import React, { useState} from 'react';
import { useDispatch } from 'react-redux';
import { checkAccessAuthorization } from '../lib/check-auth';
import Layout from './LayoutPage';
import FooterNavigationContainer from '../containers/FooterNavigationContainer';

import { UpdateDeviceChannels, toggleChannelState } from '../lib/util';

function TutorialPage() {
   checkAccessAuthorization(useDispatch) 
   const [channels,setChannels]=useState(JSON.parse(localStorage.getItem("channels")))
   const [selectedChannelId, setSelectedChannelId] = useState(null);
   const [loading, setLoading] = useState(false);
  //  const [user]=useState(JSON.parse(localStorage.getItem("xx1r")))
  const hxxrl = JSON.parse(localStorage.getItem('hxxrl'));
  if (!hxxrl || !hxxrl.access_token) {
    throw new Error('No access token found');
  }
  

  const [openList, setOpenList] = useState({});

  const toggleList = (index) => {
    setOpenList(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

 
  return(
      
 
        <Layout>
       {/* header banner */}
       <div className="flex  bg-neutral text-neutral-content">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Hollo Tutorial.</h2>
            <p>Learn hollo</p>
           </div>
        </div> 
{/* 
        <section className=" p-10 mb-8 max-w-3xl mx-auto my-8"> */}
      <div className="card  l bg-base-100 shadow-xl">
  <div className="card-body">
        <h2 className="card-title">Topics</h2>
      <ol>
        <li className="mt-2 mb-2" onClick={() => toggleList(1)}><strong>Introduction</strong>
        <hr />
          <ol style={{ display: openList[1] ? 'block' : 'none' }}>
            <li>
             
              
                {/* <figure><img src="https://daisyui.com/images/stock/photo-1494232410401-ad00d5433cfa.jpg" alt="Album"/></figure> */}
                <div className="card-body">
                  <h2 onClick={(e) => e.stopPropagation()} className="card-title">What is Hollo</h2>
                  <article>
                    <p>Hollo is a social media management tool that enables cross-platform posting and post scheduling.</p>
                    <p>
                      With Hollo, you can post to all your Facebook pages, Instagram business accounts, Instagram creator accounts, LinkedIn pages, LinkedIn user feed, and LinkedIn Showcase.
                    </p>
                    <p>Hollo allows you to publish your post instantly or schedule your post to be published on a future date.</p>

                    <p>This tutorial is under construction. we'll keep updating.</p>
                  </article>
                </div>
            
           
           
            </li>
          </ol>
        </li>
        <li className="mt-2 mb-2" onClick={() => toggleList(3)}>
          <span className="card-title"></span><strong>Channels</strong>
        <hr />
          <ol style={{ display: openList[3] ? 'block' : 'none' }}>
            <li>
  
              
                <div className="card-body">

                <h3 className="card-title mt-4">What is a channel</h3>
                    <article>
                          <p>A channel is the connection betwwen your social media account and hollo. You make post to your social media account via your channel. A channel can be taken online or ofline during the posting process. If you want to disable a channel per post bases toggle it on and off while posting. But if you  want to disable the channel you can do that in the "SETTINGS" page </p>
                        
                          <h3 className="card-title mt-4">How to connect a channel</h3>
                        
                          <li>
                            <ol>
                              <li>Click the Connect channel Button</li>
                              <li>Select the platform you want to connect to </li>
                              <li>Follow the Platform connection Process</li>
                            </ol>
                          </li>
                    </article>
                  <h3 className="card-title mt-4">  Supported platforms</h3>
                  <article>
                    <p>Welcome to the Supported Platforms section of the Hollo tutorial! In this segment, we'll explore the seamless integration of Hollo with various social media platforms, empowering you to efficiently manage your online presence. Hollo simplifies the process of sharing content across multiple platforms, allowing you to reach a wider audience and maximize your social media impact. Below is a list of the key platforms currently supported by Hollo:</p>
                  
                  </article>
                    <div className='pl-10 '>
                    <ol>
                    <li>  
                  

                      <h2 className="card-title">LinkedIn    <div  class="btn-group m-1">
                  <button class="btn btn-circle avatar btn-sm btn-circle btn-xs btn-outline">
                  <img  className = "w-5 rounded-full"  src="linkedin.png" alt=""/> 
                  </button>
                  <button class="btn btn-sm">
                  LinkedIn
                  </button>
                  </div></h2>


                        <p>Elevate your professional branding and networking efforts on LinkedIn with Hollo's comprehensive features. Schedule posts, share articles, and engage with your professional network seamlessly, all from within our platform.
                        </p>
                    </li>
                    <li>

                      <h2 className="card-title">Instagram  <div  class="btn-group m-1">
                  <button class="btn btn-circle avatar btn-sm btn-circle btn-xs btn-outline">
                  <img  className = "w-5 rounded-full"  src="instagram.png" alt=""/> 
                  </button>
                  <button class="btn btn-sm">
                  Instagram
                  </button>
                  </div></h2>
                        <p>Connect your Instagram account to Hollo and effortlessly schedule and publish posts, stories, and IGTV content directly from our platform. Engage with your followers and curate visually appealing feeds with ease.
                        </p>
                    </li>
                    <li>
                    <h2 className="card-title">Facebook <div  class="btn-group m-1">
                  <button class="btn btn-circle avatar btn-sm btn-circle btn-xs btn-outline">
                  <img  className = "w-5 rounded-full"  src="facebook.png" alt=""/> 
                  </button>
                  <button class="btn btn-sm">
                  Facebook
                  </button>
                  </div></h2>
                        <p>
                        Streamline your Facebook content strategy by scheduling posts, creating events, and sharing updates through Hollo. Maintain an active presence on the world's largest social network and interact with your audience in meaningful ways.
                        </p>
                    </li>
                      </ol>
                    </div>

                  
                
                </div>
           
            
             
            </li>
          </ol>
        </li>
        <li className="mt-2 mb-2" onClick={() => toggleList(2)}><strong>Post</strong>
        <hr />
          <ol style={{ display: openList[2] ? 'block' : 'none' }}>
            <li>
             
                {/* <figure><img src="https://daisyui.com/images/stock/photo-1494232410401-ad00d5433cfa.jpg" alt="Album"/></figure> */}
                <div className="card-body">
                  <h2  className="card-title"> What is a post?</h2>
                  <article>
                    <p>A post is the content you want published to your social media handle. 
                  A post can consist of Text, Image(s), and Video or a combination of any of these.</p>
                  
                  </article>
                  <h3 className="card-title mt-4">Types of posting (post publishing) </h3>
                      
                      <li>
                        <ol>
                        
                          <li>Instant posting</li>
                          <li>Scheduled posting </li>
                        </ol>
                      </li>
                </div>
             
            </li>
          </ol>
        </li>
       
      </ol>
    </div>
    </div>
  
   
    {/* </section> */}
        <FooterNavigationContainer page="tutorial" />
       </Layout>
   
  
    );
}
export default TutorialPage;