import React, { useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { checkAccessAuthorization } from '../lib/check-auth';
import Layout from './LayoutPage';
import FooterNavigationContainer from '../containers/FooterNavigationContainer';
import { UpdateDeviceChannels, baseUrl, logPageVisit, toggleChannelState,holloHeader} from '../lib/util';

import ConnectChannelComponent from '../components/ConnectChannelComponent';

function RewardPage() {
   logPageVisit()
   checkAccessAuthorization(useDispatch) 
   const [channels,setChannels]=useState(JSON.parse(localStorage.getItem("channels")))
   const [selectedChannelId, setSelectedChannelId] = useState(null);
   const [loading, setLoading] = useState(false);
   const hxxrl = JSON.parse(localStorage.getItem('hxxrl'));
   const [sub, setSub] = useState([localStorage.getItem('0x2')]);
   const [analytic, setAnalytic] = useState([localStorage.getItem('0x1')]);
  
  if (!hxxrl || !hxxrl.access_token) {
    throw new Error('No access token found');
  }
  
  async function handleChannelDelete(){
    setLoading(true)
         await toggleChannelState(selectedChannelId,'delete')
    

      let update= await UpdateDeviceChannels(hxxrl.user_id);
      if (update){
       setChannels(JSON.parse(localStorage.getItem("channels")))
       handleCloseModal()
       window.location.href = "/account#channelSection";
      }
    
      setLoading(false)
  }

  async function handleChannelTogle(sel_id){
    setLoading(true)
    await toggleChannelState(sel_id,'toggle')
    let update= await UpdateDeviceChannels(hxxrl.user_id);
    if (update){
     setChannels(JSON.parse(localStorage.getItem("channels")))
     window.location.href = "/account#channelSection";

    }
    setLoading(false)
  }
  function handleCloseModal(){

    const cntrlBtn = document.getElementById('deleteChannelModal')
    if (cntrlBtn) {
      cntrlBtn.click();
    }
  }
  useEffect(() => {
    const fetchStatus = async () => {
      try {

        const token = JSON.parse(localStorage.getItem('hxxrl'));
        
        if (!token || !token.access_token) {
          throw new Error('No access token found');}  
        const axios = require('axios').default;
        const url = `${baseUrl}/users/subscription?user_id=${token.user_id}`;
        const response = await axios.get(url,holloHeader());

        if (response.data && response.data.success) {
           setSub(response.data.data)
            localStorage.setItem('0x2',response.data.data)
        } else {
          throw new Error(response.data.error.message);
        }
      } catch (error) {
        console.error(error);
        alert(error.message);
      }
    };

    fetchStatus();
  }, []);
  return(
      
 
        <Layout>
       {/* header banner */}
       <div className="flex  bg-neutral text-neutral-content mb-4">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Hollo Reward Dashboard </h2>

            <div className="stats shadow">
  
          <div className="stat">
            <div className="stat-figure text-secondary">
            </div>
            <div className="stat-title">Minimum Monthly target</div>
            <div className="stat-value">10 </div>

          </div>
          
          <div className="stat">
            <div className="stat-figure text-secondary">
            </div>
            <div className="stat-title">Target aquired</div>
            <div className="stat-value">3</div>
          </div>
          
          <div className="stat">
            <div className="stat-figure text-secondary">
            </div>
            <div className="stat-title">Maximum monthly target</div>
            <div className="stat-value">30</div>
          </div>
          
</div>
<div role="alert" className="alert alert-default alert-outline sm:w-full md:w-1/2 shadow-lg p-2">
          <div>
            <div className="text-md">Note If you dont meet the Minimum target you will not be able to withdraw your reward for the month </div>
          </div>
        </div>
           </div>
        </div> 


      

        <div className='container pb-10 mx-auto sm:pr-60 sm:pl-60 '>
       
        <div className="flex flex-col space-y-4">

       
       

            
        
            <div className="stats bg-secondary  text-primary-content stats-vertical lg:stats-horizontal m-4">
          
          
            <div className="stat">
              <div className="stat-title font-bold text-primary">Available to Get</div>
              <div className="stat-value">78,000</div>
              <div className="stat-actions">
                  <button className="btn btn-sm">Get reward now</button>
              </div>
            </div>
          
            <div className="stat">
              <div className="stat-title font-bold text-primary">New users Reward</div>
              <div className="stat-value">45,000</div>
            </div>

            <div className="stat">
              <div className="stat-title font-bold text-primary">Support Reward</div>
              <div className="stat-value">38,000</div>
            </div>
          
           
          
            <div className="stat">
              <div className="stat-title font-bold text-primary">Previous Pending Reward</div>
              <div className="stat-value">0</div>
            </div>
          </div>

       
     
  
   <div className='p-4'>
      <h1 className="text-2xl mb-2">Reward request history</h1>

      <table className="table w-full">
          <thead>
            <tr>
              <th>
              
              </th>
              <th>Amount</th>
              <th>Deduction</th>
              <th>Receiver</th>
              <th>Request date</th>
              <th>Fulfil date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          
            <tr>
              <th>
              
              </th>
              <td>
                  45,000
              </td>
              <td>
                 2,300
                  <br/>
          <span className="badge badge-ghost badge-sm">Subscrition for the month of april with 50% off</span>
              </td>
              <td>  Akoh Victor | Zenith </td>
              <td>24-04-2024</td>
              <td>24-04-2024</td>
              <td>Pending</td>
            </tr>
          
        
          </tbody>
        
          
        </table>

        <h1 className="text-2xl mb-2 mt-10">User Refered</h1>

<table className="table w-full ">
   
    <thead>
      <tr>
       
        <th>Name</th>
        <th>Registered date</th>
        <th>Total post count</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {/* row 1 */}
      <tr>
   
        <td>
          <div className="flex items-center gap-3">
            <div className="avatar">
              {/* <div className="mask mask-squircle w-12 h-12"> <img src="https://img.daisyui.com/tailwind-css-component-profile-2@56w.png" alt="Avatar Tailwind CSS Component" /> </div> */}
            </div>
            <div className="font-bold">Akoh Ojochuma</div>
          </div>
        </td>
       
        <td>
         
          <span className="badge badge-ghost badge-sm">24-04-2024</span>
        </td>
        <td>
          <button className="btn btn-ghost btn-xs">20</button>
        </td>
      </tr>
    
  
    </tbody>
  
    
  </table>
   </div>
 

       



      </div>

      </div> 

     



        

        <FooterNavigationContainer page="account" />
       </Layout>
   
  
    );
}
export default RewardPage;