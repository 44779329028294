import React, { useState } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import "slick-carousel/slick/slick-theme.css";

const ResponsiveCarousel = () => {
  const [width, setWidth] = useState(900);
  const settings = {
    infinite: true,
    autoplay: true,
    dots: false,         
    slidesToShow: 8,       
    slidesToScroll: 2,       
    arrows: false,          
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,    // Settings for screens 1024px or wider
        settings: {
          slidesToShow: 4,   // Show 4 slides on large screens
        },
      },
      {
        breakpoint: 640,     // Settings for screens below 640px
        settings: {
          slidesToShow: 2,   // Show 2 slides on mobile screens
        },
      },
    ],
  };

  return (
    <div className="mb-8 " >
      <Slider {...settings}>
        {["impression-7.png", "impression-1.png", "impression-6.png", "impression-2.png", 
          "impression-4.png", "impression-5.png", "impression-8.png", "impression-3.png"].map((src, index) => (
          <div key={index} className="slide">
            <img src={src} alt={`Slide ${index + 1}`} style={{ width: "100%" }} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ResponsiveCarousel;
