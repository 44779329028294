import React, { useState, useEffect } from 'react';
import TestimonyCard from '../cards/CardCategory/TestimonyCard';

const CardSlider = ({ testimonies }) => {
  const [currentTestimonyIndex, setCurrentTestimonyIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTestimonyIndex((prevIndex) => (prevIndex + 1) % testimonies.length);
    }, 5000); // Adjust the interval duration (in milliseconds) as desired

    return () => clearInterval(interval);
  }, [testimonies.length]);

  const handleNext = () => {
    setCurrentTestimonyIndex((prevIndex) => (prevIndex + 1) % testimonies.length);
  };

  const handlePrevious = () => {
    setCurrentTestimonyIndex((prevIndex) =>
      prevIndex === 0 ? testimonies.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="lg:w-1/2  sm:w-full ">
      {/* <button className="nav-button" onClick={handlePrevious}>Previous</button> */}

      <TestimonyCard icon={testimonies[currentTestimonyIndex].icon} message={testimonies[currentTestimonyIndex].content} author={testimonies[currentTestimonyIndex].title} />
      
      {/* <button className="nav-button" onClick={handleNext}>Next</button> */}
    </div>
  );
};

export default CardSlider;

