import {
    LOGIN_REQUESTING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
  } from '../constants'
  
  const initialState = {
    requesting: false,
    successful: false,
    messages: [],
    errors: [],
    isDirty:false
  }
  
  const reducer = function loginReducer (state = initialState, action) {
    switch (action.type) {
      // Set the requesting flag and append a message to be shown
      case LOGIN_REQUESTING:
        return {
          requesting: true,
          successful: false,
          messages: [{ body: 'Logging in...', time: new Date() }],
          errors: [],
          isDirty:false
        }
  
      // Successful?  Reset the login state.
      case LOGIN_SUCCESS:
        return {
          errors: [],
          messages: [],
          requesting: false,
          successful: true,
          isDirty:false
        }
  
      // Append the error returned from our api
      // set the success and requesting flags to false
      case LOGIN_ERROR:
        return {
          errors: state.errors.concat(action.error.toString()),
          messages: [],
          requesting: false,
          successful: false,
          isDirty:true
        }
  
      default:
        return state
    }
  }
  
  export default reducer
  