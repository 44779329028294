import React, { useState } from 'react';

const TruncateText = ({ message, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) return text;
    const truncated = text.substring(0, maxLength);
    return truncated.substring(0, truncated.lastIndexOf(" ")) + "...";
  };

  return (
    <article>
      <p className="pt-1">
        {isExpanded ? message : truncateText(message, maxLength)}
      </p>
      {message.length > maxLength && (
        <button
          onClick={toggleExpand}
          className="text-blue-500 mt-2"
        >
          {isExpanded ? "View Less" : "View More"}
        </button>
      )}
    </article>
  );
};

export default TruncateText;
