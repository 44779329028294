import React, { FC } from "react";

export interface BgGlassmorphismProps {
  className?: string;
}
// inset-x-4
const BgGlassmorphism: FC<BgGlassmorphismProps> = ({
  className = "absolute  top-0 min-h-0 pl-10 py-32 flex flex-col  z-0",
}) => {
  return (
  <>
    <div
      className={`BgGlassmorphism ${className}`}
      data-nc-id="BgGlassmorphism"
    >
        {/* <span className="bg-[#ef233c] w-80 h-80 rounded-full mix-blend-multiply filter blur-3xl opacity-20 lg:w-96 lg:h-9w-96" ></span> */}
      {/* <span className="bg-[#04868b] w-80 h-80 ml-10 -mt-10 rounded-full filter blur-3xl opacity-20 lg:w-96 lg:h-9w-96 animation-delay-2000" ></span> */}

    </div>
    </>
  );
};

export default BgGlassmorphism;
