
import React, { FC, useState } from "react";
import BgGlassmorphism from "./BgGlassmorphism";
import { useSearchParams } from "react-router-dom";


export interface PageSubcriptionProps {
  className?: string;
}

export interface PricingItem {
  isPopular: boolean;
  isActive: boolean;
  percentOff?:string;
  name: string;
  servicetype: string;
  duration: string;
  pricing: string;
  discountedPrice: string;
  desc: string;
  per: string;
  features: string[];
}

const pricings: PricingItem[] = [
  // hollo space is the hard drive space used to store your hollo contents 
  {
    isPopular: false,
    isActive: true,
    percentOff:"25 % OFF",
    servicetype: "Basic Service", 
    name: "Creatives", 
    duration: "monthly",
    pricing: "₦45,000",
    discountedPrice: "₦33,750",
    per: "/mo",
    features: [
      "Max Post count: 20",
      "Content Planning: True",
      "Content Calender: True",
      "Product shoot: 10",
      "Product Copy: 10",
      "SEO Copy:  1",
      "Profile Optimization: True",
      "Platform profile upgrades: true",
      "Premium Support",
    ],
    desc: `Ideal for small business`,
  },
  {
    isPopular: true,
    isActive: true,
    servicetype: "Professional Service", 
    percentOff:"25 % OFF",
    name: "Creatives",
    duration: "monthly",
    pricing: "₦76,400",
    discountedPrice: "₦57,300",
    per: "/mo",
    features: [
      "Max Post count: 40",
      "Content Planning: True",
      "Content Calender: True",
      "Product shoot: 30",
      "Product Copy: 30",
      "SEO Copy:  2",
      "Profile Optimization: True",
      "Platform profile upgrades: true",
      "Premium Support",
    ],
    desc: `Ideal for big business`,
  },
 

];

const ManagedSubcription: FC<PageSubcriptionProps> = ({ className = "" }) => {
  const [searchParams ] = useSearchParams();
  const [selectedDuration, setSelectedDuration] = useState("monthly");
  const referalCode = searchParams.get("referal")
  const renderPricingItem = (pricing: PricingItem, index: number) => {
    if (pricing.duration !== selectedDuration) {
      return null;
    }
    return (
      <div className=" h-full   ">
      <div
        key={index}
        className={`relative px-6 py-8 rounded-3xl border-2 w-full   p-5 mx-auto bg-white shadow-lg sm:p-10 mt-10 lg:mt-20 lg:p-16 ${
          pricing.isPopular
            ? "border-primary-500"
            : "border-neutral-100 dark:border-neutral-700"
        }`}
      >

        {pricing.percentOff ? (
        <h1 className="indicator-item indicator-top indicator-center badge badge-primary font-bold ">{pricing.percentOff}</h1>
       ):("")} 
       
       {pricing.isPopular && (
          <span className="bg-primary-500 dark:text-primary px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium ">
            {pricing.servicetype}
          </h3>
          <h2 className="text-3xl leading-none flex items-center">
            <span className="text-primary text-block">{pricing.discountedPrice}</span>
            <span className="text-lg ml-1 font-normal text-neutral-500">
              {pricing.per}
            </span>
          </h2>
          <h3 className="text-2xl leading-none flex items-center">
            <span className="text-neutral-500"><del>{pricing.pricing}</del></span>
            <span className="text-lg ml-1 font-normal text-neutral-500">
              {/* {pricing.per} */}
            </span>
          </h3>
        </div>
        <nav className="space-y-4 mb-8">
          {pricing.features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary">
                {/* <CheckIcon className="w-5 h-5" aria-hidden="true" /> */}
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">
                {item}
              </span>
            </li>
          ))}
        </nav>
        <div className="flex flex-col mt-auto mb-5">
        {pricing.isPopular ? (
          <a href={`/register?package=${pricing.name}&price=${pricing.discountedPrice}&serviceType=${pricing.servicetype}&duration=${pricing.duration}&referal=${referalCode}`} className={`btn btn-primary`}>
            <button type="submit"  >
               SUBSCRIBE
            </button></a>
             ) : (
            <a href={`/register?package=${pricing.name}&price=${pricing.discountedPrice}&serviceType=${pricing.servicetype}&duration=${pricing.duration}&referal=${referalCode}`} className={`btn btn-secondary`} style={{ pointerEvents: !pricing.isActive ? 'none' : 'auto' }}>
            SUBSCRIBE
            </a>
           )}

          <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">
            {pricing.desc}
          </p>
        </div>
      </div>
      </div>
    );
  };

  return (
    <div>
        <BgGlassmorphism />
        

 

    <section className=" text-neutral-600 text-sm md:text-base overflow-fit flex justify-center mb-4">
  
      <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 sm:gap-6 md:gap-8">
        {pricings.map(renderPricingItem)}
      </div>
   
    </section>
  </div>
 
  );
};

export default ManagedSubcription;
