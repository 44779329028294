import React from 'react'
import { useDispatch} from 'react-redux';
import { checkAccessAuthorization } from '../lib/check-auth';
import { getToken, holloHeader,baseUrl, callback_url } from '../lib/util';
import { json } from 'react-router';




export default function ConnectChannelComponent(isIcon=false){

  checkAccessAuthorization(useDispatch) 

  const token =  getToken()
  let currentTheme=localStorage.getItem("theme")

   
  let connect_logo = currentTheme === 'dark' ? "connect-dark.png" : "connect-light.png";
   
  const axios = require('axios').default;

  let userId=""
  userId = token.user_id 
  
  const handleClick=async (platform)=>{
    //this clears any previous conection section

    localStorage.removeItem("connecting_payload");
    localStorage.removeItem("connecting_channel");
    localStorage.removeItem("is_page_connection");
    localStorage.removeItem("connecting_platform");
    localStorage.removeItem("has_verified_code")
  
    localStorage.setItem('connecting_platform', platform.tag)
    localStorage.setItem('is_page_connection', platform.isPage)
  
    try {
     
      const response = await axios.get(baseUrl+"/authenticate"+platform.path+"&client_url="+callback_url,holloHeader());
  
      if (response.data) {
        let responseData = response.data;
        
        if (responseData.success) {
          localStorage.setItem('connecting_payload', JSON.stringify(responseData.data))
          window.location.replace(responseData.data.url)
        } else {
          console.log(responseData.error.message);
          alert(responseData.error.message);
        }
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
   
   } 

  
  
  
  

   //update this part to have the client cal
  //use the current user id to get all the authentication urls  
  const platforms = [
    {isPage:false,path: "/twitter?user_id="+userId,tag:"twitter",tagLabel:"Twitter",icon:"twitter.png" },
    { isPage:true,path: "/facebook?user_id="+ userId+"&is_facebook_page=true&is_facebook_group=false",  tag: "facebook" ,tagLabel: "Facebook Page" ,icon:"facebook.png" },
    // { isPage:true,path: "/facebook?user_id="+ userId+"&is_facebook_page=false&is_facebook_group=true",  tag: "facebook" ,tagLabel: "Facebook Group" ,icon:"facebook.png" },
    { isPage:true,path: "/facebook?user_id="+ userId+"&is_facebook_page=false&is_facebook_group=false", tag: "facebook" ,tagLabel: "Instagram" ,icon:"instagram.png" },
    { isPage:true,path: "/linkedin?user_id="+ userId+"&is_business_page=true",  tag: "linkedin" ,  tagLabel: "Linkedin Business" ,icon:"linkedin.png" },
    { isPage:false,path: "/linkedin?user_id="+ userId+"&is_business_page=false",  tag: "linkedin",tagLabel:"LinkedIn User" ,icon:"linkedin.png" },
  ]
      return(
          <>

       

      
<label className="w-1/2 btn-group btn btn-sm btn-primary btn-block text-white" htmlFor="connect-account">
  <img className="hidden md:inline w-5 h-5 rounded-full avatar mr-2" src={connect_logo} alt="Connect Logo" />
  Connect Channel
</label>
       
        <div className="p-0 m-0">
       <input type="checkbox"  id="connect-account"  className="modal-toggle" />
        <div className="modal mt-0">
          <div className="card bg-neutral text-primary-content max-w-screen-lg mx-auto mt-0">
            <div className="navbar bg-primary text-primary-content p-0">
              <button className="pl-4 btn btn-ghost text-xl">Connect your channel</button>
              <button className="btn btn-ghost text-xl"></button>
              <label id="connect-account" htmlFor="connect-account" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
            </div>
            <div className="card-body  w-96">
          
            <div  className="m-1">
            {platforms.map(platform=>(
            
              <div  onClick={()=>handleClick(platform)} className="btn-group m-2">
              <button className="btn btn-circle avatar btn-sm btn-circle btn-outline">
              <img  className = "w-5 rounded-full"  src={platform.icon} alt={platform.tagLabel}/> 
              </button>
              <button className="btn btn-sm">
              {platform.tagLabel}
              </button>
              </div>

            ))} 
          </div>          
              </div>
            
          </div>
        </div>
        </div>
     </>
    
      );
  }
 