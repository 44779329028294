import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkAccessAuthorization } from '../lib/check-auth';
import Layout from './LayoutPage';


import { UpdateDeviceChannels, baseUrl, logPageVisit, toggleChannelState, holloHeader, toggleHashtagState } from '../lib/util';
import ConnectChannelComponent from '../components/ConnectChannelComponent';
import ContactForm from '../forms/ContactForm';
import HashtagForm from '../forms/HashtagForm';
import BrandForm from '../forms/BrandForm';
import FooterNavigationContainer from '../containers/FooterNavigationContainer';

const SettingPage = () => {
  const dispatch = useDispatch();
  checkAccessAuthorization(dispatch);



  // State for various forms
  const [activeSection, setActiveSection] = useState('account');


  const [channels, setChannels] = useState(JSON.parse(localStorage.getItem('channels')));
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [loading, setLoading] = useState(false);
  const hxxrl = JSON.parse(localStorage.getItem('hxxrl'));
  const [sub, setSub] = useState([localStorage.getItem('0x2')]);

  async function handleChannelDelete() {
    setLoading(true);
    await toggleChannelState(selectedChannelId, 'delete');
    let update = await UpdateDeviceChannels(hxxrl.user_id);
    if (update) {
      setChannels(JSON.parse(localStorage.getItem('channels')));
      handleCloseModal();
      window.location.href = '/account#channelSection';
    }
    setLoading(false);
  }

  async function handleChannelToggle(sel_id) {
    setLoading(true);
    await toggleChannelState(sel_id, 'toggle');
    let update = await UpdateDeviceChannels(hxxrl.user_id);
    if (update) {
      setChannels(JSON.parse(localStorage.getItem('channels')));
      window.location.href = '/account#channelSection';
    }
    setLoading(false);
  }


  function handleCloseModal() {
    const cntrlBtn = document.getElementById('deleteChannelModal');
    if (cntrlBtn) {
      cntrlBtn.click();
    }
  }

  return (
    <Layout>
      <div className="flex">
        <div className="w-1/4 p-4 bg-gray-200 min-h-screen">
          <h2 className="text-xl font-bold mb-4">Settings</h2>
          <nav className="flex flex-col space-y-2">
            <button className="btn btn-outline" onClick={() => setActiveSection('account')}>Account</button>
            <button className="btn btn-outline" onClick={() => setActiveSection('subscription')}>Subscription</button>
            <button className="btn btn-outline" onClick={() => setActiveSection('brand')}>Brand</button>
            {/* <button className="btn btn-outline" onClick={() => setActiveSection('security')}>Security</button> */}
            {/* <button className="btn btn-outline" onClick={() => setActiveSection('posts')}>Posts</button> */}
            <button className="btn btn-outline" onClick={() => setActiveSection('channel')}>Channel</button>
            <button className="btn btn-outline" onClick={() => setActiveSection('hashtags')}>Hashtags</button>
          </nav>
        </div>

        <div className="w-3/4 p-4">
          {activeSection === 'account' && (
            <div id="account" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Account</h3>
              <ContactForm />
            </div>
          )}
          {activeSection === 'subscription' && (
            <div id="subscription" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Subscription</h3>
              {/* <ContactForm /> */}

              <div className="flex items-center justify-center ">
                  <div className='w-140'>
                    <div role="alert" className="alert alert-outline alert-primary relative ">
                      <div className="bg-default text-white border-l-4 border-orange-700 pr-12 p-2">
                      <h2 className="card-title text-primary">Active  package</h2>
                        <span className='mr-7 font-bold text-primary text-base-100'>You're on a {sub.duration} {sub.sub_package} package starting from {sub.sub_start_date} to subscription {sub.sub_end_date}</span>
                      </div>

                      <div className="stat-actions">
                        <button className="btn btn-primary btn-sm m-4">Cancel Subscription</button> 
                      </div>
                      <div style={{  overflow: 'hidden' }} className="absolute top-0 right-0 h-full flex items-center ">
                        <div className="ribbon bg-primary w-16 h-16 absolute transform rotate-45"></div>
                        <div className="bow bg-primary w-8 h-8 rounded-full ml-2"></div>
                      </div>
                    </div>
                  </div>
          </div>

            </div>
          )}
          {activeSection === 'brand' && (
            <div id="brand" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Brand</h3>
               <BrandForm />
            </div>
          )}
          {activeSection === 'security' && (
            <div id="security" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Security</h3>
              {/* <PasswordForm /> */}
            </div>
          )}
          {activeSection === 'posts' && (
            <div id="posts" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Posts</h3>
              <div className="form-control w-52">
                <label className="label cursor-pointer">
                  <span className="label-text">Auto post to enabled channels</span>
                  <input type="checkbox" className="toggle toggle-primary" defaultChecked />
                </label>
              </div>
              <div className="form-control w-52">
                <label className="label cursor-pointer">
                  <span className="label-text">Posting mode</span>
                  <input type="checkbox" className="toggle toggle-primary" defaultChecked />
                </label>
              </div>
            </div>
          )}
          {activeSection === 'channel' && (
            <div id="channel" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Channel</h3>
              <div role="alert" className="alert alert-default alert-outline w-1/2 shadow-lg p-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-primary shrink-0 w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <div>
                  To temporarily take a channel ONLINE or OFFLINE, Click the name of the channel
                </div>
              </div>
              <br />
              <div role="alert" className="alert alert-default alert-outline w-1/2 shadow-lg p-4">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-primary shrink-0 w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
                <div>
                  <div className="text-md">To permanently delete a channel and disconnect your social media account, click the "DELETE" </div>
                </div>
              </div>
              <section className="p-4" id="channelSection">
                {channels.length > 0 &&
                  <div className="card-actions justify-start">
                    {channels.map((channel) => (
                      <div key={channel._id} className="card-actions justify-start">
                        {loading ? (
                          <button className="btn btn-sm loading m-2"></button>
                        ) : (
                          <div className="btn-group flex">
                            <button onClick={() => { handleChannelToggle(channel._id) }} className="btn btn-circle avatar btn-xs btn-circle btn-outline btn-primary">
                              <img className="w-5 rounded-full" src={channel.icon} alt={channel.tag} />
                            </button>
                            <button
                              onClick={() => { handleChannelToggle(channel._id) }} className={channel.isSelected ? "btn btn-xs btn-primary" : "btn btn-xs btn-outline"}>
                              {channel.user_name}
                            </button>
                            <label
                              id="DeleteModalBtn"
                              className="item-center btn btn-outline btn-xs item-center"
                              htmlFor="deleteChannelModal"
                              onClick={() => {
                                setSelectedChannelId(channel._id);
                              }}
                            >Delete</label>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                }
              </section>
              <p>Add a new channel</p>
              <div className="flex">
                <ConnectChannelComponent />
              </div>
            </div>
          )}
          {activeSection === 'hashtags' && (
            <div id="hashtags" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Hashtags</h3>
              <HashtagForm />
           
            </div>
          )}
        </div>
      </div>

      <div className="p-0 m-0">
        <input type="checkbox" id="deleteChannelModal" className="modal-toggle" />
        <div className="modal mt-4">
          <div className="card bg-neutral text-primary-content max-w-screen-lg mx-auto">
            <div className="navbar bg-primary text-primary-content p-0">
              <button className="btn btn-ghost text-xl">Delete channel confirmation?</button>
              <label id="modalCloseBtn" htmlFor="deleteChannelModal" className="btn btn-sm btn-circle absolute right-2 top-2">
                ✕
              </label>
            </div>
            <div className="card-body w-full">
              <article>
                By choosing to delete this channel, you are opting out of posting to this social media account through Hollo, and this action is irreversible. However, you have options! You can re-add this account or explore others based on your subscription package. If you prefer to temporarily stop posting without deleting, you can also click the "Channel name" to take the channel Offline or Online. Keep in mind the finality of deleting
              </article>
              <i className="text-primary text-sm">Are you sure you want to delete this channel? Remember, this action cannot be undone.</i>
              <button onClick={() => { handleChannelDelete() }} className="btn btn-xs btn-ghost">
                I'm sure delete
              </button>
              <label onClick={() => { handleCloseModal() }} className="btn btn-xs btn-primary">
                No cancel
              </label>
            </div>
          </div>
        </div>
      </div>
      <FooterNavigationContainer page="account" />
    </Layout>
  );
};

export default SettingPage;
