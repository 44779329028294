import { useSelector } from 'react-redux';

export function checkAuthorization (dispatch) {
  // attempt to grab the token from localstorage
  const storedToken = localStorage.getItem('hxxrl')
    
  const isSetState= useSelector(state=>state.client.token)
  // if it exists
  if (storedToken) {
    // parse it down into an object
    // const token = JSON.parse(storedToken)
    // if(!isSetState){
    //    dispatch(setClient(token))
    // }
   
    return true
  }

  return false
}
 


  
export function checkAccessAuthorization (dispatch) {

  if (!checkAuthorization(dispatch)) {  
    window.location.href = "login"
  }
}

export function checkPublicAuthorization (dispatch) {
//this checks if you are already loggedin redirects you to the feed page 
//this is used on public loggedin page and registration page
  if (checkAuthorization(dispatch)) {  
    window.location.href = "/home"
  }
}