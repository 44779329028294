import React, { createContext, useState } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {

  let currentTheme=localStorage.getItem("theme")

  if (currentTheme === null) {
  
    currentTheme="dark"
    localStorage.setItem('theme', currentTheme)
  }
  const [theme, setTheme] = useState(currentTheme);


  

  
  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
         <div data-theme={theme}>
             {children}  
         </div>
   
    </ThemeContext.Provider>
  );
};
