import React,{useDispatch} from 'react';
import Layout from '../LayoutPage';
import  { Link } from 'react-router-dom';
import { checkAccessAuthorization } from '../../lib/check-auth';
import ListContainer from '../../containers/ListContainer';
import ListContainer3 from '../../containers/ListContainer3';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import ConnectChannelComponent from '../../components/ConnectChannelComponent';
import WelcomeMessage from '../../components/WelcomeMessage';




function WelcomePage() {
    checkAccessAuthorization(useDispatch) 
    const token = JSON.parse(localStorage.getItem('hxxrl'));

     

    const userName=token.username
    const StepsData = [
      {
        "title": "Connect your desired channel",
        "message": "A channel is your social media account. Connect as much channel as allowed by your package",
        "count":"1"
      },
      {
        "title": "Compose your post ",
        "message": "Post is the message, media you want published to your channels",
        "count":"2"
      },
      {
        "title": "Hollo ",
        "message": "Once you are done composing your post, click the Hollo button to publish your post. you can schedule the post to be published later or post it instantly.",
        "count":"3"  
      }
     
    ]
    return(
      

        <Layout>
       {/* header banner */}
          

        <div class="w-full">

       
          <div className="avatar flex  justify-center mt-4">
          <div className="w-10 max-w-xs rounded-full ring ring-primary ring-offset-base-100 ring-offset-2 mt-2">
               <img src="\cup.jpg" className='w-md' />
          </div>
            </div>

            <h2 className="text-xl flex  justify-center">
               <span className="badge badge-lg"> </span>
               
               </h2>

               <div className="flex  ">
          <div className="card-body items-center">
            {/* <h2 className="card-title">We officially welcome you to hollo🎉</h2> */}


            <div className="card p-4 w-full mb-4   bg-base-100 shadow-xl">
          
            {/* <WelcomeMessage  /> */}
          
            <div role="alert" className="alert alert-default alert-outline w-full sm:w-full lg:w-1/2  p-4  items-center">
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-primary shrink-0 w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
  <div>
    
    <div className="text-md">
   <p> Hello {userName},</p>

        <p>We're thrilled to have you on board! 🎉
        At Hollo, we believe in the power of 
        a strong online presence, and we're here to help you make the most
        out of your social media journey.</p>

        <p>🚀 Unlock Your Potential with High-Volume, Consistent Posting!</p>
    </div>
  </div>
</div>

         </div>
         
      
  <div className="flex justify-center m-2">
    <div className="grid grid-cols-1  xl:grid-cols-3 ">
     
    <div className="flex card  lg:w-96 bg-base-100 shadow-xl  breath-animation ">
 
    <figure className='item bg-primary w-20 h-20 rounded-full mt-2 ml-10'>   <h1 className='text-white text-5xl font-bold'>1</h1></figure>
  
  <div className="card-body flex-item inline ">
    <h2 className="card-title dark:text-primary">Connect your desired channel</h2>
    <p className='m-2'>A channel is your social media account. Connect as much channel as allowed by your package</p>
    <a style={{ textAlign: 'center' }} className='btn btn-primary block pt-4' href='home'>Connect account</a>
  </div>

</div>


  <div className="flex card  lg:w-96 m-2 bg-base-100 shadow-xl">
  <figure className='item bg-primary w-20 h-20 rounded-full mt-2 ml-10 '>   <h1 className='text-white text-5xl font-bold'>2</h1></figure>
   
    <div className="card-body flex-item inline">
      <h2 className="card-title dark:text-primary ">Compose your post </h2>
      <p className='m-2'> Post is the message, media you want published to your channels </p>

    </div>
  </div>
  <div className="flex card m-2 lg:w-96 bg-base-100 shadow-xl">
    {/* <figure className='item'></figure> */}
    <figure className='item bg-primary w-20 h-20 rounded-full mt-2 ml-10'>   <h1 className='text-white text-5xl font-bold'>3</h1></figure>
    <div className="card-body flex-item inline">
      <h2 className="card-title dark:text-primary ">Publish post</h2>
      <p className='m-2'>Once you are done composing your post, click the Hollo button to publish your post. you can schedule the post to be published later or post it instantly.</p>
    </div>
  </div>
    </div>
  </div>


 
            {/* Put the onboarding stepts. what is the next most desired action the user should take to get them to ()post) 85 % of their daily post capacity. for more clarity what is the next step that this user should take to get them to make 17 post a day or 20 post a day*/}
            {/* <Link className="btn btn-primary normal-case text-xl" to="/feed">Start posting </Link> */}
           
           </div>
        </div>   
           
      </div>
         
       </Layout>
   
  
    );
}
export default WelcomePage;