import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
import Layout from '../../LayoutPage';

const TermsAndConditions = () => {
  return (
    <Layout>

    <div className="max-w-3xl mx-auto my-8">
      <h1 className="text-3xl font-bold mb-4">Hollo - Terms and Conditions</h1>
      <p className="mb-6">Last Updated: 26th December 2023</p>

      <p className="mb-4">
        Welcome to Hollo, the ultimate social media management tool designed to streamline your social media experience.
        Before diving into the world of Hollo, please carefully review and understand the following comprehensive terms
        and conditions. By accessing or using Hollo, you are agreeing to comply with and be bound by these terms.
      </p>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">1. Acceptance of Terms</h2>
        <p>
          By accessing or using Hollo, you agree to be bound by these terms and conditions. If you do not agree with
          any part of these terms, you may not use Hollo.
        </p>
      </section>
    
      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">2. Account Registration</h2>
        <p> a.<span className="font-semibold">User Access:</span> To use the Tool, you must register for an account, providing accurate and complete information.
        </p>
        <p>
          b. <span className="font-semibold">User Responsibility:</span> You are responsible for maintaining the
          confidentiality and the security of your account and password. You agree to accept responsibility for all activities that
          occur under your account.
        </p>
        <p>
          c. <span className="font-semibold">Accurate Information:</span> When creating your Hollo account, you must
          provide accurate and complete information. Any false information may result in the termination of your
          account.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">3. Social Media Integration</h2>
        <p>
          a. <span className="font-semibold">Connectivity:</span> Hollo allows users to connect their Facebook,
          LinkedIn, Instagram, Twitter, and other social media accounts. You agree to comply with the terms and
          conditions of each respective social media platform.
        </p>
        <p>
          b. <span className="font-semibold">Content Posting:</span> Hollo enables users to post content
          instantaneously or schedule future posts. You are responsible for the content posted through Hollo and
          must adhere to the guidelines of each connected social media platform.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">4. Contest Mechanism</h2>
        <p>
          a. <span className="font-semibold">Reward System:</span> Hollo includes a contest mechanism that rewards
          users. The rules and rewards will be specified within the Hollo platform or by Chukolo Core. By participating, users agree to
          abide by the specified rules.
        </p>
        <p>
          b. <span className="font-semibold">Fair Play:</span> Hollo reserves the right to disqualify users engaging
          in fraudulent or unfair practices in contests.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">5. Termination of Account</h2>
        <p>
          Hollo reserves the right to terminate or suspend your account at any time without prior notice if you
          violate these terms and conditions or engage in any conduct that Hollo believes, in its sole discretion,
          is harmful to other users or Hollo itself.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">6. User Conduct</h2>
        <p>
          a. <span className="font-semibold">Prohibited Activities:</span> Users must not engage in any unlawful,
          abusive, or disruptive behavior while using Hollo. This includes but is not limited to the transmission of
          malware, spam, or any content violating intellectual property rights.
        </p>
        <p>
          b. <span className="font-semibold">Compliance with Laws:</span> Users must comply with all applicable local,
          national, and international laws and regulations.
        </p>
      </section>

            <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">7. Intellectual Property</h2>
        <p>
            a. <span className="font-semibold">Ownership:</span> Hollo and its original content, features, and functionality
            are owned by Chukolo Core. The content provided by users remains the intellectual property of the users.
        </p>
        <p>
            b. <span className="font-semibold">License:</span> By posting content on Hollo, you grant Chukolo core a non-exclusive,
            worldwide, royalty-free license to use, display, and distribute the content.
        </p>
        <p>
            c. <span className="font-semibold">Copyrights:</span> You may not reproduce, distribute, modify, create derivative
            works of, publicly display, publicly perform, republish, or transmit any material obtained from or through the Tool
            without the prior written consent of Chukolo Core.
        </p>
        <p>
            d. <span className="font-semibold">Ownership Confirmation:</span> Users are only allowed to post content they have
            copyright ownership of. By posting content, you confirm that you have the necessary rights and permissions to
            share and license the content on Chukolo Core.
        </p>
        <p>
            e. <span className="font-semibold">Disclaimer:</span> Hollo is not responsible for any copyright violations
            committed by users. By using the Tool, you agree that Hollo is released from any legal action or claims arising
            from copyright violations by users.
        </p>
     </section>

     <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">8. Changes to Terms and Conditions</h2>
        <p>
          Hollo may update these terms and conditions at any time without prior notice. It is your responsibility to
          review these terms regularly.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">9. Disclaimers and Limitation of Liability</h2>
        <p>
          a. <span className="font-semibold">No Warranty:</span> Hollo is provided "as is" without any warranties,
          express or implied.
        </p>
        <p>
          b. <span className="font-semibold">Limitation of Liability:</span> Hollo shall not be liable for any
          indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">10. Governing Law</h2>
        <p>These terms and conditions shall be governed by and construed in accordance with the laws of Nigeria.</p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">11. Contact Information</h2>
        <p>
          For questions or concerns regarding these terms and conditions, please{' '}
          <Link to="/contact" className="text-blue-500">
            contact us
          </Link>{' '}
          at hollo.chukolo.com.
        </p>
      </section>
    </div>
    </Layout>
  );
};

export default TermsAndConditions;
