import React, { useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { checkAccessAuthorization } from '../lib/check-auth';
import Layout from './LayoutPage';
import FooterNavigationContainer from '../containers/FooterNavigationContainer';
import { UpdateDeviceChannels, baseUrl, logPageVisit, toggleChannelState,holloHeader, FetchUserPost} from '../lib/util';
import Socials from '../components/Socials';
import ManagedServiceForm from '../forms/BrandForm';
import PostContainer from '../containers/PostContainer';
import UserSocials from '../components/UserSocials';
const axios = require('axios').default;
function ProfilePage({ username = null }) {

  
  // if(username){
  //     logPageVisit()
  //     checkAccessAuthorization(useDispatch) 
  //     const [activeTab, setActiveTab] = useState('Posts');
  //     const profile_photo=0
  //   }else{
  //         const [activeTab, setActiveTab] = useState('Gallery');
  //   }

   const [channels,setChannels]=useState(JSON.parse(localStorage.getItem("channels")))
   const [selectedChannelId, setSelectedChannelId] = useState(null);
   const [loading, setLoading] = useState(false);
   const hxxrl = JSON.parse(localStorage.getItem('hxxrl'));

   const [sub, setSub] = useState([localStorage.getItem('0x2')]);
   const [analytic, setAnalytic] = useState([localStorage.getItem('0x1')]);
  
   const [activeTab, setActiveTab] = useState('Posts');
   const page_size=5
   const [currentPage, setCurrentPage] = useState(1); 

   const [posts, setPosts] = useState([]);
  
  //if there is a username then it means the user accessed there own profile else its a public profile 

  
  useEffect(() => {
    const storedPosts = JSON.parse(localStorage.getItem('userPosts')) || [];
  
    if (storedPosts.length > 0) {
      setPosts(storedPosts);
     } else {
      
      const initialPosts = async () => {
        try {
          setLoading(true);
          const fetchedPosts = await FetchUserPost(hxxrl.user_id, currentPage, page_size);

          setPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);
          localStorage.setItem('last_feed_page', currentPage);
        } catch (error) {
          console.log(error);
          alert(error.message);
        } finally {
          setLoading(false);
        }
      };
      initialPosts();
    }
  }, [currentPage]);

  const [profileImage, setProfileImage] = useState('user.jpg');
  const [profileUserName, setProfileUsername] = useState('Hollo User');
  const [file, setFile] = useState(null);

 

     
  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setProfileImage(reader.result);
        setFile(selectedFile);
        await handleUpload()
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('attachments', file); // The key should match the parameter name in FastAPI

      try {
        const token = JSON.parse(localStorage.getItem('hxxrl'));
        const response = await axios.post(`${baseUrl}/media/profile/image`, formData, 
          {headers: {'Content-Type': 'multipart/form-data',  'authorization': 'Bearer ' + token.access_token  } });
        if (response.data) {
          let responseData = response.data;
          if (responseData.success) {
       
             hxxrl["profile_photo"] =responseData.data
             localStorage.setItem('hxxrl',JSON.stringify(hxxrl))
             alert(  hxxrl["profile_photo"] )
          } else {
            console.log(responseData.error.message);
            alert('error');
          }
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };


  
  useEffect(() => {
    const fetchStatus = async () => {
      try {
               
        if(username){
          //this is used for 
          //get user details 
        }else{
          // alert(hxxrl["profile_photo"])
              //this is my own profile visit
            setProfileImage(hxxrl["profile_photo"])
            setProfileUsername(hxxrl["name"])
        }

        const token = JSON.parse(localStorage.getItem('hxxrl'));
        
        if (!token || !token.access_token) {
          throw new Error('No access token found');}  
        const axios = require('axios').default;
        const url = `${baseUrl}/users/subscription?user_id=${token.user_id}`;
        const response = await axios.get(url,holloHeader());

        if (response.data && response.data.success) {
           setSub(response.data.data)
            localStorage.setItem('0x2',response.data.data)
        } else {
          throw new Error(response.data.error.message);
        }
 
      } catch (error) {
        console.error(error);
        alert(error.message);
      }
    };
    fetchStatus();
  }, []);
  return(

        <Layout>
          {/* header banner */}
      
       <div className="flex bg-neutral text-neutral-content h-64">
       <div className="card-body items-center ">   <UserSocials /></div>
        
       </div>    
    
       {/* container */}
        <div className='container pb-5 mx-auto sm:pr-60 sm:pl-60'>
       
        <div className="flex flex-col space-y-4">
        <div className="flex-item justify-left -mt-12">
              <div className="avatar">
                     <div className="w-32 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2"><img src={profileImage} alt="User profile" />
              </div>
              {username?
             "": <> <input
             type="file"
             accept="image/*"
             onChange={handleImageChange}
           />  </>
              
            }
   
  
        </div>

      
        

<h2 className="card-title mt-1">{profileUserName} </h2>
        </div>
      
   
        </div>


        <section className='m-1'>
          {/* About the users brand tag */}



          <div className="mt-4">
        {/* <button
          onClick={handleUpload}
          className="px-4 py-2 bg-blue-500 text-white rounded-full"
        >
          Upload
        </button> */}
      </div>
        </section>
       
        <section>
        <div className="mb-4 mt-8 flex flex-row  ">

          <a
              role="tab"
              className={`tab  ${activeTab === 'Posts' ? 'tab-active text-primary' : ''}`}
              onClick={() => setActiveTab('Posts')}
            >
                 <h2 className="text-xl font-bold">Posts highlights</h2>
            </a>  
            {username != null ? (
                <>
            
                  <a
                    role="tab"
                    className={`tab ${activeTab === 'Gallery' ? 'tab-active text-primary' : ''}`}
                    onClick={() => setActiveTab('Gallery')}
                  >
                    Gallery
                  </a>
                </>
              ): ""

          
         }
        </div>
        </section>
       
          
      <hr />
      <div className="profile-content">
        {activeTab === 'Posts' && 
        
        <div>
   
    
        <PostContainer posts={posts} />
      </div>
        }
   
        {activeTab === 'Gallery' && <GalleryContent />}
      </div>
   
      </div> 

     

        

        <FooterNavigationContainer page="account" />
       </Layout>
   
  
    );
}


const BrandContent = () => (
  <div>
    <h2 className="text-xl font-bold">Brand</h2>
    <ManagedServiceForm />
  </div>
 
);

const GalleryContent = () => (
  <div>
    <h2 className="text-xl font-bold">Gallery</h2>
    <MasonryGridGallery />
  </div>
);

export function MasonryGridGallery() {
  return (
    <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
      <div className="grid gap-4">
        <div>
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center"
            src="https://images.unsplash.com/photo-1432462770865-65b70566d673?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80"
            alt="gallery-photo"
          />
        </div>
        <div>
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center "
            src="https://images.unsplash.com/photo-1629367494173-c78a56567877?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=927&q=80"
            alt="gallery-photo"
          />
        </div>
        <div>
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center"
            src="https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
            alt="gallery-photo"
          />
        </div>
      </div>
      <div className="grid gap-4">
        <div>
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center"
            src="https://images.unsplash.com/photo-1552960562-daf630e9278b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            alt="gallery-photo"
          />
        </div>
        <div>
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center"
            src="https://images.unsplash.com/photo-1540553016722-983e48a2cd10?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=800&q=80"
            alt="gallery-photo"
          />
        </div>
        <div>
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center "
            src="https://docs.material-tailwind.com/img/team-3.jpg"
            alt="gallery-photo"
          />
        </div>
      </div>
      <div className="grid gap-4">
        <div>
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center"
            src="https://images.unsplash.com/photo-1493246507139-91e8fad9978e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2940&q=80"
            alt="gallery-photo"
          />
        </div>
        <div>
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center "
            src="https://docs.material-tailwind.com/img/team-3.jpg"
            alt="gallery-photo"
          />
        </div>
        <div>
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center"
            src="https://images.unsplash.com/photo-1552960562-daf630e9278b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            alt="gallery-photo"
          />
        </div>
      </div>
      <div className="grid gap-4">
        <div>
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center"
            src="https://images.unsplash.com/photo-1552960562-daf630e9278b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
            alt="gallery-photo"
          />
        </div>
        <div>
          <img
            className="h-auto max-w-full rounded-lg object-cover object-center"
            src="https://images.unsplash.com/photo-1629367494173-c78a56567877?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=927&q=80"
            alt="gallery-photo"
          />
        </div>
      </div>
    </div>
  );
}
export default ProfilePage;