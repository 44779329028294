import React from 'react';
import Navigation from '../containers/NavigationContainer';
import FooterContainer from '../containers/FooterContainer';
import BgGlassmorphism from '../components/BgGlassmorphism';

const Layout = ({ children }) => {
  return (
    <>
    {/* flex flex-col min-h-screen */}
     <div className="">
     <BgGlassmorphism /> 
     <Navigation />
      <main className="flex-1 lg:pl-10 lg:pr-10">
         {children}
      </main>
      <FooterContainer />
    </div>

      
    </>
  );
};

export default Layout;