import React, { FC } from "react";
import Heading from "../components/Heading";
import UserCard from "../cards/UserCard";
import { PlatformType } from "../data/jsons/types";
import CircleCard from "../cards/CircleCard";




export interface PlatformContainerProps {
  className?: string;
  platforms: PlatformType[];
  title:string;
  desc:string;
}

const PlatformContainer: FC<PlatformContainerProps> = ({ 
className ,
platforms,
title,
desc
}) => {
  return (
    <div
    className={`nc-SectionGridAuthorBox relative ${className}`}
    data-nc-id="SectionGridAuthorBox"
  >
    <Heading desc={desc} isCenter>
     {title}
    </Heading>
    <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 md:gap-8  justify-center">
      {platforms.map((platform) => (
        <CircleCard key={platform.id} platform={platform} />
      ))}
    </div>
  </div>
  );
};

export default PlatformContainer;
