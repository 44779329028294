
import React, { FC, useState } from "react";
import BgGlassmorphism from "./BgGlassmorphism";
import { useSearchParams } from "react-router-dom";


export interface PageSubcriptionProps {
  className?: string;
}

export interface PricingItem {
  isPopular: boolean;
  isActive: boolean;
  percentOff?:string;
  name: string;
  duration: string;
  pricing: string;
  
  discountedPrice: string;
  desc: string;
  per: string;
  features: string[];
}

const pricings: PricingItem[] = [
  // hollo space is the hard drive space used to store your hollo contents 
  {
    isPopular: true,
    isActive: true,
    percentOff:"25 % OFF",
    name: "Personal",
 
    duration: "monthly",
    pricing: "₦6,200",
    discountedPrice: "₦4,650",
    per: "/mo",
    features: [
      "Maximum daily post count: 12",
      "Maximum channel count: 5",
      "Monthly schedule count: 50",
      "Single post scheduling: count 5",
      "Maximum schedule age: 1 Month",
      "Contest ticket: True",
      "Hollo space: 1GB",
      "Bulk posting: False",
      "Premium Support",
    ],
    desc: ` Way to go enjoy the power of hollo.`,
  },
  {
    isPopular: false,
    isActive: true,
    percentOff:"25 % OFF",
    name: "Creatives",
    duration: "monthly",
    pricing: "₦12,400",
    discountedPrice: "₦9,300",
    per: "/mo",
    features: [
      "Maximum daily post count: 25",
      "Maximum channel count: 12",
      "Monthly schedule count: 100",
      "Single post scheduling count: 15",
      "Maximum schedule age: 1 Months",
      "Contest ticket: True",
      "Hollo space: 2.5GB",
      "Bulk Posting: False",
      "Premium Support",
    ],
    desc: `Show your creative power? We recomend the 1 year plan`,
  },
   {
    isPopular: false,
    isActive: false,
   
    duration: "monthly",
    name: "Business",
    pricing: "",
    discountedPrice: "Coming soon",
    per: "",
    features: [
      "Maximum daily post count: pending",
      "Maximum channel count: pending",
      "Monthly schedule count: pending",
      "Single post scheduling count: pending",
      "Maximum schedule age:pending ",
      "Bulk Posting: pending",
      "Contest ticket: pending",
      "Hollo space: pending",
      "Premium Support",
    ],
    desc: `Want more power? coming soon `,
  },{
    isPopular: false,
    isActive: true,
    name: "Personal",
    percentOff:"30 % OFF",
    duration: "bi-annually",
    pricing: "₦37,200",
    discountedPrice: "₦26,040",
    per: "/bi annually",
    features: [
      "Maximum daily post count: 12",
      "Maximum channel count: 5",
      "Monthly schedule count: 50",
      "Single post scheduling: count 5",
      "Maximum schedule age: 6 Month",
      "Contest ticket: True",
      "Hollo space: 1GB",
      "Bulk Posting: False",
      "Premium Support",
    ],
    desc: ` Way to go enjoy the power of hollo.`,
  },
  {
    isPopular: true,
    isActive: true,
    percentOff:"30 % OFF",
    name: "Creatives",
    duration: "bi-annually",
    pricing: "₦74,400",
    discountedPrice: "₦52,080",
    per: "/bi annually",
    features: [
      "Maximum daily post count: 25",
      "Maximum channel count: 12",
      "Monthly schedule count: 100",
      "Single post scheduling count: 15",
      "Maximum schedule age: 6 Months",
      "Bulk Posting: False",
      "Contest ticket: True",
      "Hollo space: 2.5GB",
      "Premium Support",
    ],
    desc: `Show your creative power? We recomend the 1 year plan`,
  },
   {
    isPopular: false,
    isActive: false,
    duration: "bi-annually",
    name: "Business",
    pricing: "",
    discountedPrice: "Coming soon",
    per: "",
    features: [
      "Maximum daily post count: pending",
      "Maximum channel count: pending",
      "Monthly schedule count: pending",
      "Single post scheduling count: pending",
      "Maximum schedule age: pending",
      "Bulk Posting: pending",
      "Contest ticket: pending",
      "Hollo space: pending",
      "Premium Support",
    ],
    desc: `Show your creative power? We recomend the 1 year plan`,
  },
  {
    isPopular: false,
    isActive: true,
    percentOff:"40 % OFF",
    name: "Personal",
    duration: "annually",
    pricing: "₦74,400",
    discountedPrice: "₦44,640",
    per: "/year",
    features: [
      "Maximum daily post count: 12",
      "Maximum channel count: 5",
      "Monthly schedule count: 50",
      "Single post scheduling: count 5",
      "Maximum schedule age: 1 year",
      "Bulk Posting: False",
      "Contest ticket: True",
      "Hollo space: 1GB",
      "Premium Support",
    ],
    desc: ` Way to go enjoy the power of hollo.`,
  },
  {
    isPopular: true,
    isActive: true,
    percentOff:"40 % OFF",
    name: "Creatives",
    duration: "annually",
    pricing: "₦148,800",
    discountedPrice: "₦89,280",
    per: "/year",
    features: [
      "Maximum daily post count: 25",
      "Maximum channel count: 12",
      "Monthly schedule count: 100",
      "Single post scheduling count: 15",
      "Maximum schedule age: 1 year",
      "Bulk Posting: False",
      "Contest ticket: True",
      "Hollo space: 2.5GB",
      "Premium Support",
    ],
    desc: `Show your creative power? We recomend the 1 year plan`,
  },
   {
    isPopular: false,
    isActive: false,
    duration: "annually",
    name: "Business",
    pricing: "",
    discountedPrice: "Coming soon",
    per: "",
    features: [
      "Maximum daily post count: pending",
      "Maximum channel count: pending",
      "Monthly schedule count: pending",
      "Single post scheduling count: pending",
      "Maximum schedule age: pending",
      "Bulk Posting: pending",
      "Contest ticket: pending",
      "Hollo space: pending",
      "Premium Support",
    ],
    desc: `Want more power? coming soon `,
  },
];

const PageSubcription: FC<PageSubcriptionProps> = ({ className = "" }) => {
  const [searchParams ] = useSearchParams();
  const [selectedDuration, setSelectedDuration] = useState("monthly");
  const referalCode = searchParams.get("referal")
  const renderPricingItem = (pricing: PricingItem, index: number) => {
    if (pricing.duration !== selectedDuration) {
      return null;
    }
    return (
      <div className="p-4 ">
      <div
        key={index}
        className={`h-full relative px-6 py-8 rounded-3xl border-2 w-full   p-5 mx-auto bg-white shadow-lg sm:p-10 mt-10 lg:mt-20 lg:p-16 ${
          pricing.isPopular
            ? "border-primary-500"
            : "border-neutral-100 dark:border-neutral-700"
        }`}
      >

        {pricing.percentOff ? (
        <h1 className="indicator-item indicator-top indicator-center badge badge-primary font-bold ">{pricing.percentOff}</h1>
       ):("")} 
       
       {pricing.isPopular && (
          <span className="bg-primary-500 dark:text-primary px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
            POPULAR
          </span>
        )}
        <div className="mb-8">
          <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium ">
            {pricing.name}
          </h3>
          <h2 className="text-3xl leading-none flex items-center">
            <span className="text-primary text-block">{pricing.discountedPrice}</span>
            <span className="text-lg ml-1 font-normal text-neutral-500">
              {pricing.per}
            </span>
          </h2>
          <h3 className="text-2xl leading-none flex items-center">
            <span className="text-neutral-500"><del>{pricing.pricing}</del></span>
            <span className="text-lg ml-1 font-normal text-neutral-500">
              {/* {pricing.per} */}
            </span>
          </h3>
        </div>
        <nav className="space-y-4 mb-8">
          {pricing.features.map((item, index) => (
            <li className="flex items-center" key={index}>
              <span className="mr-4 inline-flex flex-shrink-0 text-primary">
                {/* <CheckIcon className="w-5 h-5" aria-hidden="true" /> */}
              </span>
              <span className="text-neutral-700 dark:text-neutral-300">
                {item}
              </span>
            </li>
          ))}
        </nav>
        <div className="flex flex-col mt-auto mb-5">
        {pricing.isPopular ? (
          <a href={`/register?package=${pricing.name}&price=${pricing.discountedPrice}&duration=${pricing.duration}&referal=${referalCode}`} className={`btn btn-primary`}>
            <button type="submit"  >
               SUBSCRIBE
            </button></a>
          ) : (

            <a href={`/register?package=${pricing.name}&price=${pricing.discountedPrice}&duration=${pricing.duration}&referal=${referalCode}`} className={`btn btn-secondary`} style={{ pointerEvents: !pricing.isActive ? 'none' : 'auto' }}>
            SUBSCRIBE
            </a>
           
           
          )}

          <p className="text-xs text-neutral-500 dark:text-neutral-400 mt-3">
            {pricing.desc}
          </p>
        </div>
      </div>
      </div>
    );
  };

  return (
    <div>
        <BgGlassmorphism />
        
    {/* <div role="tablist" className="tabs pt-5 pb-5 tabs-boxed flex justify-center">
      <button
        onClick={() => setSelectedDuration("monthly")}
        className={`tab ${selectedDuration === "monthly" ? "tab-active" : ""}`}
      > Monthly
      </button>
      <button
        onClick={() => setSelectedDuration("bi-annually")}
        className={`tab ${
          selectedDuration === "bi-annually" ? "tab-active" : ""
        }`}
      > 6 Months
      </button>
      <button
        onClick={() => setSelectedDuration("annually")}
        className={`tab ${
          selectedDuration === "annually" ? "tab-active" : ""
        }`}
      > Annually
      </button>
    </div> */}

 

    <section className="text-neutral-600 text-sm md:text-base overflow-fit flex justify-center mb-4">
  
      <div className="grid grid-cols-1 gap-5 xl:grid-cols-3 sm:gap-6 md:gap-8">
        {pricings.map(renderPricingItem)}
      </div>
   
    </section>
  </div>
 
  );
};

export default PageSubcription;
