import React, { useState, useEffect } from 'react';
import Layout from './LayoutPage';
import {checkAccessAuthorization} from '../lib/check-auth'
import { useDispatch } from 'react-redux';
import PostForm from '../forms/PostForm';
import FooterNavigationContainer from '../containers/FooterNavigationContainer';
import PostContainer from '../containers/PostContainer'
import { FetchPost, logPageVisit } from '../lib/util';




import { baseUrl, holloHeader } from '../lib/util';
import axios from 'axios';



const  ScheduledPage=()=> {
  logPageVisit()
    //enforce access athorization 
    checkAccessAuthorization(useDispatch) 
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchPosts = async () => {
        try {
          const fetchedPosts = await FetchPost("False",1,100);
          setPosts(fetchedPosts);
        } catch (error) {
          // Handle the error gracefully
          console.log(error);
          alert(error.message);
        } finally {
          // Set loading to false regardless of success or failure
          // setLoading(false);
        }
      };
  
      fetchPosts();
    }, []);
    return(

        <Layout>
          {/* <div className="mockup-window border bg-base-300">
            <div className="flex justify-center px-4 py-16 bg-base-200">Hello!</div>
          </div> */}

<div className='container mx-auto sm:pr-60 sm:pl-60 '>

              <div className="navbar bg-primary text-primary-content p-0">
              <button className="btn btn-ghost text-xl">My Scheduled post</button>
        
            </div>
  <div className='flex items-center justify-center'>
  <PostContainer posts={posts} />

  </div>
</div>
        <FooterNavigationContainer page="scheduled" />
      </Layout>
        
    );
}
export default ScheduledPage;