
        import React,{useState} from 'react';
        import { Formik, Form, useField,Field } from 'formik';
        import {useDispatch, useSelector } from 'react-redux';
        import {UpdateDeviceChannels,baseUrl, holloHeader} from '../lib/util'
        import * as Yup from 'yup';

        const LoginSchema = Yup.object().shape({
            tagline: Yup.string().required('Required'),
            name: Yup.string().required('Required'),
            tone: Yup.string().required('Required'),
           
            marketing_goal: Yup.string().required('Required'),
            category: Yup.string().required('Required'),
                
            color: Yup.string().required('Required'),
            location: Yup.string().required('Required')
       
          });
          
          const MyTextArea = ({label, ...props}) => {
            const [field, meta] = useField(props);
            return (
                <>
                    <label htmlFor={props.id || props.name}>{label}</label>
                    <textarea className="textarea m-2  w-full max-w-sm" {...field} {...props} />
                    {meta.touched && meta.error ? (  <div className="error">{meta.error}</div> ) : null}
                </>
            );
          };
        
          
        export default function BrandForm() {
        
          const isDirty= useSelector(state=>state.login.isDirty);
          let error= useSelector(state=>state.login.errors) 
          const [processing,setProccesing]=useState(false)

        

          return(
            <div>
          
            <div className="hero min-h-screen bg-base-200">
          <div className="hero-content flex-col lg:flex-row-reverse">
           
       
        {isDirty?
        
          <div className="alert alert-error shadow-sm">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" 
              strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
            <span>{error}</span>
          </div>
        </div>:""
        }
        
        
              <div className="card-body">
              <Formik
                    initialValues={{ 
                      category: '',
                      name: '',
                      tone: '',
                      marketing_goal: '',
                      color: '',
                      location: '',
                      socialmedia_goal: '',
                      tagline: '' 
                    }}
                    validationSchema={LoginSchema}
        
                    onSubmit={async (values, { setSubmitting }) => {
                      const axios = require('axios').default;
                     
                      setProccesing(true);
                        let data = JSON.stringify(values, null, 2);
                        alert(data)
                        const reg_response = await axios.post(baseUrl + "/brands/create", data,  holloHeader());
                        
                        if (reg_response.data) {
                            let reg_responsedata = reg_response.data;
                    
                            if (reg_responsedata.success) {
                           
                              alert("nice")
                               //success 
                              
                              
                            } else {
                                console.log(reg_responsedata.error);
                                alert(reg_responsedata.error);
                               // setProccesing(false);
                            }
                        }  
                        

                 
                        setSubmitting(false);
                    
                    }}
               >
               {({ errors, touched }) => (
                  <Form>
                  <div className="form-grid">


                     <div className="form-group">
                      <label htmlFor="category" className="label"> <span className="label-text">Business Category</span></label>
                      <Field
                        name="category"
                        className="input w-full input-bordered"
                        placeholder="Business Category"
                        type="text"
                      />
                      {errors.category && touched.category ? (
                        <div className="label font-bold text-primary">{errors.category}</div>
                      ) : null}
                    </div>


                  <div className="form-group">
                      <label htmlFor="name" className="label"> <span className="label-text">Brand Name</span></label>
                      <Field
                        name="name"
                        className="input w-full input-bordered"
                        placeholder="Brand Name"
                        type="text"
                      />
                      {errors.name && touched.name ? (<div className="label font-bold text-primary">{errors.name}</div> ) : null}
                    </div>

                    <div className="form-group">
                      <label htmlFor="color" className="label">
                        <span className="label-text">Brand Color</span>
                      </label>
                      <Field
                        name="color"
                        className="input w-full input-bordered"
                        placeholder="Brand Color"
                        type="text"
                      />
                      {errors.color && touched.color ? (
                        <div className="label font-bold text-primary">{errors.color}</div>
                      ) : null}
                    </div>
        

                    <div className="form-group">
                      <label htmlFor="tone" className="label">
                        <span className="label-text">Brand Tone</span>
                      </label>
                      <Field
                        name="tone"
                        className="input w-full input-bordered"
                        placeholder="Tone"
                        type="text"
                      />
                      {errors.tone && touched.tone ? (
                        <div className="label font-bold text-primary">{errors.tone}</div>
                      ) : null}
                    </div>
        
               


                    <div className="form-group">
                      <label htmlFor="tagline" className="label">
                        <span className="label-text">Tagline</span>
                      </label>
                      <Field
                        name="tagline"
                        className="input w-full input-bordered"
                        placeholder="tagline"
                        type="text"
                      />
                      {errors.tagline && touched.tagline ? (
                        <div className="label font-bold text-primary">{errors.tagline}</div>
                      ) : null}
                    </div>
        
                 
        
                    <div className="form-group">
                      <label htmlFor="socialmedia_goal" className="label">
                        <span className="label-text">Social media  goal</span>
                      </label>
                      <Field
                        name="socialmedia_goal"
                        className="input w-full input-bordered"
                        placeholder="What is your social media goal"
                        type="text"
                      />
                      {errors.socialmedia_goal && touched.socialmedia_goal ? (
                        <div className="label font-bold text-primary">{errors.socialmedia_goal}</div>
                      ) : null}
                    </div>
        
                    
                    <div className="form-group">
                      <MyTextArea
                        label="Marketing Goal"
                        className="textarea w-full textarea-grey w-full max-w-x"
                        name="marketing_goal"
                        rows="3"
                        placeholder="Enter Marketing Goal"
                      />
                      <label className="label font-bold text-primary">
                        {errors.marketing_goal && touched.marketing_goal ? (<div>{errors.marketing_goal}</div>) : null}
                      </label>
                   </div>
              
                    <div className="form-group">
                      <MyTextArea
                        label="Location"
                        className="textarea w-full textarea-grey w-full max-w-x"
                        name="location"
                        rows="5"
                        placeholder="Enter business location"
                      />
                      <label className="label font-bold text-primary">
                        {errors.location && touched.location ? (<div>{errors.location}</div>) : null}
                      </label>
                </div>
              
              
                  </div>
                  
                  <div className="form-footer">
        
                    <div className="form-control mt-6">
                      {processing ? (
                        <button className="btn loading">Saving</button>
                      ) : (
                        <button type="submit" className="btn btn-outline btn-primary">Save</button>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
         
              </div>
            </div>
          </div>
         
           
         
          </div>
          )}
        
        