import React, { FC } from "react";

export interface Props {
  author: string;
  message: string;
  className?: string;
  icon?:string
}

const TestimonyCard: FC<Props> = ({
  className = "",
  author="Nice title",
  message="Placeholder message",
  icon=""
}) => {
  return (
    <div className={`justify-center bg-white card px-3 py-5 sm:p-6  lg:w-full bg-base-100 shadow-sm ${className}`}>
    {icon && ( <img    src={icon}  alt={`${author}'s icon`}  className="flex-shrink-0 w-20 h-20 rounded-full overflow-hidden -mt-16 ring-primary ring ring-offset-2 "  />
    )}
    <div className="mt-1">
      <h2 className=" sm:text-lg font-semibold "> <span className="text-primary line-clamp-1">{author}</span></h2>
      <span className="block mt-[2px] text-3xl text-neutral-500 dark:text-white"> {message}</span>
    </div>
  </div>
   
  );
};

export default TestimonyCard;
