import React from 'react';
import Socials from '../components/Socials';



const  FooterContainer = () => {
    
 
    return(
       <>
      <footer  className="footer  bottom-0 p-10 bg-neutral text-neutral-content">
            <div>
            <img src="black-logo.png" width="120"  alt="Logo" />

            
            <p>Chukolo Core limited.<br />Providing reliable tech services since 2018.</p>
            </div> 
            <nav>
    <header className="footer-title">Legal</header> 
    <a href='/terms' target='blank' className="link link-hover">Terms of use</a>
    <a href='/privacy' target='blank' className="link link-hover">Privacy policy</a>
    <a href='/data-policy' target='blank' className="link link-hover">Data policy</a>
    <a href='/code-of-conduct' target='blank' className="link link-hover">Code of conduct</a>
  </nav>
            <div>
                        <Socials />
                        <p>
                        <span className='text-primary font-bold'>Made in </span>
                        <span className='text-primary font-bold'>Ogugu </span>
                        <span  className='text-primary font-bold'>Nigeria</span>
                        </p>
                        
            </div>
        </footer> 

     
        <footer className="footer footer-center p-4 bg-base-300 text-base-content">
        <div>
          <p>Copyright © 2024 - All right reserved by Chukolo Core  </p>
        </div>
      </footer>
      </>
        
    );
}

export default FooterContainer;