import React from 'react'

import 'react-slideshow-image/dist/styles.css';

import 'react-slideshow-image/dist/styles.css';
import Typist from 'react-typist';

function BannerContainemri() {
  //  spanish: "Hablemos",
    // chinese: "让我们聊一聊",
    // igala: "Ka hollo",
    // igbo: "Kpọlụ anya",
    // yoruba: "Jẹ alaye",
    // hausa: "Mu gina",
    // german: "Lass uns plaudern",
    // four:     <img src="white-icon.png"  alt="User Avatar" />,
    const retype = {
      renderMsg: true,
    }
 
  return (
    <>

      <div  className='flex flex-col sm:flex-row mb-16 h-screen lg:h-auto'>
        {/* Left side with text */}
        <div className='flex-1 flex flex-col justify-center p-8 '>
          <div className="relative">
            <div className="flex justify-center items-center text-center">
              <div className='w-full'>
                <Typist
                  startDelay={1000}
                  cursor={{
                    show: false,
                    blink: true,
                    element: <span className='text-6xl text-primary'>|</span>,
                    hideWhenDone: true,
                  }}
                >
                  <span className="text-6xl sm:text-5xl text-4xl font-bold text-primary">
                    Want to...
                  </span>
                  <br />
                  <span className="text-6xl sm:text-5xl text-4xl font-bold text-primary">
                    Stay fresh in the mind of your customers?
                  </span>
                  <Typist.Delay ms={1000} />
                  <Typist.Backspace count={41} delay={10} />
                  <span className="text-6xl sm:text-5xl text-4xl font-bold text-primary">
                    Grow your page?
                  </span> 
                  <Typist.Delay ms={1000} />
                  <Typist.Backspace count={16} delay={200} />
                  <span className="text-6xl sm:text-5xl text-4xl font-bold text-primary">
                    Get more post engagement?
                  </span> 
                  <Typist.Delay ms={1000} />
                  <Typist.Backspace count={25} delay={200} />
                  <span className="text-6xl sm:text-5xl text-4xl font-bold text-primary">
                    Build a stronger brand?
                  </span>
                  <Typist.Delay ms={1000} />
                  <Typist.Backspace count={23} delay={200} />
                  <span className="text-6xl sm:text-5xl text-4xl font-bold text-primary">
                    Build online trust?
                  </span> 
                  <Typist.Delay ms={1000} />
                  <Typist.Backspace count={20} delay={200} />
                  <span className="text-6xl sm:text-5xl text-4xl font-bold text-primary">
                    Sell more?
                  </span> 
                  <Typist.Delay ms={1000} />
                  <Typist.Backspace count={20} delay={200} />
                  <span className="text-6xl sm:text-5xl text-4xl font-bold text-primary">
                    Use hollo to post from one dashboard
                  </span> 
                  <Typist.Delay ms={3000} />
                  <Typist.Backspace count={45} delay={200} />
                  <span className="text-6xl sm:text-5xl text-4xl font-bold text-primary">
                    Try hollo now 
                  </span>
                  <br />
               
                </Typist>
                <a className='btn btn-primary btn-block mt-4' href='#register'>Use hollo</a>
              </div>
            </div>
          </div>
        </div>

        {/* Right side with image */}
        <div className='flex-1'>
          <img src='followers.png' alt='Hero Image' className='w-full h-auto object-contain ' />
        </div>
      </div>


    </>
  );
}

export default BannerContainemri();
