import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { checkAccessAuthorization } from '../../lib/check-auth';
import Layout from '../LayoutPage';

const AdminBase = () => {
  const dispatch = useDispatch();
  checkAccessAuthorization(dispatch);



  // State for various forms
  const [activeSection, setActiveSection] = useState('account');


 

  return (
    <Layout>
      <div className="flex">
        <div className="w-1/4 p-4 bg-gray-200 min-h-screen">
          <h2 className="text-xl font-bold mb-4">Settings</h2>
          <nav className="flex flex-col space-y-2">
            <button className="btn btn-outline" onClick={() => setActiveSection('posts')}>Posts</button>
            <button className="btn btn-outline" onClick={() => setActiveSection('users')}>Users</button>
            <button className="btn btn-outline" onClick={() => setActiveSection('agents')}>Agents</button>
            <button className="btn btn-outline" onClick={() => setActiveSection('jobs')}>Jobs</button>
            <button className="btn btn-outline" onClick={() => setActiveSection('database')}>Database</button>
          </nav>
        </div>

        <div className="w-3/4 p-4">
          {activeSection === 'posts' && (
            <div id="account" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Account</h3>
 
            </div>
          )}
          {activeSection === 'users' && (
            <div id="subscription" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Subscription</h3>
              {/* <ContactForm /> */}

             

            </div>
          )}
          {activeSection === 'agents' && (
            <div id="brand" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Brand</h3>
            
            </div>
          )}
          {activeSection === 'jobs' && (
            <div id="security" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Security</h3>
              {/* <PasswordForm /> */}
            </div>
          )}
          {activeSection === 'database' && (
            <div id="posts" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Posts</h3>
              <div className="form-control w-52">
                <label className="label cursor-pointer">
                  <span className="label-text">Auto post to enabled channels</span>
                  <input type="checkbox" className="toggle toggle-primary" defaultChecked />
                </label>
              </div>
              <div className="form-control w-52">
                <label className="label cursor-pointer">
                  <span className="label-text">Posting mode</span>
                  <input type="checkbox" className="toggle toggle-primary" defaultChecked />
                </label>
              </div>
            </div>
          )}
          {activeSection === 'channel' && (
            <div id="channel" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Channel</h3>
          
           
              
            </div>
          )}
          {activeSection === 'hashtags' && (
            <div id="hashtags" className="mb-8">
              <h3 className="text-lg font-semibold mb-2">Hashtags</h3>
           
           
            </div>
          )}
        </div>
      </div>

    </Layout>
  );
};

export default AdminBase;
