import React from 'react'
import { calculateTimeElapsed } from '../lib/util';
import { Link } from 'react-router-dom';



export default function InlineUserCard({ key,user,created=null }) {

  return (
            <>
          <span className="flex items-start mb-0 pb-0">
             {user?
             <>
              <Link to={`/user/${user.username}`}>
                <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                  <div className="w-10 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                    <img src={user.profile_photo ? user.profile_photo : 'user.jpg'} alt="User" />
                  </div>
                </label>
              </Link>
         
       
         
              <span className="flex ">
              <span>
                 <span className="ml-1 font-bold"> {user.fullname.charAt(0).toUpperCase() + user.fullname.slice(1)}</span>
                <span className="ml-1 text-gray-500">@{user.username}</span>
                {/* <div class="badge badge-primary">published</div> */}
               
              </span>
              <span className='pl-1 text-primary font-xs'>{calculateTimeElapsed(created)}</span>
              </span>
              </>
              :""}
            </span>

       
            </>
        );
}


