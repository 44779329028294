import { call, put, takeLatest } from 'redux-saga/effects' 
import {  
  POST_ERROR,POST_REQUESTING,POST_SUCCESS 
} from '../constants'
import { baseUrl } from '../lib/util'


function Api (payload) {
    return fetch(baseUrl+"/posts/create", {
        method: 'POST',
        headers: {  'Content-Type': 'application/json',
        }, body: payload,
      }) .then(response => response.json()).then(json => json).catch((error) => { throw error })
}


function* Flow (action) {  
  try {
  //call the api 
 
   const response = yield call(Api,action.payload)
     console.log(response)

  yield put({ type: POST_SUCCESS, response })
 // window.location="feed"
  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
   yield put({ type: POST_ERROR, error })
  }
}

function* Watcher () {
  yield takeLatest(POST_REQUESTING, Flow)
}


export default Watcher