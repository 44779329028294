import React, { useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { checkAccessAuthorization } from '../../lib/check-auth';
import Layout from '../LayoutPage';
import {  baseUrl, logPageVisit, holloHeader} from '../../lib/util';



function AdminJobApplications() {
   logPageVisit()
   checkAccessAuthorization(useDispatch) 

   const [loading, setLoading] = useState(false);
   const [applyCount, setApplyCount] = useState(0);
   const [applications, setApplications] = useState([]);
   const page_size=500
   const hxxrl = JSON.parse(localStorage.getItem('hxxrl'));

   const [viewMore, setShowMore] = useState({}); // Initialize an empty object

 
  const handleViewMore = (id) => {
    setShowMore((prevViewMore) => {
      const newViewMore = { [id]: !prevViewMore[id] };
      Object.keys(prevViewMore).forEach((key) => {
        if (key !== id) {
          newViewMore[key] = false;
        }
      });
      return newViewMore;
    });
  };
  if (!hxxrl || !hxxrl.access_token) {
    throw new Error('No access token found');
  }
  const axios = require('axios').default; 

useEffect(async () => {
  try {
    setLoading(true);
    await GetJobCount()
    const applications = await FetchApplication(1, page_size);
    setApplications(applications);
    localStorage.setItem('last_application_page', 1);

  } catch (error) {
    console.log(error);
    alert(error.message);
  } finally {
    setLoading(false);
  }
}, [page_size]);
function copyText(path) {
  navigator.clipboard.writeText(path);
  alert("Copied to clipboard!");
}
const FetchApplication = async (page, page_size) => {
  try {
    const token = JSON.parse(localStorage.getItem('hxxrl'));
    if (!token || !token.access_token) {
      throw new Error('No access token found');
    }
    const url = `${baseUrl}/jobs/applications?page=${page}&page_size=${page_size}`;
    const response = await axios.get(url, {  headers: {  'Content-Type': 'application/json' }});

    const responseData = response.data;

    if (responseData.success) {
      return responseData.data;
    } else {
      throw new Error(responseData.error.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
}; 

const GetJobCount = async () => {
  try {
   
    const url = `${baseUrl}/jobs/status`;
    const response = await axios.get(url, {  headers: {  'Content-Type': 'application/json' }});

    const responseData = response.data;

    if (responseData.success) {
      setApplyCount(responseData.data)
     
    } else {
      throw new Error(responseData.error.message);
    }
  } catch (error) {
    console.log(error);
    throw new Error(error);
  }
};
  return(
      
        <Layout>
   <div className="flex  bg-neutral text-neutral-content mb-4">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Job applications </h2>

                    <div className="stats shadow">
          
                  <div className="stat">
                    <div className="stat-figure text-secondary">
                    </div>
                    <div className="stat-title">Applied </div>
                    <div className="stat-value text-primary">{applyCount} </div>

                  </div>
                  
                
                  
        </div>

           </div>
        </div> 


      

        <div className='container pb-10 mx-auto sm:pr-30 sm:pl-30 '>
       
        <div className="flex flex-col space-y-4">

       
       

     
  
   <div className='p-4'>
      <h1 className="text-2xl mb-2">Job applications</h1>

      <div className=''>
      <table  className="table w-full overflow-x-auto border-collapse" style={{ maxWidth: "100vw" }} >
          <thead>
          <tr>
  <th style={{ width: '50px' }}>Fullname</th>
  <th style={{ width: '40px' }}>phone</th>
  <th style={{ width: '50px' }}>email</th>
  <th style={{ width: '10px' }}>expirience</th>
  <th style={{ width: '200px' }}>why</th>
  <th style={{ width: '50px' }}>location</th>
  <th style={{ width: '50px' }}>CV</th>
  {/* <th style={{ width: '100px' }}>Actions</th> */}
</tr>
          </thead>
          <tbody>
          {applications.length > 0 ? (
        <>
          {applications.map((application) => (
             <tr>
             <td  style={{ width: '50px' }}>
           <p>   {application.fullname}</p>
             </td>
             <td  style={{ width: '50px' }}>
               {application.phone}
             </td>
             <td  style={{ width: '50px' }}>
              {application.email}
             </td>
             <td style={{ width: '10px' }}> 
             {application.expirience}
              </td>
              <td>
            <p className='w-96' style={{ whiteSpace: 'pre-line' }}>
              {application.why.length > 60 ? (
                <>
                  {application.why.substring(0, 60)}...
                  <span
                    className='view-more font-bold text-primary'
                    onClick={() => handleViewMore(application.email)}
                  >
                    View More
                  </span>
                  {viewMore[application.email] && (
                    <span>{application.why.substring(60)}</span>
                  )}
                </>
              ) : (
                application.why.replace(/\n/g, '<br>')
              )}
            </p>
          </td>
             <td>{application.location}</td>
           
             
    
             <td><button className='font-bold text-primary' onClick={() => copyText(application.cv_path)}>Click to Copy</button></td>
             <td >  
              {/* <select className="select select-primary w-full max-w-xs mb-4 mt-4">
                   <option disabled defaultValue>Select an action</option>
                   <option value="lagos">Reject</option>
                   <option value="abuja">Accept</option>
                   <option value="port-harcourt">Interview</option>
                   <option value="abuja">Delete</option>
                 </select> */}
             </td>
           </tr>
           ))}
</>
      


      
      ) : (
           
        <div className="flex w-full pt-4">
       
        <div className="grid  flex-grow card w-1/2 p-4 m-2 bg-base-300 rounded-box place-items-center">
          <p className='font-bold'>No appication</p>
        </div>
        </div> 
       
      
      
      )}
          
          
        
          </tbody>
        
          
        </table>
      </div>


   </div>
 

       



      </div>

      </div> 

     


       </Layout>
   
  
    );
}
export default AdminJobApplications;