import React from 'react'

import {convertNumbThousand} from '../lib/util'
import PostChannelCard from './PostChannelCard';


export default function PostChannelCards({ channels}) {

  return (
            <>
            {channels.length > 0 &&
             (  <div class="card-actions justify-start">
                      {channels.map((channel)=>(
                      <PostChannelCard channel={channel}/>
                      ))} 
                </div>)
              }
            </>
        );
}


