import React from 'react';
import PostCard from '../cards/PostCard';
import { Link } from 'react-router-dom';

const PostContainer = ({ posts }) => {
  return (
    <div className="grid place-items-center h-full ">
      {posts.length > 0 ? (
        <div className="card bg-base-100  shadow-xl w-full">
          {posts.map((post) => (<PostCard key={post.id} post={post} /> ))}
        </div>
      ) : (
           
        <div className="flex w-full pt-4">
       
        <div className="grid  flex-grow card w-1/2 p-4 m-2 bg-base-300 rounded-box place-items-center">
          <p className='font-bold'>Empty post, to make a post kindly click the button below</p>
        <Link className="btn btn-neutral btn-md m-4   normal-case text-xl" to="/home">Make a post</Link>
        </div>
        </div> 
       
      
      
      )}
    </div>
  );
};

export default PostContainer;