import React,{useState,useEffect} from 'react';

import Layout from '../LayoutPage';
import { baseUrl} from '../../lib/util'
import Card3 from '../../cards/CardCategory/Card3';
import { Link } from 'react-router-dom';



const Jobs = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
       
        const axios = require('axios').default;
        const url = `${baseUrl}/careers/jobs?void_key=CYPh_SECRET_LO_l1&status=open&page=1&page_size=50`;

        const response = await axios.get(url, { headers: {'Content-Type': 'application/json'}});
      
        
        if (response.data && response.data.success) {
       
          setJobs(response.data.data);
        } else {
          throw new Error(response.data.error.message);
        }
      } catch (error) {
        console.error(error);
        alert(error.message);
      }
    };

    fetchJobs();
  }, []);


  return ( <Layout>
    <div className="flex flex-col  h-screen  mb-4 mt-4">
        <div>


         <div className='flex bg-neutral text-neutral-content p-8 flex-col'>
          
         <h1 className="card-title lg:text-8xl text-6xl font-bold">Careers</h1>
         <h2 className="text-4xl font-bold mb-4 mt-4">Join our team</h2>
          </div> 
      
        </div>
         
          
            {jobs.length > 0 ? (
        <>
          {jobs.map((job) => (
        

           <div className={` px-3 m-2 py-5 sm:p-3 card   lg:w-full shadow-xl`} >

           {/* <img src="" className={`flex-shrink-0 w-20 h-20 rounded-full overflow-hidden`}/> */}
     
           <div className="mt-1 ">
             <h2 className={` sm:text-lg font-semibold m-4`}> <span className="line-clamp-1">{job.title}</span> </h2>
            
             <p className="mb-4 mt-4">
             <article>  {job.about}  </article>
      </p>
             <span className={`block m-4 text-sm `}>
                   
      
             </span>

             <hr />

             <div className='flex flex-row pt-4 space-x-4'>
            <div className='flex-item'>
                Location: <span className='font-bold text-primary'>{job.location}</span>
               </div>
              
               <div className='flex-item'>
                Status <span className='font-bold text-primary'>{job.status}</span>
                </div>
                <div className='flex-item'>
                Type: <span className='font-bold text-primary'>{job.type}</span> 
                </div>

                <div className='flex-item'>
        
               <Link
                  to={`/careers/job/${job._id}`}
                  state={{job}}
                  className='btn btn-md btn-primary btn-outline btn-block p-2'
               
                >
                  Apply
                </Link>
               </div>
             </div>
            
           </div>


         </div>

           ))}
</>
      


      
      ) : (
           
        <div className="flex w-full pt-4">
       
        <div className="grid  flex-grow card w-1/2 p-4 m-2 bg-base-300 rounded-box place-items-center">
          <p className='font-bold'>No open job at the moment </p>
        </div>
        </div> 
       
      
      
      )}
          

        
        </div> 
   

    
   
     </Layout>
  );
};

export default Jobs;

