import React, { useContext } from 'react';
import  { Link } from 'react-router-dom';
import {useDispatch } from 'react-redux';
import {checkAuthorization} from '../lib/check-auth'
import { unsetClient } from '../actions/ClientAction';
import { ThemeContext } from '../ThemeContext';
import ConnectChannelComponent from '../components/ConnectChannelComponent'
const  Navigation = () => {

   const dispatch=useDispatch() 
   
   const isLoggedIn = checkAuthorization(dispatch) 

   const handleLogout = () => {
    dispatch(unsetClient())
  }
 
  const { theme, setTheme } = useContext(ThemeContext);
  const logo = theme === 'dark' ? "black-logo.png" : "white-logo.png" ;

  const handleThemeChange=()=>{
    let currentTheme=localStorage.getItem("theme")
    if (currentTheme === 'light') {
     setTheme('dark');
      localStorage.setItem('theme', 'dark')
    } else {
      setTheme('light');
      localStorage.setItem('theme', 'light')
    }
  }
  let profile_photo ="user.jpg"
  let pages=[]; 
  const athenticatedPages = [
    //  { path: "/rewards",  name: "Rewards" },
     { path: "/settings",  name: "Settings" }
  ]
   const unAthenticatedPages = [
    // { path: "/",  name: "Home" },  
    { path: "/careers",  name: "Careers" },
    { path: "/login",  name: "Login" }
  ]

  if(isLoggedIn ==true)
  {
    pages=athenticatedPages;
    const hxxrl = JSON.parse(localStorage.getItem('hxxrl'));
    profile_photo=hxxrl["profile_photo"]
  }
  else
  {
      pages=unAthenticatedPages;
  }
    return(
        <div  className="navbar bg-base-100">
        <div className="flex-1">  
         <Link className="btn btn-ghost normal-case text-xl top-0 min-h-0   z-0" to="/"><img src={logo} width="120"  alt="Logo" /></Link>
        </div>
        <div className="flex-none">

            <ul className="menu menu-horizontal p-0">
           
          
           
            {pages.map(link => ( <li><Link className="btn btn-ghost normal-case text-xl" to={link.path}>{link.name}</Link> </li>))}

            <label  className="swap swap-rotate">
  

  <input className="invisible" onClick={()=>handleThemeChange()}  type="checkbox" />
      
    
      <svg className="swap-on fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.64,17l-.71.71a1,1,0,0,0,0,1.41,1,1,0,0,0,1.41,0l.71-.71A1,1,0,0,0,5.64,17ZM5,12a1,1,0,0,0-1-1H3a1,1,0,0,0,0,2H4A1,1,0,0,0,5,12Zm7-7a1,1,0,0,0,1-1V3a1,1,0,0,0-2,0V4A1,1,0,0,0,12,5ZM5.64,7.05a1,1,0,0,0,.7.29,1,1,0,0,0,.71-.29,1,1,0,0,0,0-1.41l-.71-.71A1,1,0,0,0,4.93,6.34Zm12,.29a1,1,0,0,0,.7-.29l.71-.71a1,1,0,1,0-1.41-1.41L17,5.64a1,1,0,0,0,0,1.41A1,1,0,0,0,17.66,7.34ZM21,11H20a1,1,0,0,0,0,2h1a1,1,0,0,0,0-2Zm-9,8a1,1,0,0,0-1,1v1a1,1,0,0,0,2,0V20A1,1,0,0,0,12,19ZM18.36,17A1,1,0,0,0,17,18.36l.71.71a1,1,0,0,0,1.41,0,1,1,0,0,0,0-1.41ZM12,6.5A5.5,5.5,0,1,0,17.5,12,5.51,5.51,0,0,0,12,6.5Zm0,9A3.5,3.5,0,1,1,15.5,12,3.5,3.5,0,0,1,12,15.5Z"/></svg>
      
      
      <svg className="swap-off fill-current w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.64,13a1,1,0,0,0-1.05-.14,8.05,8.05,0,0,1-3.37.73A8.15,8.15,0,0,1,9.08,5.49a8.59,8.59,0,0,1,.25-2A1,1,0,0,0,8,2.36,10.14,10.14,0,1,0,22,14.05,1,1,0,0,0,21.64,13Zm-9.5,6.69A8.14,8.14,0,0,1,7.08,5.22v.27A10.15,10.15,0,0,0,17.22,15.63a9.79,9.79,0,0,0,2.1-.22A8.11,8.11,0,0,1,12.14,19.73Z"/></svg>
      
            </label>

          
           
            </ul>
            
        </div>

        <div>
      {isLoggedIn && (
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img src={profile_photo} />
            </div>
          </label>
          <ul
            tabIndex={0}
            className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
          >
            {/* <li>
                {isLoggedIn && ( <ConnectChannelComponent /> )}
            </li>  */}
            <li>
              <Link className="font-bold normal-case text-xl" to="/me">
              Profile
              </Link>
            </li>
            <li>
              <Link className="font-bold normal-case text-xl" to="/feedback">
                Feedback
              </Link>
            </li>
            <li>
              <button
                className="font-bold normal-case text-xl"
                onClick={handleLogout}
              >
                Logout
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>




        </div>
    );
}

export default Navigation;