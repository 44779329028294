import React from 'react';

const Socials = ({ values }) => {
 
  return (
    <>
        <span className="footer-title">Our socials</span> 
                      <div className="grid grid-flow-col gap-4">
                      <a href='https://x.com/hollobychukolo' target='blank'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                          <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
                      </svg>
                      </a>
                      <a href='https://www.youtube.com/channel/UCojsEn4HpvVtt8YdcJ9b5cQ' target='blank'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                          <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
                      </svg>
                      </a>
                      <a href='https://www.linkedin.com/showcase/ka-hollo' target='blank'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="fill-current" viewBox="0 0 24 24" fill="#0077B5" width="24" height="24">
                          <path d="M20.5 0h-17C1.1 0 0 1.1 0 2.5v19C0 22.9 1.1 24 2.5 24h17c1.4 0 2.5-1.1 2.5-2.5v-19C23 1.1 21.9 0 20.5 0zM7.8 19.4h-3.4V9h3.4v10.4zM6.1 7.9c-1 0-1.7-0.8-1.7-1.7S5.1 4.5 6.1 4.5c1 0 1.7 0.8 1.7 1.7s-0.8 1.7-1.7 1.7zM19.4 19.4h-3.4v-5.5c0-1.3 0-3-1.8-3s-2.1 1.4-2.1 2.9v5.6h-3.4V9h3.2v1.6h0c0.5-0.9 1.7-1.9 3.3-1.9 3.5 0 4.1 2.3 4.1 5.4V19.4z" />
                      </svg>
                      </a>
                      <a href='https://www.facebook.com/hollofromchukolo' target='blank'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                        <path d="M22.675 0h-21.35C.595 0 0 .593 0 1.326v21.348C0 23.407.593 24 1.326 24H12.81v-9.294H9.692V11.31h3.117V8.412c0-3.1 1.893-4.788 4.658-4.788 1.325 0 2.462.098 2.795.142v3.24h-1.918c-1.504 0-1.794.714-1.794 1.762v2.311h3.587l-.467 3.396h-3.12V24h6.116c.733 0 1.326-.593 1.326-1.326V1.326C24 .593 23.407 0 22.675 0z"/>
                      </svg>

                      </a>
                      <a href='https://www.instagram.com/hollofromchukolo' target='blank'>
                      
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                      <path d = "M12 2.163c3.204 0 3.584.012 4.85.07 1.17.054 1.796.24 2.217.404a4.412 4.412 0 011.52.96c.425.425.727.94.96 1.52.163.421.35 1.047.404 2.217.058 1.266.07 1.646.07 4.85s-.012 3.584-.07 4.85c-.054 1.17-.24 1.796-.404 2.217a4.412 4.412 0 01-.96 1.52 4.412 4.412 0 01-1.52.96c-.421.163-1.047.35-2.217.404-1.266.058-1.646.07-4.85.07s-3.584-.012-4.85-.07c-1.17-.054-1.796-.24-2.217-.404a4.412 4.412 0 01-1.52-.96 4.412 4.412 0 01-.96-1.52c-.163-.421-.35-1.047-.404-2.217-.058-1.266-.07-1.646-.07-4.85s.012-3.584.07-4.85c.054-1.17.24-1.796.404-2.217a4.412 4.412 0 01.96-1.52 4.412 4.412 0 011.52-.96c.421-.163 1.047-.35 2.217-.404 1.266-.058 1.646-.07 4.85-.07zm0-2.163C8.735 0 8.332.01 7.052.07 5.717.133 4.731.34 3.905.654a6.473 6.473 0 00-2.347 1.36 6.473 6.473 0 00-1.36 2.347C.34 5.269.133 6.255.07 7.59.01 8.869 0 9.271 0 12s.01 3.131.07 4.41c.063 1.335.27 2.321.654 3.147.302.78.71 1.486 1.36 2.347.65.86 1.367 1.577 2.347 2.347.86.65 1.566 1.057 2.347 1.36.826.384 1.812.591 3.147.654C8.869 24 9.271 24 12 24s3.131-.01 4.41-.07c1.335-.063 2.321-.27 3.147-.654.78-.302 1.486-.71 2.347-1.36.86-.65 1.577-1.367 2.347-2.347.65-.86 1.057-1.566 1.36-2.347.384-.826.591-1.812.654-3.147C23.99 15.131 24 14.729 24 12s-.01-3.131-.07-4.41c-.063-1.335-.27-2.321-.654-3.147a6.473 6.473 0 00-1.36-2.347 6.473 6.473 0 00-2.347-1.36c-.826-.384-1.812-.591-3.147-.654C15.131.01 14.729 0 12 0zM12 5.838a6.162 6.162 0 106.162 6.162A6.162 6.162 0 0012 5.838zm0 10.162a4 4 0 114-4 4 4 0 01-4 4zm6.406-11.845a1.44 1.44 0 11-1.44-1.44 1.439 1.439 0 011.44 1.44z"/>
                    </svg>


                      </a>
                      </div>
    </>
  );
};
export default Socials;



