import React,{useState} from 'react';
import { Formik, Form, Field } from 'formik';
import {useDispatch, useSelector } from 'react-redux';
import {UpdateDeviceChannels,baseUrl} from '../lib/util'
import  { Link } from 'react-router-dom';
import * as Yup from 'yup';
const LoginSchema = Yup.object().shape({
    fullname: Yup.string().required('Required') ,  
    email: Yup.string().required('Required'),
    phone: Yup.string(),
    comment: Yup.string(),
  });
  


  
export default function WaitingListForm() {
    const [apiCallSuccess, setApiCallSuccess] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const handleTermsAcceptedToggle = () => {
        setTermsAccepted(!termsAccepted);
      };
  let error= useSelector(state=>state.login.errors) 
  const [proccesing,setProccesing]=useState(false)
  return(
    <>
    <div className="card flex-shrink-0 w-full max-w-sm shadow bg-base-100">

 

<div className="card-body">
<Formik
        initialValues={{ fullname: '', email: '' , phone: '' , comment: '' }}
        validationSchema={LoginSchema}

        onSubmit={async (values, { setSubmitting }) => {
          const axios = require('axios').default;
          setProccesing(true);
            //call api and pass in data
            let data =JSON.stringify(values, null, 2);
          
                
            try {
              const response = await axios.post(baseUrl+"/marketing/waiting", data, {headers: {'Content-Type': 'application/json'}});
            
              if (response.data) {
                let responseData = response.data;
            
                if (responseData.success) {
                  setApiCallSuccess(true);
            
                } else {
                    alert("bug")
                  setProccesing(false)
                  console.log(responseData.error.message);
                  alert(responseData.error.message);
                }
              }
            } catch (error) {
              setProccesing(false)
              console.error('An error occurred while making the request:', error);
              console.error('Request config:', error.config);
              if (error.response) {
                console.error('Response status:', error.response.status);
                console.error('Response data:', error.response.data);
                console.error('Response headers:', error.response.headers);
              } else if (error.request) {
                console.error('Request:', error.request);
              }
              // handle the error
            }
          
            
            setSubmitting(false);
        
        }}
        >
        {({ errors, touched }) => (
          <Form>
          <div className="form-control">
            <label htmlFor="fullname" className="label"> <span className="label-text">Fullname</span></label>
            <Field name="fullname"  className="input w-full max-w-x  input-bordered" placeholder="John Okafor"  type="text" />
            <label className="label font-bold text-primary">
                {errors.fullname && touched.fullname ? <div>{errors.fullname}</div> : null}
            </label>
            
          </div>

          <div className="form-control">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <Field name="email" type="email" placeholder="contact@email.com"  className="input input-bordered  w-full max-w-x" />
            <label className="label font-bold text-primary">
            {errors.email && touched.email ? ( <div>{errors.email}</div> ) : null}
            </label>
          </div>  

          <div className="form-control">
                        <label className="label"> <span className="label-text">Phone</span> </label>
                        <Field name="phone" type="text" placeholder="Optional" className="input input-bordered" />
                        <label className="label font-bold text-primary"> {errors.phone && touched.phone ? ( <div>{errors.phone}</div> ) : null}
                        </label>
          </div> 

          <div className="form-control">
            <label htmlFor="comment" className="label"> <span className="label-text">Comment</span></label>
            <Field name="comment"  className="input w-full max-w-x  input-bordered" placeholder="comment"  type="textarea" />
            <label className="label font-bold text-primary">
                {errors.comment && touched.comment ? <div>{errors.comment}</div> : null}
            </label>
            
          </div>

          <label className='label  font-bold text-primary' htmlFor="termsAccepted">
                        <input
                          type="checkbox"
                          id="termsAccepted"
                          className='text-primary mr-3'
                          checked={termsAccepted}
                          onChange={handleTermsAcceptedToggle}
                        />
                      <span >Add me to beta testers.</span>
                      </label>

          <div className="form-control mt-6">

          {proccesing ? (
              <button className="btn loading">Submiting</button>
            ) : (
              <button  type="submit" className="btn btn-outline btn-primary">Submit</button>
            )}


          </div>
            
          </Form>
        )}
</Formik>

</div>



{apiCallSuccess && (
  <>
    <input type="checkbox" id="my_modal_6" className="modal-toggle" defaultChecked />
    <div className="modal">
      <div className="modal-box w-11/12 max-w-5xl">
        <h3 className="font-bold text-lg text-primary">Great first step! 🎊</h3>
        <p className="py-4">With Hollo, you'll unlock a world of convenience and efficiency in managing your social media accounts. Say goodbye to the hassle of individually posting on each platform and embrace the power of centralized posting. From Facebook to Twitter, Instagram to LinkedIn, and beyond, Hollo has got you covered!</p>
        <p className="py-4">But that's not all—Hollo also offers advanced post scheduling capabilities. Now you can plan your content ahead of time, ensuring your posts go live at the perfect moment to engage your audience. Prepare your social media presence for success and enjoy more time for other important aspects of your life or business.</p>
        <p className="py-4">Stay tuned and get ready to experience the magic of Hollo firsthand. We'll keep you updated on the latest developments and notify you as soon as we're ready to launch. Get ready to take your social media game to new heights!</p>
        <p className="py-4">Once again, congratulations on becoming part of the Hollo community. We can't wait to have you on board. Let the journey begin! 🚀✨</p>
        <hr />
        <p className='text-primary font-bold'>Connect with us on our socials to get real-time updates</p>
        <div className="join">
          <button className="join-item pl-2">
            <a href='https://twitter.com/lets_hollo' target='blank'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>
              </svg>
            </a>
          </button>
          <button className="join-item pl-2">
            <a href='https://www.youtube.com/channel/UCojsEn4HpvVtt8YdcJ9b5cQ' target='blank'>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current">
                <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path>
              </svg>
            </a>
          </button>
          <button className="join-item p-2">
            <a href='https://www.linkedin.com/showcase/ka-hollo' target='blank'>
              <svg xmlns="http://www.w3.org/2000/svg" className="fill-current" viewBox="0 0 24 24" fill="#0077B5" width="24" height="24">
                <path d="M20.5 0h-17C1.1 0 0 1.1 0 2.5v19C0 22.9 1.1 24 2.5 24h17c1.4 0 2.5-1.1 2.5-2.5v-19C23 1.1 21.9 0 20.5 0zM7.8 19.4h-3.4V9h3.4v10.4zM6.1 7.9c-1 0-1.7-0.8-1.7-1.7S5.1 4.5 6.1 4.5c1 0 1.7 0.8 1.7 1.7s-0.8 1.7-1.7 1.7zM19.4 19.4h-3.4v-5.5c0-1.3 0-3-1.8-3s-2.1 1.4-2.1 2.9v5.6h-3.4V9h3.2v1.6h0c0.5-0.9 1.7-1.9 3.3-1.9 3.5 0 4.1 2.3 4.1 5.4V19.4z" />
              </svg>
            </a>
          </button>
        </div>
        <div className="modal-action">
          <label htmlFor="my_modal_6" className="btn">Close!</label>
        </div>
      </div>
    </div>
  </>
)}


</div>
  


  </>
  )}

