import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
import Layout from '../../LayoutPage';

const PrivacyPolicy = () => {
  return (
    <Layout>
    <div className="max-w-3xl mx-auto my-8">
      <h1 className="text-3xl font-bold mb-4">Hollo - Privacy Policy</h1>
      <p className="mb-6">Last Updated: 26th December 2023</p>

      <p className="mb-4">
        Welcome to Hollo, the ultimate social media management tool designed to streamline your social media experience.
        This Privacy Policy outlines how Chukolo Core collects, uses, and protects the information you provide when
        using Hollo. By accessing or using Hollo, you consent to the practices described in this Privacy Policy.
      </p>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">1. Information We Collect</h2>
        <p>
          We may collect personal information such as your name, email address, and social media account information
          when you register or use Hollo. Additionally, we may collect non-personal information such as device
          information and usage statistics.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">2. How We Use Your Information</h2>
        <p>
          We use the information we collect to provide, maintain, and improve Hollo, including personalized features
          and content. Your information may also be used to communicate with you, respond to inquiries, and send
          important notices.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">3. Sharing of Information</h2>
        <p>
          We do not sell, trade, or rent your personal information to third parties. However, we may share information
          with trusted third-party service providers who assist us in operating Hollo or conducting our business,
          subject to confidentiality agreements.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">4. Security</h2>
        <p>
          We take reasonable measures to protect the security of your information. However, no method of transmission
          over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">5. Cookies</h2>
        <p>
          Hollo may use cookies to enhance your experience. You can set your browser to refuse all or some browser
          cookies, but certain features of Hollo may not function properly without cookies.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">6. Changes to Privacy Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. The latest version will be posted on this page with the
          updated date. We encourage you to review this Privacy Policy periodically for any changes.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">7. Contact Information</h2>
        <p>
          If you have any questions or concerns regarding this Privacy Policy, please{' '}
          <Link to="/contact" className="text-blue-500">
            contact us
          </Link>{' '}
          at hollo.chukolo.com
        </p>
      </section>
    </div>
    </Layout>

  );
};

export default PrivacyPolicy;
