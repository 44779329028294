import React,{useState} from 'react';
import { Formik, Form, Field } from 'formik';
import {useDispatch } from 'react-redux';
import { loginRequest } from '../actions/LoginAction';
import * as Yup from 'yup';
const phoneSchema = Yup.object().shape({
    
    phone: Yup.string().required('Required') ,  
    email: Yup.string().required('Required'),
  });

export default function ContactForm() {
  const [showphone, setShowphone] = useState(false);
  const dispatch=useDispatch()
  return(
   
    
  
      <div className="card-body">
       <Formik
      initialValues={{ username: '', phone: '' }}
      validationSchema={phoneSchema}

      onSubmit={(values, { setSubmitting }) => {
       
        
          //call api and pass in data
          let data =JSON.stringify(values, null, 2);
       
          //api call

          setSubmitting(false);
       
      }}
    >
       {({ errors, touched }) => (
         <Form>
      
        <div className="form-control">
                <label className="label"> <span className="label-text">Phone</span> </label>
                <Field name="phone"  type="text"   className="input input-bordered" />
               
                <label className="label font-bold text-primary">{errors.phone && touched.phone ? ( <div>{errors.phone}</div> ) : null}
                </label>
        </div> 
        <div className="form-control">
                <label className="label"> <span className="label-text">Email </span> </label>
                <Field name="email"  type="text"  className="input input-bordered" />
              
                <label className="label font-bold text-primary">{errors.email && touched.email ? ( <div>{errors.email}</div> ) : null}
                </label>
        </div> 
        <div className="form-control mt-6">
          <button  type="submit" className="btn btn-primary">Submit</button>
        </div>
          
         </Form>
      )}
       </Formik>
      </div>
  

  )}

