import React,{useState} from 'react';
import { Formik, Form, Field } from 'formik';
import {baseUrl} from '../lib/util'
import  { Link } from 'react-router-dom';
import {useSelector } from 'react-redux';
import * as Yup from 'yup';
const LoginSchema = Yup.object().shape({
    username: Yup.string().required('Required'),
  });
  
const ResetSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
  password: Yup.string()
  .min(2, 'Too Short!')
  .max(50, 'Too Long!')
  .required('Required')
  .matches(/\d/, 'Password must contain at least a number'),  
confirmPassword: Yup.string()
  .oneOf([Yup.ref('password'), null], 'Passwords must match')
  .required('Required'),
  });

  
export default function ResetForm() {
  const [showPassword, setShowPassword] = useState(false);
  const isDirty= useSelector(state=>state.login.isDirty);
  let error= useSelector(state=>state.login.errors) 
  const [proccesing,setProccesing]=useState(false)
  const [formOne,setFormOne]=useState(1)
  const [formTwo,setFormTwo]=useState(0)
  return(
    <div>
  
    <div className="hero min-h-screen bg-base-200">
  <div className="hero-content flex-col lg:flex-row-reverse">
    <div className="text-center lg:text-left">
      <h1 className="text-5xl font-bold text-primary">Reset your password</h1>
      <p className="py-6">Kindly provide your username or email to reset your password</p>
    </div>
    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
{isDirty?

    <div className="alert alert-error shadow-sm">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" 
      strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
    <span>{error}</span>
  </div>
</div>:""
}


      <div className="card-body">
     <div>
      {formOne?
      <Formik
     initialValues={{ username:'' }}
     validationSchema={LoginSchema}

     onSubmit={async (values, { setSubmitting }) => {
       const axios = require('axios').default;
       setProccesing(true);
         //call api and pass in data
         try {
           const url = `${baseUrl}/security/init/passwordreset?username=${values.username}`;
           localStorage.setItem("00X1",values.username)
           const response = await axios.get(url, {headers: {'Content-Type':'application/json'}});
         
           if (response.data) {
             let responseData = response.data;
         
             if (responseData.success) {
            setFormOne(0)
            setFormTwo(1)
            setProccesing(false)
             } else {
               setProccesing(false)
               console.log(responseData.error);
               alert(responseData.error);
             }
           }
         } catch (error) {
           setProccesing(false)
           console.error('An error occurred while making the request:', error);
           console.error('Request config:', error.config);
           if (error.response) {
             console.error('Response status:', error.response.status);
             console.error('Response data:', error.response.data);
             console.error('Response headers:', error.response.headers);
           } else if (error.request) {
             console.error('Request:', error.request);
           }
           // handle the error
         }
         setSubmitting(false);
         }}
>
{({ errors, touched }) => (
  <Form>
 <div className="form-control">
   <label htmlFor="username" className="label">
     <span className="label-text">Username or Email</span>
   </label>
   <Field name="username"  
    className="input w-full max-w-x  input-bordered" placeholder="username or email"  type="text" />
   <label className="label font-bold text-primary">
   {errors.username && touched.username ? <div>{errors.username}</div> : null}
   </label>
   
 </div>


 <Link className="" to="/#register">  <span className='label  text-primary'> No account? Register</span></Link>

 <div className="form-control mt-6">

 {proccesing ? (
      <button className="btn loading">Authenticating</button>
   ) : (
     <button  type="submit" className="btn btn-outline btn-primary">Submit</button>
   )}


 </div>
   
  </Form>
)}
      </Formik>
      :""}
     
 
     </div>
     <div>
    {formTwo?
      <Formik
            initialValues={{ 
            username:localStorage.getItem("00X1"),
            password:'',
            code:''
           }}
            validationSchema={ResetSchema}

            onSubmit={async (values, { setSubmitting }) => {
              const axios = require('axios').default;
              setProccesing(true);
                //call api and pass in data
                let data =JSON.stringify(values, null, 2);
                   
                try {
                  const response = await axios.put(baseUrl+"/security/finalize/passwordreset", data, {headers: {'Content-Type': 'application/json'}});
                
                  if (response.data) {
                    let responseData = response.data;
                
                    if (responseData.success) {
                      alert("Congratulations your password has been changed kindly login with your new password");
                      localStorage.removeItem("00X1")
                      window.location.href = "/login";
                      setProccesing(false)
                    } else {
                      setProccesing(false)
                      console.log(responseData.error);
                      alert(responseData.error);
                    }
                  }
                } catch (error) {
                  setProccesing(false)
                  console.error('An error occurred while making the request:', error);
                  console.error('Request config:', error.config);
                  if (error.response) {
                    console.error('Response status:', error.response.status);
                    console.error('Response data:', error.response.data);
                    console.error('Response headers:', error.response.headers);
                  } else if (error.request) {
                    console.error('Request:', error.request);
                  }
                  // handle the error
                }
              
                
                setSubmitting(false);
            
            }}
       >
       {({ errors, touched }) => (
         <Form>
        <div className="form-control">
        <div className="form-control">
        <label htmlFor="username" className="label">
            <span className="label-text">Code</span>
          </label>
                  <Field name="code"  
                    className="input w-full max-w-x  input-bordered" placeholder="01332"  type="text" />
                    <label className="label font-bold text-primary">
                    {errors.code && touched.code ? <div>{errors.code}</div> : null}
                    </label>
                <label className="label"> <span className="label-text">Password</span> </label>
                <Field name="password"  type={showPassword ? "text" : "password"}   className="input input-bordered" />
                {/* <button onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button> */}
                <label className="label font-bold text-primary">{errors.password && touched.password ? ( <div>{errors.password}</div> ) : null}
                </label></div> 
                <div className="form-control">
                <label className="label"> <span className="label-text">Confirm password </span> </label>
                <Field name="confirmPassword"  type={showPassword ? "text" : "password"}   className="input input-bordered" />
                {/* <button onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button> */}
                <label className="label font-bold text-primary">{errors.confirmPassword && touched.confirmPassword ? ( <div>{errors.confirmPassword}</div> ) : null}
                </label></div> 

          
        </div>
       
   
        <div className="form-control mt-6">

        {proccesing ? (
             <button className="btn loading">Processing</button>
          ) : (
            <button  type="submit" className="btn btn-outline btn-primary">Submit</button>
          )}

      
        </div>
          
         </Form>
      )}
    </Formik>:""}
 
     </div>
      </div>
    </div>
  </div>
   </div>
   
 
  </div>
  )}

