import React, { useEffect, useState } from 'react';
import Layout from './LayoutPage';
import {checkAccessAuthorization} from '../lib/check-auth'
import { useDispatch } from 'react-redux';
import FooterNavigationContainer from '../containers/FooterNavigationContainer';
import PostContainer from '../containers/PostContainer'

import BgGlassmorphism from '../components/BgGlassmorphism'
import { baseUrl } from '../lib/util';

const  NotificationPage = () => {
    //enforce access athorization 
    checkAccessAuthorization(useDispatch) 
    let user_id =JSON.parse(localStorage.getItem('hxxrl')).user_id
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
      const eventSource = new EventSource(`${baseUrl}/notifications/${user_id}`);
  
      eventSource.onmessage = (event) => {
   
         const notification = JSON.parse(event.data);
        setNotifications(prevNotifications => [...prevNotifications,notification]);
      };
  
      return () => {
        eventSource.close();
      };
    }, [user_id]);
    return(

        <Layout>
 
        <div className="flex flex-col text-neutral-content  mb-4 mt-4">
      


         <div className='flex flex-col m-4  '>
          
         <h1 className="card-title text-4xl font-bold">Notfications</h1>
         <hr />

         <ul>
            {notifications.map((notification, index) => (
            <li key={index}>
                
           <div className={` px-3 m-2 py-5 sm:p-3 card  lg:w-full bg-base-100 `} >

{/* <img src="" className={`flex-shrink-0 w-20 h-20 rounded-full overflow-hidden`}/> */}

<div className="mt-1 ">
  <h2 className={`text-base sm:text-lg font-semibold m-4`}> <span className="line-clamp-1">{notification.title}</span> </h2>
 

  <span className={`block m-4 text-sm `}>
       <article>     {notification.detail} </article>
  </span>

  {/* <hr /> */}

  {/* <div className='flex flex-row pt-4 space-x-4'>
 <div className='flex-item'>

    </div>
   
    <div className='flex-item'>
 
     </div>
     <div className='flex-item'>
   
     </div>

     <div className='flex-item'>

    
    </div>
  </div> */}
 
</div>


</div>
              
            </li>
            ))}
        </ul>
      
        </div>
           
          
         
     


  
          
     
        
        </div> 
   


     <FooterNavigationContainer page="Notification" />
      </Layout>
        
    );}
    

export default NotificationPage;