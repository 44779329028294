import React from 'react'

import PostImageCard from '../cards/PostImageCard'

import PostMessageCard from './PostMessageCard'
import AnalyticCard from './AnalyticCard'
import InlineUserCard from './InlineUserCard'
import PostChannelCards from './PostChannelCards'
import TruncateText from '../components/TruncateText'
export default function PostCard({ key, post }) {
  

  return (
    <>
            <div key={key} className="card-body w-full">

             <InlineUserCard user={post.user} created={post.created} />
          
       
             <div className="container mx-auto p-4">
                <TruncateText message={post.raw_message} maxLength={200} />
              </div>

                  {post.tags.length > 0 &&
                  (
                    <div class="card-actions justify-start">
                  {post.tags.map((tag)=>(
                    <div class="badge badge-ghost">#{tag}</div>
                  ))} </div>
                )
              }
               <PostImageCard images={post.attachments} />
             <PostChannelCards channels={post.channels} />
             {/* <AnalyticCard post ={post}/> */}
            </div>
         {/* <div key={key} className="card-body w-full">
             <InlineUserCard user={post.user} created={post.created} />
             <PostMessageCard message={post.raw_message} tags={post.tags} />
             <PostImageCard images={post.attachments} />
             <PostChannelCards channels={post.channels} />
             <AnalyticCard post ={post}/>
            </div>     */}
   </>
        );
}


