import { takeEvery,put } from 'redux-saga/effects' 
import {CLIENT_UNSET} from '../constants'


function* Logout () {

    // remove our token from the local storage 
    localStorage.removeItem('hxxrl')
    localStorage.removeItem('channels')
    localStorage.removeItem('connecting_platform')
    localStorage.removeItem('is_page_connection')
    localStorage.removeItem('connecting_payload')
    localStorage.removeItem("has_verified_code")
    localStorage.removeItem("cachedPosts")
    localStorage.removeItem("last_feed_page")
    // redirect to the /login screen
  window.location.href = "/login"
  }

function* logoutWatcher () { 
  yield takeEvery(CLIENT_UNSET, Logout)
}


export default logoutWatcher