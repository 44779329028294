import React from 'react'

import {convertNumbThousand} from '../lib/util'


export default function PostChannelCard({ channel}) {
 
  return (
            <>
             <div class="card-actions justify-start">
            <a target='blank' href={channel.platform_post_url}>
           <div class="btn-group">
                     
                    
                      <button class="btn btn-circle avatar btn-xs btn-circle btn-outline">
                       <img  className = "w-5 rounded-full"  src={channel.channel_icon} alt={channel.channel_title}/> 
                      </button>

                      <button class="btn btn-xs">
                    
                      {channel.channel_title}
                   
                      </button>
  

                     </div>
                     </a>
           </div>
            </>
        );
}


