import React,{useState,useEffect} from 'react';
import Layout from '../LayoutPage';
import  { useSearchParams } from 'react-router-dom';
import { UpdateDeviceChannels,baseUrl } from '../../lib/util';

function PaymentSuccess() {
  const axios = require('axios').default;
  const [searchParams ] = useSearchParams();
  const [proccesing,setProccesing]=useState(false) 
  const readyStepTwo = searchParams.get("trxref")

  useEffect(() => {
    // Call finalizeRegistration function when component is mounted
    finalizeRegistration();
  }, []); // Empty dependency array ensures the effect runs only once on component mount

  async function finalizeRegistration() {
    const storedDataString = localStorage.getItem('initRegResponse');
    const storedData = JSON.parse(storedDataString);

    if (storedData) {
      let finalizePayload = {
        "user_id": storedData.user_id,
        "package_id": storedData.package_id,
        "amount_paid": storedData.amount,
        "status": "success",
        "duration": storedData.duration,
        "payment_currency": "NGN",
        "payment_ref": readyStepTwo,
      }

      let data = JSON.stringify(finalizePayload);

      try {
        const reg_response = await axios.post(baseUrl + "/security/register/finalize", data, { headers: { 'Content-Type': 'application/json' } });

        if (reg_response.data && reg_response.data.success) {
          localStorage.removeItem('initRegResponse')
          localStorage.removeItem('initRege0X')
          localStorage.removeItem('0x10')
          localStorage.removeItem('0XP')
          localStorage.removeItem('0XD')
          localStorage.removeItem('0XR')
          localStorage.removeItem('isServiceTypeChosen')
          localStorage.removeItem('selectedServiceType')
          
         await handleAutoLogin(storedData.email, storedData.void_x, "/welcome");
        } else {
          console.log(reg_response.data.error);
          alert(reg_response.data.error);
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred. Please try again later.');
      }
    }
  };


  async function handleAutoLogin(username,password,redirect_url){

      let logindata =JSON.stringify({username:username, password:password}, null, 2);
   
    
       const response = await axios.post(baseUrl+"/security/token", logindata, {headers: {'Content-Type': 'application/json'}});
     
       if (response.data) {
         let responseData = response.data;
     
         if (responseData.success) {
         
           localStorage.setItem('hxxrl', JSON.stringify(responseData.data));
           
           await UpdateDeviceChannels(responseData.data.user_id); 
           // Update redirect to redirect_url if provided, otherwise go home
           if (redirect_url) {
                   window.location.href = redirect_url;
               } else {
                   window.location.href = "/home";
              }
         } else {
           console.log(responseData.error);
           alert(responseData.error);
           setProccesing(false)
         }
       }
   }

    return(
      

        <Layout>
       {/* header banner */}
          

        <div class="flex flex-col space-y-4">

       
          <div className="avatar flex  justify-center mt-4">
          <div className="w-full max-w-xs rounded-full ring ring-primary ring-offset-base-100 ring-offset-2 mt-2">
               <img src="\payment success.jpg" className='w-md' />
          </div>
            </div>

            <h2 className="text-xl flex  justify-center">
               <span className="badge badge-lg"> </span>
               
               </h2>

               <div className="flex  bg-neutral  text-neutral-content">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Congratulations your payment is received.🎉</h2>

            <span >
            Kindly hold while we provision your account. don't do anything we'll take your to the required next step.
            </span>

           </div>
        </div>   
           
      </div>
         
       </Layout>
   
  
    );
}
export default PaymentSuccess;