import React,{useState} from 'react';
import { Formik, Form, Field } from 'formik';
import {useDispatch, useSelector } from 'react-redux';
import {UpdateDeviceChannels,UpdateDeviceHashtags,baseUrl} from '../lib/util'
import  { Link } from 'react-router-dom';
import * as Yup from 'yup';
const LoginSchema = Yup.object().shape({
    password: Yup.string().required('Required') ,  
    username: Yup.string().required('Required'),
  });
  


  
export default function LoginForm() {

 

  const isDirty= useSelector(state=>state.login.isDirty);
  let error= useSelector(state=>state.login.errors) 
  const [proccesing,setProccesing]=useState(false)
  return(
    <div>
  
    <div className="hero min-h-screen bg-base-200">
  <div className="hero-content flex-col lg:flex-row-reverse">
    <div className="text-center lg:text-left">
      <h1 className="text-5xl font-bold text-primary">Login 😊</h1>
      <p className="py-6">Hope you're enjoying the awesomeness of hollo.
      login to explore</p>
    </div>
    <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
{isDirty?

    <div className="alert alert-error shadow-sm">
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" className="stroke-current flex-shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24">
      <path strokeLinecap="round" strokeLinejoin="round" 
      strokeWidth="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
    <span>{error}</span>
  </div>
</div>:""
}


      <div className="card-body">
      <Formik
            initialValues={{ username: '', password: '' }}
            validationSchema={LoginSchema}

            onSubmit={async (values, { setSubmitting }) => {
              const axios = require('axios').default;
              setProccesing(true);
                // call api and pass in data
                let data = JSON.stringify(values, null, 2);
                    
                try {

                  const response = await axios.post(baseUrl+"/security/token", data, {headers: {'Content-Type': 'application/json'}});
                
                  if (response.data) {
                    let responseData = response.data;
                
                    if (responseData.success) {
                      localStorage.setItem('hxxrl', JSON.stringify(responseData.data));
                      await UpdateDeviceChannels(responseData.data.user_id); // Wait for the function to complete
                      await UpdateDeviceHashtags() 
                      window.location.href = "/home";
                    } else {
                      setProccesing(false)
                      console.log(responseData.error.message);
                      alert(responseData.error.message);
                    }
                  }
                 } catch (error) {
                  setProccesing(false)
                  console.error('An error occurred while making the request:', error);
                  console.error('Request config:', error.config);
                  if (error.response) {
                    console.error('Response status:', error.response.status);
                    console.error('Response data:', error.response.data);
                    console.error('Response headers:', error.response.headers);
                  } else if (error.request) {
                    console.error('Request:', error.request);
                  }
                  // handle the error
                }
              
                
                setSubmitting(false);
            
            }}
       >
       {({ errors, touched }) => (
         <Form>
        <div className="form-control">
          <label htmlFor="username" className="label">
            <span className="label-text">Username</span>
          </label>
          <Field name="username"  
           className="input w-full max-w-x  input-bordered" placeholder="username"  type="text" />
          <label className="label font-bold text-primary">
          {errors.username && touched.username ? <div>{errors.username}</div> : null}
          </label>
          
        </div>
        <div className="form-control">
          <label className="label">
            <span className="label-text">Password</span>
          </label>
          <Field name="password" type="password" placeholder="****"  className="input input-bordered  w-full max-w-x" />
          <label className="label font-bold text-primary">
          {errors.password && touched.password ? ( <div>{errors.password}</div> ) : null}
          </label>
        </div>  

        <Link className="" to="/#register">  <span className='label  text-primary'> No account? Register</span></Link>
        
        <Link className="" to="/reset-pass">  <span className='label  text-primary'>Forgot pasword? Reset</span></Link>
        <div className="form-control mt-6">

        {proccesing ? (
             <button className="btn loading">Authenticating</button>
          ) : (
            <button  type="submit" className="btn btn-outline btn-primary">Submit</button>
          )}

      
        </div>
          
         </Form>
      )}
    </Formik>
 
      </div>
    </div>
  </div>
   </div>
   
 
  </div>
  )}

