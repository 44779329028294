import React , { useState,useEffect } from 'react';
import  { Link,useSearchParams } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import {baseUrl,parseCurrency,formatCurrency, capitalizeFirstLetter,callback_url_register} from '../lib/util'


// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import * as Yup from 'yup';

const SignupSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(2, 'Too Short!')
      .max(25, 'Too Long!')
      .required('Required'),
    phone: Yup.string()
      .min(9, 'The phone numer is too short')
      .max(14, 'The phone numer is too short Long!')
      .required('Required'),
    username: Yup.string()
    .required('Username is Required'),  
    lastname: Yup.string()
      .min(2, 'Too Short!')
      .max(25, 'Too Long!')
      .required('Required'),

    
      
    password: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required')
      .matches(/\d/, 'Password must contain at least a number'),  
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'), });
   
    const axios = require('axios').default;
    const RegisterForm =()=> {
    const [searchParams ] = useSearchParams();
    const [proccesing,setProccesing]=useState(false) 
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    
    let selectedPackage = searchParams.get("package")
    let referalCode =  searchParams.get("referal")
    let selectedDuration = searchParams.get("duration")
    let price=searchParams.get("price")
 
   let serviceType=searchParams.get("serviceType")

  useEffect(() => {
   
   if(serviceType){


    }
  }, []);


    const handleTermsAcceptedToggle = () => {
      setTermsAccepted(!termsAccepted);
    };
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    }
    let subscription  = () => {
      return {
        "duration": selectedDuration,
        "package_name": selectedPackage
      };
    };

  const handleDurationOrPackageEdit = () => {    window.location.href ="/#register" };
  
        
   
  const initializePayment = async (userEmail, regAmount,duration,package_name) => {
  //sk_test_8ffb36b8d31ccce3e37423345778e21afebb5ff6
  //'sk_live_e653962e2768b55defba919aa8938b602b50c339' callback_url_register
    const url = 'https://api.paystack.co/transaction/initialize';
    const secretKey = 'sk_live_e653962e2768b55defba919aa8938b602b50c339'; 
    const data = {
      email: userEmail,
      amount: regAmount*100,
      metadata: {
       
        "custom_fields": 
          {
            "package": package_name,
            "duration": duration
          }
      },
      callback_url :callback_url_register
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${secretKey}`,
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify(data),
    });
    const result = await response.json();
    if (result.status === true) {
      const authorizationUrl = result.data.authorization_url;
      window.location.href = authorizationUrl;
    } else {
      alert(result.message);
    }
  };



   const storedinitRege0X=localStorage.getItem('initRege0X');

    return (
      <div>
        <div className="flex  bg-neutral text-neutral-content mb-4 mt-4">
          <div className="card-body items-center text-center">
       
            <h1 className="text-5xl font-bold text-primary p-10">Register 🎬</h1>
           <div className="stats  text-primary-content stats-vertical lg:stats-horizontal">
          <div className="stat">
            <div className="stat-figure text-secondary">
            </div>
            <div className="stat-title">Amount</div>        
            <div className="stat-value text-primary">{price} </div>
              <div className="stat-desc "> </div>
          </div>
          
          {/* <div className="stat">
            <div className="stat-figure text-secondary">
            </div> 
            <div className="stat-title">Duration  </div>
            <div className="stat-value  ">{updatedDuration} </div>
            <button  className='btn btn-default btn-xs' >Change</button>
        
          </div> */}
          {/* <div className="stat">
            <div className="stat-figure text-secondary">
            </div> 
            <div className="stat-title">Subscription type  </div>
            <div className="stat-value  ">{selectedServiceType}</div>
            <button className='btn btn-default btn-xs'>Change</button>
          </div>   
                 */}
          <div className="stat">
            <div className="stat-figure text-secondary">
            </div>
            <div className="stat-title">Package Type</div>
            <div className="stat-value text-primary">{ serviceType || selectedPackage} </div>
            <button  className='btn btn-default btn-xs'  onClick={handleDurationOrPackageEdit}>Change</button>

          </div>
        
                        
                          
              </div>

                     
        


           </div>
        </div> 
      <div className="hero min-h-screen bg-base-200">
            <div className="hero-content flex-col lg:flex-row-reverse">
     
      <div className="card flex-shrink-0 w-full max-w-sm shadow-2xl bg-base-100">
        <div className="card-body">

        <Formik
          initialValues={{
            firstname:storedinitRege0X?storedinitRege0X.firstname:"",
            lastname:storedinitRege0X?storedinitRege0X.lastname:"",
            phone: storedinitRege0X?storedinitRege0X.phone:"",
            username:storedinitRege0X?storedinitRege0X.username:"",
            email: storedinitRege0X?storedinitRege0X.email:"",
            why:storedinitRege0X?storedinitRege0X.why:" ",
            password:"",
            confirmPassword:"",
            referedby: referalCode !== null && referalCode !== "" ? referalCode : undefined,
          }}
         
         
          validationSchema={SignupSchema}
          onSubmit={ async (values, { setSubmitting }) => {
           
            if (termsAccepted) { 
              // 
               const dataobj={
                "firstname": values.firstname,
                "lastname":  values.lastname,
                "phone": values.phone,
                "username": values.username,
                "email":  values.email,
                "why":  values.why,
                "password": values.password,
                "confirmPassword":  values.confirmPassword,
                "referedby": values.referedby,
                "subscription": subscription(),
                "servicetype":serviceType || "",
                "location":values.location
              }

              let data =JSON.stringify(dataobj, null, 2);
                setProccesing(true)
     
            
                try {
                  const reg_response = await axios.post(baseUrl + "/security/register/init", data, { headers: { 'Content-Type': 'application/json' }});
                  if (reg_response.data) {
                      let reg_responsedata = reg_response.data;
              
                      if (reg_responsedata.success) {
                    
                          localStorage.setItem('initRegResponse',JSON.stringify(reg_responsedata.data))
                          localStorage.setItem('initRege0X', data)
                          
                        await initializePayment(reg_responsedata.data.email, reg_responsedata.data.amount,reg_responsedata.data.duration,selectedPackage);
                      } else {
                          console.log(reg_responsedata.error);
                          alert(reg_responsedata.error);
                          setProccesing(false);
                      }
                  }
                } catch (error) {
            
                let errorMessage = 'An error occurred during registration. Please try again later.';
    
                    // Check if the error is from Axios (network or server error)
                    if (error.response) {
                        // The request was made and the server responded with a status code
                        // that falls out of the range of 2xx
                        if (error.response.data && error.response.data.message) {
                            errorMessage = error.response.data.message;
                        } else {
                            errorMessage = 'Server error. Please try again later.';
                        }
                    } else if (error.request) {
                        // The request was made but no response was received
                        errorMessage = 'Network error. Please check your internet connection and try again.';
                    } else {
                        // Something happened in setting up the request that triggered an error
                        errorMessage = 'An unexpected error occurred or Network error. Please check your internet and try again.';
                    }
                    setProccesing(false);
                    alert(errorMessage);
                 
              }
              
           
              setSubmitting(false);
            } else {
              setProccesing(false)
              alert('You must accept the terms and conditions to register.');
            }
          
          }}
        >
         
          {({ errors, touched }) => (
             <Form>
                
              
                <div className="form-control">
                <label className="label"><span className="label-text">First Name</span></label>
                <Field name="firstname" type="text"  className="input input-bordered" />
                <label className="label font-bold text-primary">  {errors.firstname && touched.firstname ? (<div>{errors.firstname}</div>) : null}</label>
                </div> 

               <div className="form-control">
                <label className="label"> <span className="label-text">Last Name</span> </label>
                <Field name="lastname" type="text"  className="input input-bordered" />
                <label className="label  font-bold text-primary">{errors.lastname && touched.lastname ? (  <div>{errors.lastname}</div> ) : null}
                </label></div> 
               
                <div className="form-control">
                <label className="label"> <span className="label-text">Phone</span> </label>
                <Field name="phone" type="text"  className="input input-bordered" />
                <label className="label font-bold text-primary"> {errors.phone && touched.phone ? ( <div>{errors.phone}</div> ) : null}
                </label></div> 

                <div className="form-control">
                <label className="label"> <span className="label-text">Username</span> </label>
                <Field name="username" type="text"  className="input input-bordered" />
                <label className="label font-bold text-primary"> {errors.username && touched.username ? ( <div>{errors.username}</div> ) : null}
                </label></div> 

               <div className="form-control">
                <label className="label"> <span className="label-text">Email</span></label>
                <Field name="email" type="email"  className="input input-bordered" /> 
                <label className="label font-bold text-primary"> {errors.email && touched.email ? ( <div>{errors.email}</div> ) : null}
                </label></div> 

                <div className="form-control">
                <label className="label"> <span className="label-text">Password</span> </label>
                <Field name="password"  type={showPassword ? "text" : "password"}   className="input input-bordered" />
                {/* <button onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button> */}
                <label className="label font-bold text-primary">{errors.password && touched.password ? ( <div>{errors.password}</div> ) : null}
                </label></div> 
                <div className="form-control">
                <label className="label"> <span className="label-text">Confirm password </span> </label>
                <Field name="confirmPassword"  type={showPassword ? "text" : "password"}   className="input input-bordered" />
                {/* <button onClick={togglePasswordVisibility}>
                  <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                </button> */}
                <label className="label font-bold text-primary">{errors.confirmPassword && touched.confirmPassword ? ( <div>{errors.confirmPassword}</div> ) : null}
                </label></div> 

                <div className="form-control">
                <label className="label"> <span className="label-text">Service location</span> </label>
     
                <Field name="location" as="select" className= "w-full m-4">        
                {({ field }) => (
                  <select {...field} className="select select-primary w-full max-w-xs mb-4 mt-4">

                    <option  value="" defaultValue>Make a choice</option>
                    <option value="lagos"> <span >Lagos</span></option>
                   
                  </select>
                )}
              
              </Field>
                <label className="label font-bold text-primary"> {errors.referedby && touched.referedby ? ( <div>{errors.referedby}</div> ) : null}
                </label></div> 

                <div className="form-control">
                <label className="label"> <span className="label-text">Why do you want to use hollo </span> </label>
                <Field name="why" type="text"  className="input input-bordered" />
                <label className="label font-bold text-primary">{errors.why && touched.why ? ( <div>{errors.why}</div> ) : null}
                </label></div> 

             
                <div className="form-control">
                <label className="label"> <span className="label-text">Refered By</span> </label>
                <Field name="referedby" type="text"  className="input input-bordered" />
                <label className="label font-bold text-primary"> {errors.referedby && touched.referedby ? ( <div>{errors.referedby}</div> ) : null}
                </label></div> 

               
                <div>
        <label className='label font-bold text-primary' htmlFor="termsAccepted">
                <input
                  type="checkbox"
                  id="termsAccepted"
                  className='text-primary'
                  checked={termsAccepted}
                  onChange={handleTermsAcceptedToggle}
                />
               <span > I accept the terms and conditions</span>
              </label>
              <Link  to="/login">  <span className='label text-primary'> Already have an account? Login</span></Link>
            </div>
          
 {/* onClick={() => payWithPaystack(user_id, email, amount, package_id, buying_duration, kyexy)} */}
             

                <div className="form-control mt-6">
                {proccesing ? (
                    <button className="btn loading">Authenticating</button>
                            ) : (
                  <button  type="submit"
                  className="btn btn-outline btn-primary">Register</button>
                   
                  )}

                </div>
             </Form>
           )}
        </Formik>
     
      

        </div>
      </div>
      </div>
     </div>
     
   


    </div>
     
    
    );}  

    export default  RegisterForm;