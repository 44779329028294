
import { combineReducers } from 'redux'  
import client from './ClientReducer'
import register from './RegisterReducer'
import login from './LoginReducer'
import channel from './ChannelReducer'
import post from './PostReducer'

//create reducers and add it up here
const IndexReducer = combineReducers({  
 client,
 register,
 login,
 post,
 channel,
})

export default IndexReducer  