import {CREATE_CHANNEL_REQUESTING,CREATE_CHANNEL_ERROR
  ,CREATE_CHANNEL_SUCCESS, CHANNEL_CONNECT_REQUESTING,
  CHANNEL_CONECT_ERROR,
  CHANNEL_CONECT_SUCCESS
  ,FETCH_CHANNELS,FETCH_CHANNELS_ERROR,FETCH_CHANNELS_SUCCESS } from '../constants'


const initialState = {  
  requesting: false,
  successful: false,
  authorization_url: "",
  errors: [],
  isDirty:false,
}
const reducer = function clientReducer (state = initialState, action) {  
  switch (action.type) {

    case CHANNEL_CONNECT_REQUESTING:
      return {
        requesting: true,
        successful: false,
        messages: [{ body: 'Signing up...', time: new Date() }],
        errors: [],
        isDirty:false,
      }

    case CHANNEL_CONECT_ERROR:
      return {
         errors:  action.error.toString(),
          authorization_url: [],
          requesting: false,
          successful: false,
          isDirty:true,
      }

      case CHANNEL_CONECT_SUCCESS:
        return {
          errors: [],
          authorization_url:action.response,
          requesting: false,
          successful: true,
          isDirty:false,
        }
        case CREATE_CHANNEL_REQUESTING:
          return {
            requesting: true,
            successful: false,
            messages: [{ body: 'Signing up...', time: new Date() }],
            errors: [],
            isDirty:false,
          }
    
        case CREATE_CHANNEL_ERROR:
          return {
             errors: state.errors.concat([{
           
              }]),
            
              requesting: false,
              successful: false,
              isDirty:true,
          }
    
          case CREATE_CHANNEL_SUCCESS:
            return {
              errors: [],
              channel:action.response,
              requesting: false,
              successful: true,
              isDirty:false,
            }

            case FETCH_CHANNELS_SUCCESS:
              return {
                errors: [],
                channel:action.response,
                requesting: false,
                successful: true,
                isDirty:false,
              }
              case FETCH_CHANNELS_ERROR:
                return {
                  errors:  action.error.toString(),
                  channel:[],
                  requesting: false,
                  successful: false,
                  isDirty:true,
                }  
    default:
      return state
  }
}

export default reducer  