import React, { useState, useEffect } from 'react';
import Layout from './LayoutPage';
import {checkAccessAuthorization} from '../lib/check-auth'
import { useDispatch } from 'react-redux';
import FooterNavigationContainer from '../containers/FooterNavigationContainer';

import PostForm from '../forms/PostForm';


import { baseUrl, holloHeader,FetchPost, logPageVisit, getCurrentDate } from '../lib/util';


//  {
//   id: number;
//   author: PostAuthorType;
//   date: string;
//   content: string;
//   parentId: number | null;
//   children?: CommentType[];
//   like: {
//     count: number;
//     isLiked: boolean;
//   };
// }
// const renderCommentItemChild = (comment) => {
//   return (
//     <li key={comment.id}>
//       <CommentCard size="normal" comment={comment} />
//       {comment.children && (
//         <ul classNameName="pl-4 mt-5 space-y-5 md:pl-9">
//           {comment.children.map(renderCommentItemChild)}
//         </ul>
//       )}
//     </li>
//   );
// };

// const renderCommentItem = (comment) => {
//   return (
//     <li key={comment.id}>
//       <CommentCard comment={comment} />
//       {comment.children && (
//         <ul classNameName="pl-4 mt-5 space-y-5 md:pl-11">
//           {comment.children.map(renderCommentItemChild)}
//         </ul>
//       )}
//     </li>
//   );
// };

// return (
//   <ul classNameName="nc-SingleCommentLists space-y-5">
//     {cmtLv1.map(renderCommentItem)}
//     <ButtonPrimary classNameName="dark:bg-primary-700 w-full">
//       View full comments (+117 comments)
//     </ButtonPrimary>
//   </ul>
// );



  
const  FeedPage = ()=> {
    //enforce access athorization 
    logPageVisit()
    checkAccessAuthorization(useDispatch) 
  
  

    const [analytic, setAnalytic] = useState([localStorage.getItem('0x1')]);
    const   currentDateString=getCurrentDate()
  
useEffect(() => {
  const fetchStatus = async () => {
    try {

      const token = JSON.parse(localStorage.getItem('hxxrl'));
      if (!token || !token.access_token) {
        throw new Error('No access token found');}  
      const axios = require('axios').default;
      const url = `${baseUrl}/analytic/user/stat/${token.user_id}?datetimestr=${currentDateString}`;
     
      const response = await axios.get(url,holloHeader());
    
      if (response.data && response.data.success) {
        setAnalytic(response.data.data);
        localStorage.setItem('0x1',response.data.data)
      } else {
        throw new Error(response.data.error.message);
      }
       } catch (error) {
      console.error(error);
      alert(error.message);
    }
  };
  fetchStatus();
}, []);
    return(

        <Layout>
         
         <div className="flex flex-col lg:flex-row">


  <div className="lg:basis-1/4"></div>
  <div className="divider lg:divider-horizontal"></div>
  <div className="lg:basis-1/2 sm:w-full">




  

      <div className="stats shadow mb-2 w-full  stats-vertical lg:stats-horizontal">
          
          <div className="stat place-items-center">
            <div className="stat-title font-bold">Daily Post Limit</div>
            <div className="stat-value">{analytic.daily_post_limit}</div>
            <div className="stat-desc"></div>
          </div>
          <div className="stat place-items-center">
            <div className="stat-title font-bold">Todays post count</div>
            <div className="stat-value text-primary">{analytic?analytic.todays_post_count:"0"}</div>
            {/* <div className="stat-desc text-secondary">↗︎ 40 (2%)</div> */}
            <div className="stat-desc font-bold">

              {currentDateString}
            </div>
          </div>
          <div className="stat place-items-center">
            <div className="stat-title font-bold">Daily Post Limit Usage</div>

            <div className="stat-value">{analytic.daily_post_usage_percentage}%</div>
            <div className="stat-desc text-primary font-bold">Daily goal 30% usage </div>
          </div>
          <div className="stat place-items-center">
            <div className="stat-title font-bold">Total post count</div>
            <div className="stat-value text-primary">{analytic.my_total_post_count}</div>
            {/* <div className="stat-desc text-secondary">↗︎ 40 (2%)</div> */}
            <div className="stat-desc"></div>
          </div>
      </div>



      <PostForm />



   

  </div>
  <div className="divider lg:divider-horizontal"></div>
  <div className="lg:basis-1/4"></div>
</div>

       
        <FooterNavigationContainer page="home" />
      </Layout>
        
    );
}
export default FeedPage;