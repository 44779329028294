import React from 'react';

import ReactDOM from 'react-dom/client';
import './output.css';
import App from './App';
import IndexSaga from './sagas/IndexSaga'
import IndexReducer from './reducers/IndexReducer'
import { applyMiddleware, createStore, compose } from 'redux'  
import { Provider } from 'react-redux'  
import createSagaMiddleware from 'redux-saga' 


const sagaMiddleware = createSagaMiddleware()
const composeSetup = process.env.NODE_ENV !== 'production' && typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? 
window.window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
  trace: true,
  traceLimit: 25,
}) :compose 
  

const store = createStore(  
  IndexReducer,
  composeSetup(applyMiddleware(sagaMiddleware)), 
)


// Begin our Index Saga
sagaMiddleware.run(IndexSaga)


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

