import { SIGNUP_REQUESTING,SIGNUP_SUCCESS,SIGNUP_ERROR } from '../constants'

const initialState = {  
    requesting: false,
    successful: false,
    messages: [],
    errors: [],
    isDirty:false,
  }

const reducer = function signupReducer (state = initialState, action) {  
    switch (action.type) {
      case SIGNUP_REQUESTING:
        return {
          requesting: true,
          successful: false,
          messages: [{ body: 'Signing up...', time: new Date() }],
          errors: [],
          isDirty:false,
        }
      case SIGNUP_SUCCESS:
        return {
            errors: [],
            messages: [{
              body: `Successfully created account for ${action.response.email}`,
        
            }],
            requesting: false,
            successful: true,
            isDirty:false,
          }
      //reset the with errors    
    case SIGNUP_ERROR:
    return {
        errors: state.errors.concat([{
        body: action.error.toString(),
        time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
        isDirty:true,
    }   
      default:
        return state
    }
}
export default reducer;