import { POST_ERROR,POST_REQUESTING,POST_SUCCESS } from '../constants'


const initialState = {  
  requesting: false,
  successful: false,
  messages: [],
  errors: [],
  isDirty:false,
}
const reducer = function clientReducer (state = initialState, action) {  
  switch (action.type) {

    case POST_REQUESTING:
      return {
        requesting: true,
        successful: false,
        messages: [{ body: 'Posting..', time: new Date() }],
        errors: [],
        isDirty:false,
      }

    case POST_ERROR:
      return {
         errors: state.errors.concat([{
          body: action.error.toString()
          }]),
          messages: [],
          requesting: false,
          successful: false,
          isDirty:true,
      }

      case POST_SUCCESS:
        return {
          errors: [],
          messages: [{
            body: `Successfully posted ${action.response}`,
           }],
          requesting: false,
          successful: true,
          isDirty:false,
        }
        
    default:
      return state
  }
}

export default reducer  