import React, { useState} from 'react';
import { useDispatch } from 'react-redux';
import { checkAccessAuthorization } from '../lib/check-auth';
import Layout from './LayoutPage';
import FooterNavigationContainer from '../containers/FooterNavigationContainer';
import * as Yup from 'yup';
import { Formik, Form, useField,Field } from 'formik';
import {baseUrl, logPageVisit} from '../lib/util'
const Schema = Yup.object().shape({
  message: Yup.string().required('Required'), 
  type: Yup.string().required('Required'), 
});
const axios = require('axios').default;
const MyTextArea = ({label, ...props}) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
      <>
          <label htmlFor={props.id || props.name}>{label}</label>
          <textarea className="textarea m-2  w-full max-w-sm" {...field} {...props} />
          {meta.touched && meta.error ? (  <div className="error">{meta.error}</div> ) : null}
      </>
  );
};

function FeedbackPage() {
  logPageVisit()
   checkAccessAuthorization(useDispatch) 
  //  const [channels,setChannels]=useState(JSON.parse(localStorage.getItem("channels")))
  //  const [selectedChannelId, setSelectedChannelId] = useState(null);
  //  const [loading, setLoading] = useState(false);
  //  const [user]=useState(JSON.parse(localStorage.getItem("xx1r")))
  const hxxrl = JSON.parse(localStorage.getItem('hxxrl'));
  if (!hxxrl || !hxxrl.access_token) {
    throw new Error('No access token found');
  }
  
  return(
      
        <Layout>
       {/* header banner */}
       <div className="  ">
                    
       <div className="flex justify-center bg-neutral text-neutral-content">
            <div className="bg-neutral text-neutral-content m-4">
              <div className="card-body">
                <h2 className="card-title">Please give us your feedback.</h2>
              </div>
              <div role="alert" className="alert alert-default hadow-lg">
                <div className='flex'>
                  <p className="text-md">Great job trying out Hollo. <br /> Do you want to report a bug, have a complain or improvement suggestion? <br /> Use the feedback form.</p>
                </div>            
              </div>
            </div>
       </div>

        
            <Formik  
              initialValues={{
                message:"",
                type:"complain"              }
            }
              validationSchema={Schema}

              onSubmit={async (values, { setSubmitting }) => {
      
    
             const pubFeedbackBtn = document.getElementById('publish-feedback-btn')
             const token = JSON.parse(localStorage.getItem('hxxrl'));
                const post={
                  "message": values.message,
                  "type": values.type,
                  "user_id": token.user_id,
                }
                
                  try {
                    const response = await axios.post(baseUrl + "/feedback/create",post, {
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.access_token
                      }
                    });
              
                  
                    if (response.data) {
                      let responseData = response.data;
                   
                     
                      if (responseData.success) {
                        pubFeedbackBtn.click();
                    
                      } else {
                        console.log(responseData.error);
                      }
            
                    }
                  } catch (error) {
               
                    console.log(error);
            
                  }
                
                  setSubmitting(false);
              
              }}
            >
              {({ errors, touched }) => (
                
                <div className='flex justify-center'>
           <Form>
       
           <div className="card w-full md:w-1/2 lg:w-96">
            <label className="hidden" id="publish-feedback-btn" htmlFor="published-feedback"></label>

              <Field name="type" as="select">
                {({ field }) => (
                  <select {...field} className="select select-primary w-full max-w-xs mb-4 mt-4">
                    <option disabled defaultValue>Select a feedback type</option>
                    <option value="Bug">Bug</option>
                    <option value="Complain">Complain</option>
                    <option value="Improvement suggestion">Improvement suggestion</option>
                  </select>
                )}
              </Field>

              <div className="form-control">
                <MyTextArea
                  label="Feedback here"
                  className="textarea w-full textarea-grey w-full max-w-x"
                  name="message"
                  rows="2"
                  placeholder="Your feedback"
                />
                <label className="label font-bold text-primary">
                  {errors.message && touched.message ? (<div>{errors.message}</div>) : null}
                </label>
              </div>

              <div className="form-control mt-6">
                <button type="submit" className="btn btn-outline btn-primary mb-4">Submit</button>
              </div>
            </div>

           
           </Form>
               </div>
              )}
            </Formik>

        </div>      
      
           <div className='card w-1/2  p-0 m-0'>
        
    
        <input type="checkbox" id="published-feedback" className="modal-toggle" />
        <div className="modal ">
          
            <div className="card  bg-neutral text-primary-content">
              <div className="navbar bg-primary text-primary-content p-0">
              <button className="btn btn-ghost text-xl">Feedback received</button>
              <label id="modalCloseBtn" htmlFor="published-feedback" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
            </div>
            <div className="card-body ">
{/*           
              <figure className='item bg-primary w-20 h-20 rounded-full mt-2 ml-10'>      <h1 className='text-white text-5xl font-bold'>Ok</h1></figure> */}
              <span className='font-bold'>
                {/* when you update the type display different message based on the type exam you bug report has been received your complain is being worked on etc */}
                Congratulations your feedback has been received. we have cued it for action.
              </span>
             
           
           
            </div>

          </div>
        </div>
        
          </div>
        <FooterNavigationContainer page="tutorial" />
       </Layout>
   
  
    );
}
export default FeedbackPage;