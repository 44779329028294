import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
import Layout from '../../LayoutPage';

const CodeOfConduct = () => {
  return (
    <Layout>
    <div className="max-w-3xl mx-auto my-8">
      <h1 className="text-3xl font-bold mb-4">Hollo - Code of Conduct</h1>
      <p className="mb-6">Effective Date: 26th December 2023</p>

      <p className="mb-4">
        Welcome to Hollo! Our community is founded on principles of kindness, respect, and collaboration. To ensure a
        positive and inclusive experience for all users, we have established this Code of Conduct. By using the Hollo
        Social Media Management Tool, you agree to abide by these principles.
      </p>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">1. Respectful Interaction</h2>
        <p>
          a. <strong>Inclusive Community:</strong> Embrace diversity and treat everyone with respect. Our community
          values individuals from all backgrounds and perspectives.
        </p>
        <p>
          b. <strong>Civility and Kindness:</strong> Engage in constructive and kind communication. Disagreements are
          natural; express your opinions respectfully.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">2. User Responsibility</h2>
        <p>
          a. <strong>Account Integrity:</strong> Users are responsible for the security of their accounts. Protect your
          login credentials and report any suspicious activity promptly.
        </p>
        <p>
          b. <strong>Ethical Behavior:</strong> Adhere to ethical standards and comply with all applicable laws and
          regulations when using the Hollo tool.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">3. Ethical Use of Data</h2>
        <p>
          a. <strong>Respect Privacy:</strong> Respect the privacy of users and their data. Use data responsibly and
          in accordance with the data policy outlined by Hollo.
        </p>
        <p>
          b. <strong>No Unauthorized Access:</strong> Do not attempt to access, modify, or interfere with other
          users' accounts or data without explicit permission.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">4. Positive Contribution</h2>
        <p>
          a. <strong>Constructive Contributions:</strong> Contribute to discussions and user-generated content in a
          constructive and positive manner. Encourage a supportive community.
        </p>
        <p>
          b. <strong>Avoid Spam:</strong> Refrain from engaging in spamming activities, including excessive
          self-promotion or sending unsolicited messages.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">5. Reporting Violations</h2>
        <p>
          a. <strong>Reporting Mechanism:</strong> Report any violations of the code of conduct promptly through the
          designated channels provided by Hollo.
        </p>
        <p>
          b. <strong>Non-Retaliation:</strong> Reports will be handled confidentially, and there will be no retaliation
          against those reporting in good faith.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">6. Consequences of Violations</h2>
        <p>
          a. <strong>Enforcement:</strong> Hollo reserves the right to take appropriate action, including account
          suspension or termination, for violations of the code of conduct.
        </p>
        <p>
          b. <strong>Appeals:</strong> Users have the right to appeal decisions made by Hollo regarding code of conduct
          violations. Appeals will be considered based on the merits of the case.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">7. Continuous Improvement</h2>
        <p>
          a. <strong>Feedback:</strong> Hollo encourages feedback and suggestions to enhance the user experience. Share
          your thoughts through designated channels.
        </p>
        <p>
          b. <strong>Updates to Code of Conduct:</strong> This code of conduct may be updated periodically. Users will
          be notified of significant changes.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-xl font-semibold mb-4">8. Contact Us</h2>
        <p>
          For any questions or concerns regarding the Code of Conduct, please{' '}
          <Link to="/contact" className="text-blue-500">
            contact us
          </Link>{' '}
          at [your contact information].
        </p>
      </section>
    </div>
    </Layout>
  );
};

export default CodeOfConduct;
