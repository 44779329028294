import { call, put, takeLatest } from 'redux-saga/effects'  

import {  
  SIGNUP_REQUESTING,
  SIGNUP_SUCCESS,
  SIGNUP_ERROR,
} from '../constants'
import { baseUrl } from '../lib/util';


function signupApi (payload) {
    return fetch(baseUrl+"/security/register", {
        method: 'POST',
        headers: {  'Content-Type': 'application/json',
        }, body: payload,
      }) .then(response => response.json()).then(json => json).catch((error) => { throw error })
}


function* signupFlow (action) {  
  try {
  //call the api 
 
   const response = yield call(signupApi,action.payload)


  yield put({ type: SIGNUP_SUCCESS, response })

  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
   yield put({ type: SIGNUP_ERROR, error })
  }
}






function* signupWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
 
  yield takeLatest(SIGNUP_REQUESTING, signupFlow)
}


export default signupWatcher