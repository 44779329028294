import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you are using React Router
import Layout from '../../LayoutPage';
const DataPolicy = () => {
  return ( <Layout>
    <div className="max-w-3xl mx-auto my-8">
      <h1 className="text-3xl font-bold mb-4">Hollo - Data Policy</h1>
      <p className="mb-6">Last Updated: 26th December 2023</p>

      <p className="mb-4">
        <strong>1. Introduction</strong>
      </p>
      <p>
        Welcome to Hollo! This Data Policy is designed to inform you about the collection, use, and protection of your
        data when using the Hollo Social Media Management Tool. By using our services, you agree to the terms outlined in
        this policy.
      </p>

      <p className="mb-4">
        <strong>2. Information We Collect</strong>
      </p>
      <p>
        a. <strong>Account Information:</strong> When you register for an account, we collect your name, email address,
        and other necessary information for account creation.
      </p>
      <p>
        b. <strong>Connected Social Media Accounts:</strong> To enable social media management, we collect and store
        information from the social media accounts you connect, such as posts, profile information, and engagement
        metrics.
      </p>
      <p>
        c. <strong>Usage Data:</strong> We collect information about how you interact with the Hollo tool, including
        log data, device information, and actions taken within the platform.
      </p>
      <p>
        d. <strong>Payment Information:</strong> If you make purchases, we collect payment information to process
        transactions securely.
      </p>

      <p className="mb-4">
        <strong>3. How We Use Your Information</strong>
      </p>
      <p>
        a. <strong>Providing and Improving Services:</strong> We use your data to deliver and enhance the functionality
        of the Hollo tool, including personalized features and content.
      </p>
      <p>
        b. <strong>Communications:</strong> We may send you important notifications, updates, and promotional messages
        related to our services.
      </p>
      <p>
        c. <strong>Analytics and Research:</strong> Aggregated and anonymized data may be used for analytics, research,
        and improving our services.
      </p>
      <p>
        d. <strong>Customer Support:</strong> Your information helps us respond to your inquiries, troubleshoot issues,
        and provide efficient customer support.
      </p>

      <p className="mb-4">
        <strong>4. Data Sharing and Third Parties</strong>
      </p>
      <p>
        a. <strong>Connected Social Media Platforms:</strong> Hollo accesses and shares data with connected social media
        platforms only as necessary for the tool's functionality. Users are responsible for compliance with social media
        platform terms.
      </p>
      <p>
        b. <strong>Service Providers:</strong> We may engage third-party service providers for various purposes, such as
        data storage, payment processing, and analytics.
      </p>
      <p>
        c. <strong>Legal Compliance:</strong> Hollo may disclose information if required by law or to protect our
        rights, users, or others.
      </p>

      <p className="mb-4">
        <strong>5. Data Security</strong>
      </p>
      <p>
        a. <strong>Security Measures:</strong> We implement industry-standard security measures to protect your data
        from unauthorized access, alteration, or disclosure.
      </p>
      <p>
        b. <strong>Data Retention:</strong> We retain your data only for as long as necessary to fulfill the purposes
        outlined in this policy or as required by law.
      </p>

      <p className="mb-4">
        <strong>6. Your Choices and Controls</strong>
      </p>
      <p>
        a. <strong>Account Settings:</strong> Users can manage account settings, privacy preferences, and connected
        social media accounts within the Hollo platform.
      </p>
      <p>
        b. <strong>Opting Out:</strong> You can opt out of promotional communications and certain data collection by
        adjusting your preferences.
      </p>

      <p className="mb-4">
        <strong>7. Children's Privacy</strong>
      </p>
      <p>
        Hollo is not intended for children under the age of 13. We do not knowingly collect personal information from
        children. If you are a parent or guardian and believe your child has provided us with information, please{' '}
        <Link to="/contact" className="text-blue-500">
          contact us
        </Link>.
      </p>

      <p className="mb-4">
        <strong>8. Updates to this Policy</strong>
      </p>
      <p>
        We may update this Data Policy periodically. Users will be notified of significant changes. By continuing to
        use Hollo after such changes, you agree to the updated policy.
      </p>

      <p className="mb-4">
        <strong>9. Contact Us</strong>
      </p>
      <p>
        For any questions or concerns regarding this Data Policy, please{' '}
        <Link to="/contact" className="text-blue-500">
          contact us
        </Link>{' '}
        at hollo.chukolo.com
      </p>
    </div>
     </Layout>
  );
};

export default DataPolicy;

