import React,{useState,useEffect} from 'react';

import * as Yup from 'yup';
import { Formik, Form, useField,Field } from 'formik';
import {baseUrl,addMetaInfo, FetchPost,toggleHashtagState, UpdateDeviceHashtags} from '../lib/util'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import PostContainer from '../containers/PostContainer'
import ConnectChannelComponent from '../components/ConnectChannelComponent';
// import { useMediaRecorder } from 'react-media-recorder';


import dayjs from 'dayjs'; 
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

// Load the plugins
dayjs.extend(utc);
dayjs.extend(timezone);

const Schema = Yup.object().shape({
    message: Yup.string().required('Required'), 
  });


  
  //this is formating the channel to the posting
  const prepareChannel = (my_channels) => {
 
    return my_channels
      .filter(channel => channel.isSelected)
      .map(channel => ({ 
        _id: channel._id,
        channel_title:channel.user_name,
        channel_icon: channel.channel_tag+".png",
       
       }));
    }


   
    function prepareSchedule(datetimeList) {
    
      const datetimeObjects = datetimeList.map(datetimeString => {
       
        return { datetime: datetimeString };
      });
    
      return datetimeObjects
    }
    
    
  

  
export default function PostForm() {


  const [postingErrors,setPostingErrors]=useState([])
  const [postingSuccess,setPostingSuccess]=useState([])

  // make sure there is at least one channel to post  i can give you your own phone and plug the power bank
  const [schedules,setSchedules]=useState([])
  const [schedule,setSchedule]=useState()
  const [channels,setChannels]=useState(JSON.parse(localStorage.getItem("channels")))
  const [hashtags, setHashtags] =  useState(JSON.parse(localStorage.getItem('hashtags')));
  const [broadcasting,setBroadcasting]=useState(false) 
  const [isChannelConfirmed,setIsChannelConfirmed]=useState(0)
  const [isChannelEmptyError,setIsChannelEmptyError]=useState(0)
  const [progress, setProgress] = useState(0);

  let user_id =JSON.parse(localStorage.getItem('hxxrl')).user_id
  const axios = require('axios').default;

  const [attachments, setAttachments] = useState([]);
  const [attachmentsPreview, setAttachmentsPreview] = useState([]);





  const testpost= "" 
// posts list 

const [posts, setPosts] = useState([]);

const page_size=5

const [loading, setLoading] = useState(true);
const [isNearBottom, setIsNearBottom] = useState(false);

const [currentPage, setCurrentPage] = useState(1); 


const attachmentHandler = (e) => {
  const files = Array.from(e.target.files);
  const formData = new FormData();

  const isImage = (file) => file.type.startsWith('image/');
  const isVideo = (file) => file.type.startsWith('video/');
  const isAudio = (file) => file.type.startsWith('audio/');

  const resizeAndAppendImage = (imageFile) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const image = new Image();
        image.src = event.target.result;

        image.onload = () => {
          const canvas = document.createElement("canvas");
          const maxDimension = 800; // Set your desired max dimension here

          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxDimension) {
              height *= maxDimension / width;
              width = maxDimension;
            }
          } else {
            if (height > maxDimension) {
              width *= maxDimension / height;
              height = maxDimension;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, width, height);

          // Convert the image to JPEG format with a specified quality (adjust as needed)
          canvas.toBlob((blob) => {
            const resizedFile = new File([blob], imageFile.name, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });

            formData.append("attachments", resizedFile, resizedFile.name);
            resolve();
          }, "image/jpeg", 1);
        };
      };

      reader.readAsDataURL(imageFile);
    });
  };

  const appendVideo = (videoFile) => {
    // Append video file as is without compression
    formData.append("attachments", videoFile, videoFile.name);
  };

  const appendAudio = (audioFile) => {
    // Append audio file as is without compression
    formData.append("attachments", audioFile, audioFile.name);
  };

  const promises = files.map((file) => {
    if (isImage(file)) {
      return resizeAndAppendImage(file);
    } else if (isVideo(file)) {
      appendVideo(file);
      return Promise.resolve();
    } else if (isAudio(file)) {
      appendAudio(file);
      return Promise.resolve();
    } else {
      // Handle other file types as needed
      return Promise.resolve();
    }
  });

  Promise.all(promises).then(() => {
    // This block executes after all files have been processed and appended to the formData
    setAttachments([...attachments, formData]); // Move this line here
  });

  const newAttachments = [];
  files.forEach((file) => {
    const objectUrl = URL.createObjectURL(file);
    newAttachments.push({ file, objectUrl });
  });
  setAttachmentsPreview((prevAttachments) => [...prevAttachments, ...newAttachments]);
};




const addSchedule = () => {
  // Ensure the schedule date is not null or an empty string
  if (schedule !== null && schedule !== "") {
    const formattedDate = dayjs(schedule).format('YYYY-MM-DD HH:mm:ss');
    setSchedules([...schedules, formattedDate]);

  }

};

const removeSchedule = (index) => {
  // Splicing array
  const updatedSchedules = [...schedules.slice(0, index), ...schedules.slice(index + 1)];
  setSchedules(updatedSchedules);
};

 const handleHolloPostSubmit = ()=>{
  const holloBtn = document.getElementById('hollo-btn');
  const cntrlBtn = document.getElementById('modalCloseBtn')
  // automatically add the select datetime check the effect of this for instant post 


  if (schedule !== null &&  schedule !== "" && schedule !=undefined) {
    const formattedDate = dayjs(schedule).format('YYYY-MM-DD HH:mm:ss');
    setSchedules([...schedules, formattedDate]);

  }



  if (holloBtn) {
    holloBtn.click();
  }
  if(cntrlBtn){
    cntrlBtn.click()
  }
 }

  const confirmChannelSelection = () => {
    const workingChannel=prepareChannel(channels)

    if(workingChannel.length>0){
    
      setIsChannelConfirmed(true)
    }else{
  
      setIsChannelEmptyError(true)
    }
   
  }
  
const toggleChannel = (channel) => {
  const updatedChannel = {...channel, isSelected: !channel.isSelected};
  //update channel in the state 
  setChannels(channels => channels.map(c => c._id === channel._id ? updatedChannel : c));
}


  const getPostType=()=>{
    //check if media or not return appropriate type
    if(typeof attachments !== 'undefined' && attachments.length){
      return  "media"
    }else{
      return "text"
    }
   
  }
  const preparedChannels = prepareChannel(channels);
  React.useEffect(() => {
    // Function to get the user's timezone offset
    const getUserTimezone = () => {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    };
    // Set the user's timezone for Dayjs
    dayjs.tz.setDefault(getUserTimezone());
    }, []);

    const renderAttachments = () => {
      const handleRemoveAttachment = (index) => {
        const updatedAttachments = attachmentsPreview.filter((_, i) => i !== index);
        setAttachmentsPreview(updatedAttachments);
      };
    
      return (
        <div className="p-2" style={{p:'2px' ,display: 'flex', flexWrap: 'wrap' }}>
        

          {attachmentsPreview.map((attachment, index) => (
            <div className="indicator mask"  key={index} style={{ marginRight: '10px', marginBottom: '10px' }}>
              {attachment.file.type.startsWith('image/') && (
                  <img src={attachment.objectUrl} alt="Attachment" style={{ maxWidth: '100px', maxHeight: '100px' }} />
               
              )}
              {attachment.file.type.startsWith('video/') && (
                <video controls style={{ maxWidth: '100px', maxHeight: '100px' }}>
                  <source src={attachment.objectUrl} type={attachment.file.type} />
                </video>
              )}
              {attachment.file.type.startsWith('audio/') && (
                <audio controls style={{ maxWidth: '100px' }}>
                  <source src={attachment.objectUrl} type={attachment.file.type} />
                </audio>
              )}
                <span  onClick={() => handleRemoveAttachment(index)} className="indicator-item badge indicator-bottom  indicator-center badge-primary">Remove</span> 
            </div>
          ))}
        </div>
      );
    };
    
    const MyTextArea = ({label, ...props}) => {
      // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
      // which we can spread on <input> and alse replace ErrorMessage entirely.
      const [field, meta] = useField(props);
      return (
        <>
        <label htmlFor={props.id || props.name}>{label}</label>
        <textarea className="textarea m-2 w-full max-w-sm" {...field} {...props} />
        {renderAttachments()}
        {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      </>
      );
    };
    const updatePostsAndLocalStorage = (responseData) => {
      // Append the newly created post to the existing posts in state
      setPosts((prevPosts) => [responseData.data, ...prevPosts]);
      
      // Update the cachedPosts and save to local storage
      const storedPosts = JSON.parse(localStorage.getItem('cachedPosts')) || [];
      localStorage.setItem('cachedPosts', JSON.stringify([responseData.data, ...storedPosts]));
    
      // Calculate the accurate number of pages based on the updated posts
      const page_size = 10; // Example page size, replace with your actual value
      const totalPages = Math.ceil((storedPosts.length + 1) / page_size);
      
      // Update the cached page count
      localStorage.setItem('last_feed_page', totalPages);
    };
    
    async function handleHashtagToggle(id) {
      setLoading(true);
      await toggleHashtagState(id, 'toggle');
      await UpdateDeviceHashtags() 
    
      setLoading(false);
 
    }
    const prepareMessage =(message)=>{
     
        const activeHashtags = hashtags.filter(hashtag => !hashtag.is_offline);

        // Map the filtered hashtags to extract the tag values and join them into a single string
        const hashtagsString = activeHashtags.map(hashtag => `#${hashtag.tag}`).join(' ');

        // Append the hashtags to the message with a newline before them
        const preparedMessage = `${message}\n${hashtagsString}`;

        return preparedMessage
    }
// displaying posts 


  
useEffect(() => {
  const handleScroll = () => {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    // Check if the user is near the bottom (adjust 200 based on your preference)
    setIsNearBottom(scrollY + windowHeight >= documentHeight - 100);
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);

const fetchAndUpdatePosts = async () => {
  try {
 
    setLoading(true);

    const storedPosts = JSON.parse(localStorage.getItem('cachedPosts')) || [];

    // Fetch the latest posts
    const latestPosts = await FetchPost("True", 1, page_size);

    // Identify new posts by comparing their IDs
    const newPosts = latestPosts.filter(post => !storedPosts.some(existingPost => existingPost._id === post._id));

    // Append the new posts to the displayed posts
    setPosts((prevPosts) => [...prevPosts, ...newPosts]);

    // Update the cachedPosts and save to local storage
    localStorage.setItem('cachedPosts', JSON.stringify([...storedPosts, ...latestPosts]));

    // Calculate the accurate number of pages based on the volume of stored posts
    const totalPages = Math.ceil((storedPosts.length + newPosts.length) / page_size);

    // Update the last_feed_page in local storage
    localStorage.setItem('last_feed_page', totalPages);
  } catch (error) {
    console.log(error);
    alert(error.message);
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  const storedPosts = JSON.parse(localStorage.getItem('cachedPosts')) || [];

  if (storedPosts.length > 0) {
    setPosts(storedPosts);

    // Fetch and update posts
    fetchAndUpdatePosts();
  } else {
    // Fetch initial posts
    const initialPosts = async () => {
      try {
        setLoading(true);
        const fetchedPosts = await FetchPost("True", currentPage, page_size);
        setPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);
        localStorage.setItem('last_feed_page', currentPage);
      } catch (error) {
        console.log(error);
        alert(error.message);
      } finally {
        setLoading(false);
      }
    };
    initialPosts();
  }
}, [currentPage]);

useEffect(() => {
  const loadMorePosts = async () => {
    if (isNearBottom) {
      try {
        setLoading(true);
        const lastFeedPage = localStorage.getItem('last_feed_page');
        const fetchedPosts = await FetchPost("True", parseInt(lastFeedPage, 10) + 1, page_size);
        const storedPosts = JSON.parse(localStorage.getItem('cachedPosts')) || [];
        setPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);
        localStorage.setItem('cachedPosts', JSON.stringify([...storedPosts, ...fetchedPosts]));
        localStorage.setItem('last_feed_page', parseInt(lastFeedPage, 10) + 1);
      } catch (error) {
        console.log(error);
        alert(error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  loadMorePosts();
}, [isNearBottom]); // Add isNearBottom as a dependency



  return(
    // <div class="flex ">
    
    <>
     
         
          <div className="glass bg-dark dark:bg-neutral-900 dark:border dark:border-neutral-700 rounded-[30px] sm:w-full md:w-[80%] lg:w-full xl:w-full mx-auto">
             <Formik 
              initialValues={{message:testpost }}
              validationSchema={Schema}

              onSubmit={async (values, { setSubmitting ,resetForm }) => {
              
                setBroadcasting(true);
                setPostingErrors([])
                const post={
                  "message": prepareMessage(values.message),
                  "tags": [],
                  "meta":"",
                  "channels":prepareChannel(channels),
                  "schedules": prepareSchedule(schedules),
                  "publish_instantly": schedules.length === 0,
                  "post_type":getPostType(),
                  "user_id": user_id,
                  "meta":JSON.stringify(addMetaInfo()),
                  "attachments":[]
                }
                //

                // alert(post)
                const pubFeedbackBtn = document.getElementById('publish-feedback-btn')
                 const token = JSON.parse(localStorage.getItem('hxxrl'));

                    if(typeof attachments !== 'undefined' && attachments.length) {
                  setLoading(true)
                      const response = await axios.post(baseUrl + "/media/upload", attachments[0], {headers: {'Content-Type': 'multipart/form-data', 'Authorization':'Bearer ' + token.access_token},
                      onUploadProgress: (progressEvent) => {
                        //const uploadPercentage = ;
                      
                        setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                      }});
                      
                      if (response.data) {
                        let responseData = response.data;
                
                        if (responseData.success) {
                           post["attachments"]=responseData.data
                        } else{
                          setPostingErrors([])
                          setPostingSuccess("")
                          setPostingErrors(prevErrors => [...prevErrors, responseData.error]);
                        }
                  
                      }
                      setLoading(false)
                    }

                  try {
                    const response = await axios.post(baseUrl + "/posts/create",post, {
                      headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token.access_token
                      }
                    });
              
                    if (response.data) {
                      let responseData = response.data;
                      // updatePostsAndLocalStorage(responseData)
                      if (responseData.success) {
                         
                             setPostingSuccess("Post dispatched check notification for updates ")
                             //call the function here.
                             //update the cached post to reflect the post that was just made 
                             localStorage.removeItem('cachedPosts')
                             //  window.location.href = "/home";               
                           

                              // Fetch and update posts
                           
                                try {
                                  setLoading(true);
                                  const fetchedPosts = await FetchPost("True", 1, page_size);
                               
                                  setPosts(fetchedPosts);
                                  localStorage.setItem('cachedPosts', JSON.stringify(fetchedPosts));
                                  localStorage.setItem('last_feed_page', 1);

                                   // Clear form fields
                               resetForm();
                                } catch (error) {
                                  console.log(error);
                                  alert(error.message);
                                } finally {
                                  setLoading(false);
                                }
                            
                             
              
                      } else {
                        console.log(responseData.error);
                        setPostingErrors([])
                        setPostingSuccess("")
                        setPostingErrors(responseData.error);
                     
                      }
                      if (pubFeedbackBtn) {
                     //   pubFeedbackBtn.click();
                      }
                      setBroadcasting(false);
                    }
                  } catch (error) {
               
                    console.log(error);
                    // handle the error
                    setPostingErrors([])
                    setPostingSuccess("")
                    setPostingErrors(prevErrors => [...prevErrors, error['message']]);
                    if (pubFeedbackBtn) {
                      //pubFeedbackBtn.click();
                    }
                    setBroadcasting(false);
                  }
                
                  setSubmitting(false);
              
              }}
            >
              {({ errors, touched }) => (
                
                <Form>
              
                <div className="form-control">

              
                  {/* do if target */}
                  <div className="chat chat-end">
                  <div className="chat-image avatar pr-2">
                      <div className="w-5 rounded-full">
                        <img src="white-icon.png" alt="User Avatar" />
                      </div>
                  </div>
                  <div style={{ minHeight: 0 }} className="chat-bubble chat-bubble-primary breath-animation pt-0 pb-0 mh-0">
                    Build a strong brand with hollo by posting consistently
                  </div>
             
              </div>
               {postingErrors.length?(
                <div className="chat chat-start mt-1 mb-1">
                <div className="chat-image avatar pr-2">
                    <div className="w-5 rounded-full">
                      <img src="white-icon.png" alt="User Avatar" />
                    </div>
                </div>
                {postingErrors.map(errorp => (
                       <div style={{ minHeight: 0 }} className="chat-bubble chat-bubble-error m-1 pt-0 pb-0 mh-0">
                         {errorp}
                       </div>
                      ))}
               
            </div>
               ):""}
                 
               
             {postingSuccess.length?(
                <div className="chat chat-start">
                <div className="chat-image avatar pr-2">
                    <div className="w-5 rounded-full">
                      <img src="white-icon.png" alt="User Avatar" />
                    </div>
                </div>
                <div style={{ minHeight: 0 }} className="chat-bubble chat-bubble-success breath-animation pt-0 pb-0 mh-0">
                         {postingSuccess}
                       </div>
                </div>
               ):""}
             

                <MyTextArea
                    label=""
                    className="textarea  w-full textarea-grey "
                    name="message"
                    rows="4"
                    placeholder="What's happening ?"
                  />
              {
  loading && broadcasting ? (
    <progress className="progress progress-primary w-full" value={progress} max="90"></progress>
  ) : ""
}          {
  !loading && broadcasting ? (
    <progress className="progress progress-primary w-full"></progress>
  ) : null
}


{hashtags.length > 0 &&

<div>  
{/* <div className="stat-desc font-bold text-primary p-4">Post hashtags</div> */}
                  <div className="card-actions bg-default justify-center pl-2 pr-2">
                    {hashtags.map((hashtag) => (
                      <div key={hashtag._id} className="card-actions justify-start">
                        {loading ? (
                          <button className="btn btn-sm loading m-2"></button>
                        ) : (

                          <div className="btn-group flex">
                           {hashtag.is_offline?"": 
                            <label className="item-center btn-primary btn btn-outline btn-xs item-center" onClick={() => { handleHashtagToggle(hashtag._id) }}  > #{hashtag.tag } | X</label>
                          } 
                          </div>

                        )}
                      </div>
                    ))}
                  </div>
</div>
                
                }
                
                </div>

               
      
              
                  <div className='flex justify-between px-5'>
    
                  <label htmlFor="attachments" className="rounded-md cursor-pointer hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                    <Field name="attachments">
                      {({ field }) => (
                        <>
                          <input
                            type="file"
                            id="attachments"
                            multiple
                            accept="image/*, video/*"
                            className="hidden"
                            onChange={(event) => {
                              field.onChange(event);
                              attachmentHandler(event);
                            }}
                          />
                          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} >
                            <img src="upload media icon.png" alt="Media Uploader" width="39" height="38" style={{ marginRight: '10px' }} />
                            <span></span>
                          </div>
                        </>
                      )}
                    </Field>
         
                  </label>

  



                  {/* <button onClick={handleRecordClick}>
                    {status === 'idle' ? 'Start Recording' : 'Stop Recording'}
                  </button> */}

                  {/* Display the recorded media */}
                  {/* {status === 'stopped' && mediaBlobUrl && (
                    <video src={mediaBlobUrl} controls />
                  )} */}






         <div className="divider divider-horizontal"></div>

         <div className="avatar-group  mt-2 mb-2 w-50 -space-x-6 rtl:space-x-reverse">
      {preparedChannels.map(channel => (
        <div key={channel._id} className="avatar  h-10 ">
          <div className="w-10 h-10">
            <img src={`${channel.channel_icon}`} alt={channel.channel_title} />
          </div>
        </div>
      ))}

      {/* Placeholder for the remaining channels */}
      <div className="avatar h-10 placeholder">
          <div className="w-10 bg-neutral text-neutral-content">
            <span>{preparedChannels.length}</span>
          </div>
        </div>
    </div>

    <label  className="" id="publish-feedback-btn" htmlFor="hollo-published-feedback" ></label>
   

          {/* Buttons Component */}
          <div className='pt-1'>
          <button type="submit" id="hollo-btn" className="btn btn-ghost btn-xs">
                 
          </button>
            {broadcasting ? (
              <button className="btn btn-sm loading m-2"></button>
            ) : (
              channels.length ? (
                <label  className="btn btn-primary btn-sm m-2" id="kkkkk" htmlFor="hollo-phase-2" > 
            Hollo
           </label>
              
              ) : (  
                
                <label  className="btn btn-primary btn-sm m-2 btn-disabled"  > 
              connect account
             </label>)
            )}
          </div>
        </div>



                </Form>
              )}
             </Formik>
          </div>
       
          <div className="flex m-2 justify-center">
        <ConnectChannelComponent />
      </div>

          <div>
          <PostContainer posts={posts} />
            {loading?
              <div className='flex justify-center'>
              <button className="btn btn-ghost loading loading-infinity  btn-lg "></button>
              </div>:""}
        </div>




       {/* modals  */}

          <div className='card w-1/2  p-0 m-0'>
        
    
        <input type="checkbox" id="hollo-phase-2" className="modal-toggle" />
        <div className="modal ">
          
            <div className="card  bg-neutral text-primary-content">
              <div className="navbar bg-primary text-primary-content p-0">
              <button className="btn btn-ghost text-xl">Choose channels to publish post</button>
              <label id="modalCloseBtn" htmlFor="hollo-phase-2" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
            </div>
            <div className="card-body ">
          
            <div id="channelSelectionSection"  style={{ display: isChannelConfirmed ? 'none' : 'block' }}>
            {/* <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text text-primary">Remember my choice don't present this screen again</span> 
                <input type="checkbox" className="toggle toggle-success"  />
              </label>
             </div> */}
         
              {isChannelEmptyError ? (
              <span className='text-primary'>
                None of the channels connected to your Hollo account is online. You're required to have at lease one channel online to post.
              </span>
                ) : null}
                <i>Click any of the channels to toggle between online and offline mode </i>
              <div class="card-actions mt-4 justify-start">
               {/* <ChannelCards channels={channels} /> */}
                {channels?channels.map((channel)=>
                  <label onClick={()=>toggleChannel(channel)} >
                    <div class="btn-group">
                          <button class="btn btn-circle avatar btn-xs btn-circle btn-outline">
                          <img  className = "w-5 rounded-full"  src={channel.icon} /> 
                          </button>
                          <button className={channel.isSelected?"btn btn-xs btn-primary ":"btn btn-xs btn-outline "}>
                          {channel.user_name}
                          </button>
                    </div>
                  </label>
                    ):""}
              </div>
             
             



              <div className="flex w-full pt-4">
            <div className="grid h-20 flex-grow card bg-base-300 rounded-box place-items-center">
            <button  onClick={()=>confirmChannelSelection()}   className="btn btn-outline btn-primary btn-sm "> 
                Publish post later
              </button>
            </div>
            <div className="divider divider-horizontal">OR</div>
            <div className="grid h-20 flex-grow card bg-base-300 rounded-box place-items-center">
            <button  onClick={()=>handleHolloPostSubmit()}   className="btn btn-outline btn-primary btn-sm m-2"> 
                Publish post now
              </button>
            </div>
            </div> 
           
            </div>
              <div id="scheduleSelectionSection" style={{ display: isChannelConfirmed ? 'block' : 'none' }}>
              <i>Here you have the option of choosing weather to publish your post now or later. if later? select the desired time  </i>
              {/* <div className="form-control">
              <label className="label cursor-pointer">
                <span className="label-text text-primary">Save schedule template, use it for subsequent posts </span> 
                <input type="checkbox" className="toggle toggle-success"  />
              </label>
             </div> */}

             <div className="join m-4">



            

            <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        label=""
        value={schedule}
        onChange={setSchedule}
        className="input input-bordered input-primary w-full max-w-xs join-item"
        slotProps={{
          textField: {
            focused: true,
            color: 'primary',
          },
        }}
      />
      <label onClick={addSchedule} className="btn join-item rounded-r-full">
        <h2 className="text-bold text-primary">Add schedule</h2>
      </label>
    </LocalizationProvider>
              </div>

             

              <div className="w-100 text-sm text-left mt-1  mb-4 text-neutral-500 dark:text-neutral-300">
                {schedules.length?"This post will be published at :":""}
                  <div className="inline-block">
                
                        {schedules.map((value,key) => (
                      
                        <span className='flex-inline  badge badge-primary badge badge-outline badge'>
                          <div>
                              <span>
                                  {JSON.stringify(value)}
                              </span>
                              <label onClick={()=>removeSchedule(key)}
                                className=" btn-sm btn-ghost">X</label>
                          </div>
                        </span>
                      ))}
                              
                  </div>
                </div>
                <button  onClick={()=>handleHolloPostSubmit()}  className="btn btn-primary btn-sm m-2"  > 
                    Schedule post
                </button>
            </div>
           
            </div>

          </div>
        </div>
        
          </div>

          <div className='card w-1/2  p-0 m-0'>
        
             <input type="checkbox" id="hollo-published-feedback" className="modal-toggle" />
             <div className="modal ">
                  
                    <div className="card  bg-neutral text-primary-content">
                      <div className="navbar bg-primary text-primary-content p-0">
                      <button className="btn btn-ghost text-xl">Post published feedback</button>
                      <label id="modalCloseBtn" htmlFor="hollo-published-feedback" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                    </div>
                    <div className="card-body ">
                  
                      <figure className='item bg-primary w-20 h-20 rounded-full mt-2 ml-10'>      <h1 className='text-white text-5xl font-bold'>Ok</h1></figure>
                      <span className='text-primary'>
                        Congratulations post publishing successful.
                      </span>
                    
                    {/* <div id="channelSelectionSection"  style={{ display: isChannelConfirmed ? 'none' : 'block' }}>
                    */}
        {/*          
                      {isChannelEmptyError ? (
                    
                        ) : null}
                        <i>Click any of the channels to toggle between online and offline mode </i> */}
                      {/* <div class="card-actions mt-4 justify-start">
                    
                      </div>
                    
                    </div> */}
                      {/* <div id="scheduleSelectionSection" style={{ display: isChannelConfirmed ? 'block' : 'none' }}>
                      <i>Here you have the option of choosing weather to publish your post now or later. if later? select the desired time  </i>
                    
                    </div> */}
                  
                    </div>

                  </div>
             </div>
        
          </div>
       
</>
   
 

  )}

