import React, { useState,useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { checkAccessAuthorization } from '../../lib/check-auth';
import Layout from '../LayoutPage';
import { UpdateDeviceChannels, baseUrl, logPageVisit, toggleChannelState,holloHeader} from '../../lib/util';



function AdminDashboard() {
   logPageVisit()
   checkAccessAuthorization(useDispatch) 
   const [channels,setChannels]=useState(JSON.parse(localStorage.getItem("channels")))
   const [selectedChannelId, setSelectedChannelId] = useState(null);
   const [loading, setLoading] = useState(false);
   const hxxrl = JSON.parse(localStorage.getItem('hxxrl'));
   const [sub, setSub] = useState([localStorage.getItem('0x2')]);
  
  if (!hxxrl || !hxxrl.access_token) {
    throw new Error('No access token found');
  }
  
  async function handleChannelDelete(){
    setLoading(true)
         await toggleChannelState(selectedChannelId,'delete')
    

      let update= await UpdateDeviceChannels(hxxrl.user_id);
      if (update){
       setChannels(JSON.parse(localStorage.getItem("channels")))
       handleCloseModal()
       window.location.href = "/account#channelSection";
      }
    
      setLoading(false)
  }

  async function handleChannelTogle(sel_id){
    setLoading(true)
    await toggleChannelState(sel_id,'toggle')
    let update= await UpdateDeviceChannels(hxxrl.user_id);
    if (update){
     setChannels(JSON.parse(localStorage.getItem("channels")))
     window.location.href = "/account#channelSection";

    }
    setLoading(false)
  }
  function handleCloseModal(){

    const cntrlBtn = document.getElementById('deleteChannelModal')
    if (cntrlBtn) {
      cntrlBtn.click();
    }
  }
  useEffect(() => {
    const fetchStatus = async () => {
      try {

        const token = JSON.parse(localStorage.getItem('hxxrl'));
        
        if (!token || !token.access_token) {
          throw new Error('No access token found');}  
        const axios = require('axios').default;
        const url = `${baseUrl}/users/subscription?user_id=${token.user_id}`;
        const response = await axios.get(url,holloHeader());

        if (response.data && response.data.success) {
           setSub(response.data.data)
            localStorage.setItem('0x2',response.data.data)
        } else {
          throw new Error(response.data.error.message);
        }
      } catch (error) {
        console.error(error);
        alert(error.message);
      }
    };

    fetchStatus();
  }, []);
  return(
      
 
        <Layout>
           {/* vertical nav */}
           
       </Layout>
   
  
    );
}
export default AdminDashboard;