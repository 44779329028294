import React, { FC } from "react";
import BgGlassmorphism from "../../components/BgGlassmorphism";

export interface Card1Props {
  title: string;
  message: string;
  className?: string;
}

const Card1: FC<Card1Props> = ({
  className = "",
  title="",
  message=""
}) => {
  return (
    // <div className={`card relative  flex flex-col items-center justify-center text-center px-3 py-5 sm:p-6 ${className}`} >
    //   {/* <img src="" className={`flex-shrink-0 w-20 h-20 rounded-full overflow-hidden`}/> */}

    //   <div className="mt-1 ">
    //     <h2 className={`text-base sm:text-lg font-semibold`}> <span className="line-clamp-1">{title}</span> </h2>
    //     <span className={`block mt-[2px] text-sm text-neutral-500 dark:text-neutral-400`}>
    //       {message} 
    //     </span>
    //   </div>
    // </div>


<div className="card  bg-primary  lg:w-96 bg-base-100 shadow-xl">
  {/* <figure><img src="user.jpg" alt="Shoes" /></figure> */}
 
  <div className="card-body">
    <h2 className="card-title text-neutral justify-center text-2xl">
    {title}
      <div className="badge badge-white "></div>
    </h2>
    <p className="text-2xl">  {message} </p>
    {/* <div className="card-actions justify-end">
   
    </div> */}
  </div>
</div>
  );
};

export default Card1;
