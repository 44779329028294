import React from 'react';
import Layout from '../LayoutPage';
import  { Link } from 'react-router-dom';
import ConnectChannelComponent from '../../components/ConnectChannelComponent';


function ProfilePage() {
   // checkAccessAuthorization(useDispatch) 
  
    return(
      

        <Layout>
       {/* header banner */}
          

        <div class="flex flex-col space-y-4">

       
          <div className="avatar flex  justify-center mt-4">
          <div className="w-full max-w-xs rounded-full ring ring-primary ring-offset-base-100 ring-offset-2 mt-2">
               <img src="\cup.jpg" className='w-md' />
          </div>
            </div>

            <h2 className="text-xl flex  justify-center">
               <span className="badge badge-lg"> </span>
               
               </h2>

               <div className="flex  bg-neutral  text-neutral-content">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Congratulations on successfully connecting your channel🎉</h2>

            <span >
            This is an excellent achievement and an important step towards growing your brand and expanding your audience. You've already taken a significant step towards reaching a wider audience, but why stop there? Add more channel by clicking on the <span className='text-primary'>Connect Channel</span> button. By adding more channels to your account, you can increase your reach even further and connect with even more people. Click the <span className='text-primary'>Start posting</span> button continue
            </span>
          
           
           
           
           
           
           <div className="flex w-full pt-4">
            <div className="grid pt-4 h-20 flex-grow card bg-base-300 rounded-box place-items-center">
            <ConnectChannelComponent />
            </div>
            <div className="divider divider-horizontal">OR</div>
            <div className="grid h-20 flex-grow card bg-base-300 rounded-box place-items-center">
            <Link className="btn btn-neutral btn-sm btn-outline  normal-case text-xl" to="/home">Start posting </Link>
            </div>
            </div> 
           
            </div>
           </div>
         
           
      </div>
         
       </Layout>
   
  
    );
}
export default ProfilePage;