import React from 'react';
import RegisterForm from '../forms/RegisterForm';
import Layout from './LayoutPage';
import {checkPublicAuthorization} from '../lib/check-auth'
import { useDispatch } from 'react-redux';


function RegisterPage() {
   
    checkPublicAuthorization(useDispatch)
  
    return(

        <Layout>
        <div className="hero min-h-screen bg-base-200">
        <RegisterForm />
        </div> 
        </Layout> 
        
    );
}
export default RegisterPage;