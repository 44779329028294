import React from "react";
import Card1 from "../cards/CardCategory/Card1";
import Heading from "../components/Heading";
import BgGlassmorphism from "../components/BgGlassmorphism";


export interface WhyType {
  title: string;
  message: string;
}
export interface ListContainerProps {
  data?: WhyType[];
  heading:string;
  subHeading:string;
  headingCenter?: boolean;
  className?: string;
}

const DATA = [
  {
    "title": "Post Consistently ",
    "message": "erify correct class name: Double-check that the class name is correct and matches the CSS framework you are us",
  },
  {
    "title": "Boost Engagement",
    "message": "erify correct class name: Double-check that the class name is correct and matches the CSS framework you are using. Differen",
  },
  {
    "title": "Grow followers",
    "message": "erify correct class name: Double-check that the c",
  },
  {
    "title": "Boost sales",
    "message": "erify correct class name: Double-check that the class name is correct and matches the CSS framework you are using. Differt",
  }
]

const ListContainer: React.FC<ListContainerProps> = ({
  data = DATA,
 heading="Title goes here ",
 subHeading="a selling sub title selling the title",
  headingCenter = true,  
  className = "",
}) => {
 

  return (
    <section>
  <Heading desc={subHeading} isCenter={headingCenter}>
   {heading}
  </Heading>
 
  <div className="flex justify-center w-full">
    <div className="grid grid-cols-1 gap-5 xl:grid-cols-3  sm:gap-6 md:gap-8">
      {data.map((item) => (

      
        <Card1
            title={item.title}
             message={item.message}
        />
      ))}
    </div>
  </div>
</section>

  );
};

export default ListContainer;
