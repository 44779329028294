import React from 'react';
import Layout from '../LayoutPage';
import  { Link } from 'react-router-dom';

import { useSearchParams } from 'react-router-dom';

function ProfilePage() {
   // checkAccessAuthorization(useDispatch) 
   const [searchParams ] = useSearchParams();
   const error = searchParams.get("error")
    return(
      

        <Layout>
          {/* header banner */}
          
        <div class="flex flex-col space-y-4">

       
          <div className="avatar flex  justify-center mt-4">
          <div className="w-full max-w-xs rounded-full ring ring-primary ring-offset-base-100 ring-offset-2 mt-2">
               <img src="\denied.jpg" />
          </div>
            </div>

            <h2 className="text-xl flex  justify-center">
               <span className="badge badge-lg"> </span>
               
               </h2>

               <div className="flex  bg-neutral  text-neutral-content">
          <div className="card-body items-center text-center">
            <h2 className="card-title">Access Denied</h2>
            <div className="flex w-full pt-4">
                <span >
                {error} 
                </span>
            </div>
            <Link className="btn btn-primary normal-case text-xl" to="/home">Go back  </Link>
           </div>
        </div>   
           
      </div>
         
       </Layout>
   
  
    );
}
export default ProfilePage;