import React,{useState,useEffect} from 'react';
import { Formik, Form, Field } from 'formik';
import {UpdateDeviceHashtags, baseUrl, holloHeader, toggleHashtagState} from '../lib/util'
import * as Yup from 'yup';
import axios from 'axios';
const schema = Yup.object().shape({
    
    tag: Yup.string().required('Required') ,  

  });

export default function HashtagForm() {
  const [hashtags, setHashtags] =  useState(JSON.parse(localStorage.getItem('hashtags')));
  const [loading, setLoading] = useState(false);

 
  
  async function handleHashtagToggle(id) {
    setLoading(true);
    await toggleHashtagState(id, 'toggle');
    await UpdateDeviceHashtags() 
  
    setLoading(false);
  }

  async function handleHashtagDelete(id) {
    setLoading(true);
    await toggleHashtagState(id, 'delete');
    await UpdateDeviceHashtags() 

    setLoading(false);
  }

  const updateHashtags = (responseData, setHashtags) => {
    // Append the newly created post to the existing posts in state
    setHashtags((prevHashtags) => [responseData.data, ...prevHashtags]);
    
   
    const storedPosts = JSON.parse(localStorage.getItem('hashtags')) || [];
    localStorage.setItem('hashtags', JSON.stringify([responseData.data, ...storedPosts]));
  
  };
  
  return(
   
    
  
      <div className="card-body">
       <Formik
      initialValues={{ tag: '' }}

      validationSchema={schema}

      onSubmit={async (values, { setSubmitting }) => {
       
        
          //call api and pass in data
          let data =JSON.stringify(values, null, 2);
  
          //api call
      
          try {
                                     
            const response = await axios.post(baseUrl + "/hashtags/create",data, holloHeader());
      
            if (response.data) {
              let responseData = response.data;

            

              if (responseData.success) {
                     
                    updateHashtags(responseData,setHashtags)                    
              } else {
                alert("error")   
          
             
              }
            
          
            }
          } catch (error) {
       
            console.log(error);
              alert("thrown error")   
            
          }
        

          setSubmitting(false);
       
      }}
    >
       {({ errors, touched }) => (
         <Form>
      

       <h3>Add a new hashtag</h3>
        <div className="join">
            <Field name="tag"  type="text"   className="input input-bordered  join-item" />
            <button  type="submit" className="btn btn-primary join-item ">Add +</button>
        </div> 
        <label className="label font-bold text-primary">{errors.tag && touched.tag ? ( <div>{errors.tag}</div> ) : null}</label>
      
    
       


          
         </Form>
      )}
       </Formik>

  



              <section className="p-4" id="">
                {hashtags.length > 0 &&
                  <div className="card-actions justify-start">
                    {hashtags.map((hashtag) => (
                      <div key={hashtag._id} className="card-actions justify-start">
                        {loading ? (
                          <button className="btn btn-sm loading m-2"></button>
                        ) : (
                          <div className="btn-group flex">
                         
                            <button onClick={() => { handleHashtagToggle(hashtag._id) }} 
                            className={hashtag.is_offline ? "btn btn-xs btn-outline" : "btn btn-xs  btn-primary"}>
                             #{hashtag.tag} 
                            </button>
                            <label className="item-center btn btn-outline btn-xs item-center"
                        
                              onClick={() => {
                                handleHashtagDelete(hashtag._id);
                              }}
                            >Delete</label>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                }
              </section>
      </div>
  

  )}

