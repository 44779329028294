import React from 'react'




const TaglineCard =()=> {
  return (
    <>
    <div className="my-8 flex justify-center ">
         
    <div className="card w-96 bg-base-100 shadow-xl ">
      <div className="card-body justify-evenly ">
      <span className="text-2Xl font-bold">Communicate your | <span className='text-primary'>REALITY</span></span>
   {/* <p>   <span className='text-primary'>Hollo </span>We help you tell your story.</p> */}
      </div>
    </div>
  
    </div> 
    </> 
  );
}

export default TaglineCard();
