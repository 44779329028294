import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import BannerContainer from '../containers/BannerContainer';
import Layout from './LayoutPage';
import {checkPublicAuthorization} from '../lib/check-auth'
import {useDispatch}from 'react-redux'
import PageSubcription from '../components/PageSubcription';
import Heading from "../components/Heading";
import ListContainer from '../containers/ListContainer';
import {FetchFeaturedPost, convertNumbThousand, logPageVisit} from '../lib/util'
import PlatformContainer from '../containers/PlatformContainer';

import { baseUrl, holloHeader } from '../lib/util';
import PostContainer from '../containers/PostContainer';
import CardSlider from '../components/CardSlider';
import ResponsiveCarousel from '../components/ResponsiveCarousel';
import ManagedSubcription from '../components/ManagedSubcription';

const   HomePage = () => {
  logPageVisit()
  checkPublicAuthorization(useDispatch)
  const [searchParams ] = useSearchParams();
  const [analytic, setAnalytic] = useState([]);
  const page_size=5
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeService, setActiveService] = useState("self-service");
  const [currentPage, setCurrentPage] = useState(1); 
  const referalCode = searchParams.get("referal")
  const  Users =[
    {
      id: "string | number",
      firstName: "ojonimi",
      lastName: "ojochuma",
      username: "ojochuma",
      avatar: "user.jpg",
      bgImage:"user.jpg",   
      email: "test@email.com",
      postCount: 6,
      about: "string",
      fullname: "ojonimi ojochuma",
      href: "string",
    }
  ]
  const  Platforms =[
    {
      id: "1",
      tag: "Facebook Page",
      isactive: "true",
      icon: "facebook.png",
    },
    {
      id: "4",
      tag: "Linkedin Pages",
      isactive: "true",
      icon: "linkedin.png",
      
    },{
      id: "4",
      tag: "Linkedin Profile",
      isactive: "true",
      icon: "linkedin.png",
      
    }, 
    
    {
     id: "7",
     tag: "Instagram Creator",
     isactive: "true",
     icon: "instagram.png",
   }, 
    {
     id: "3",
     tag: "Instagram Bussiness",
     isactive: "true",
     icon: "instagram.png",
   },
   {
    id: "4",
    tag: "Twitter(X)",
    isactive: "false",
    icon: "twitter.png",
    
  }
  ]
  const  ComePlatforms =[
   ,  {
      id: "2",
      tag: "Tik-tok",
      isactive: "true",
      icon: "tiktok.png",
      
    },
    {
      id: "3",
      tag: "Youtube",
      isactive: "true",
      icon: "youtube.png",
      
    },
    {
      id: "4",
      tag: "Pintrest",
      isactive: "true",
      icon: "pintrest.png",
      
    }
  ]
  const FeaturesData = [
    {
      "title": "Cross Platform posting ",
      "message": "",
    },
    {
      "title": "Scheduled posting ",
      "message": "",
    },
    {
      "title": "Hashtag Management",
      "message": "",
    },
    {
      "title": "Business Verification",
      "message": "",
    },
    {
      "title": "Cross selling",
      "message": "",
    },
    {
      "title": "Content Creation",
      "message": "",
    }
  ]
  // const FeaturesData = [
  //   {
  //     "title": "Cross Platform posting ",
  //     "message": "When you post from hollo, it goes out to all the account conected to your hollo account instantly",
  //   },
  //   {
  //     "title": "Scheduled posting ",
  //     "message": "With hollo you can effeciently manage your brand conversation with the scheduling on, you can speak into the future.",
  //   },
  //   {
  //     "title": "Bulk Posting",
  //     "message": "Imagine that you have created your content calender for the year. With your content calender and hollo you can instantly create all the posting you need in a year under 2 minuites",
  //   }
   
  // ]
  const slide_item = {
    one: "Get upto 40% discount on all the package when you subscribe to any package for 12 months",
    four: "Get upto 30% discount on all the package when you subscribe to any package for 6 months",
    platform: "Get upto 25% discount on all the package when you subscribe to any package for 1 months",
   
  
   
  };
  const BenefitData = [
    {
      "title": "Consistent Branding",
      "message": "Ensure a consistent brand image across platforms by easily posting and scheduling content, maintaining a unified and professional online identity.",
    },
    {
      "title": "Save Time and Effort ",
      "message": "Efficiently manage all your social media accounts in one central hub, saving you valuable time and effort.",
    },
    {
      "title": "Streamlined Social Media Presence ",
      "message": "Stay organized and maintain a cohesive online presence by effortlessly posting across multiple platforms from a single dashboard.",
    },
    {
      "title": "Optimize Posting Schedule",
      "message": "Maximize engagement by scheduling your posts at the optimal times, ensuring your content reaches the right audience.",
    },
      {
      "title": "Flexibility and Convenience",
      "message": "Enjoy the flexibility to plan and schedule your posts in advance, giving you the convenience to focus on other aspects of your business or personal life.",
    },
    {
      "title": "Enhanced Productivity",
      "message": "Boost your productivity with Hollo's intuitive features, allowing you to efficiently plan, schedule, and manage your social media content.",
    }
   
  ]

 
  // /analytic/app/stat
  const values = Object.values(slide_item);
  const testimonies = [
    { title: 'Samson Omale', content: 'Hollo makes it easy to keep our brand consistent across platforms. Posting and scheduling content is a breeze, helping us maintain a unified, professional online presence.', author: 'John Doe',icon:'sam.jpeg' },
    { title: 'Adaeze sreal', content: 'With Hollo, managing all our social media accounts is so much easier. Everything’s in one place, which saves us tons of time and effort.', author: 'Jane Smith',icon:'ada.jpeg'  },
    { title: 'Joy Akoh', content: 'Hollo has really boosted my productivity! Its intuitive features make it so easy to plan, schedule, and manage all my social media content efficiently', author: 'Jane Smith',icon:'joy.jpeg'  },
    
    { title: 'Eesha Restaurant', content: 'Hollo helps us maximize engagement by scheduling posts at the best times, ensuring our content reaches the right audience While we focus on preparing and serving delicious meal', author: 'Aisha emanuel',icon:'eesha.jpeg'  },

    { title: 'Evlin Philip', content: 'Hollo helps us stay organized and maintain a consistent online presence by making it easy to post across multiple platforms from one simple dashboard.', author: 'Mary Johnson' ,icon:'eve.jpeg' }
  ];
  useEffect(() => {
    const fetchInitialPosts = async () => {
      try {
       
        const axios = require('axios').default;
        const url = `${baseUrl}/analytic/app/stat`;

        const response = await axios.get(url, { headers: {'Content-Type': 'application/json'}});
      
        
        if (response.data && response.data.success) {
          setAnalytic(response.data.data);
        } else {
          throw new Error(response.data.error.message);
        }
      } catch (error) {
        console.error(error);
        alert(error.message);
      }
    };

    fetchInitialPosts();
  }, []);



  useEffect(() => {
    const storedPosts = JSON.parse(localStorage.getItem('featuredPosts')) || [];
  
    if (storedPosts.length > 0) {
      setPosts(storedPosts);
     } else {
      
      const initialPosts = async () => {
        try {
          setLoading(true);
          const fetchedPosts = await FetchFeaturedPost( currentPage, page_size);

          setPosts((prevPosts) => [...prevPosts, ...fetchedPosts]);
          localStorage.setItem('last_feed_page', currentPage);
        } catch (error) {
          console.log(error);
          alert(error.message);
        } finally {
          setLoading(false);
        }
      };
      initialPosts();
    }
  }, [currentPage]);


  return (
    
       <Layout>
  

           <div className='flex-item mb-8 bg-neutral' >
                  {BannerContainer}
            </div>
         
      
         <div className='flex-item mt-8 ' >
       
         <ResponsiveCarousel />
         </div>


            {/* <div className='flex-item m-16'>
              <PromoSplash />
            </div> */}

            <div className='flex-item pt-8 pb-8 mb-24 h-1/2 bg-neutral'>
                 <div className="   sm:p-10 ">
                    <ListContainer data={FeaturesData} heading='Features' subHeading='Get the best out of hollo with our solutions ' /> 
                  </div>
         </div>



         <div className='flex-item '>
            <div className='flex justify-center'>
                    <div className="stats stats-vertical lg:stats-horizontal shadow-xl item ">
                  
                          <div className="stat">
                            <div className="stat-figure text-secondary">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            </div>
                            <div className="stat-title">Total Active Users </div>
                            <div className="stat-value">{convertNumbThousand(analytic.total_active_user)}</div>
                        
                          </div>
                        
                          <div className="stat">
                            <div className="stat-figure text-secondary">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path></svg>
                            </div>
                            <div className="stat-title">Total Connected Channels</div>
                            <div className="stat-value">{convertNumbThousand(analytic.total_connected_channel)}</div>
                          
                          </div>
                          
                          <div className="stat">
                            <div className="stat-figure text-secondary">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"></path></svg>
                            </div>
                            <div className="stat-title text-bold">Total Posts</div>
                            <div className="stat-value">{analytic.total_post}</div>
                          </div>
                              
                          <div className="stat">
                            <div className="stat-figure text-secondary">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-8 h-8 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path></svg>
                            </div>
                            <div className="stat-title">Total site visit </div>
                            <div className="stat-value">{analytic.total_site_visit}</div>
                          
                          </div>
                    </div>
    

            </div>
         </div>
   

          <div className='flex-item  bg-primary  mt-24 '>
         
              <div className=" bg-neutral  p-5 sm:p-10 flex  m-2 mb-10 lg:w-full bg-base-100 shadow-l justify-center ">
                <CardSlider testimonies={testimonies} />
            
              </div>
         </div>
      

         <div className='flex-item  '>
                
                <section id='register'>
                     <Heading desc="" isCenter >
                      Register a hollo account
                    </Heading>

                  <div className='flex flex-row'>
             

                  {/* bg-base-300 */}

            <div id="self-service-btn"
                  className={`flex-grow text-primary font-bold   card sm:w-full p-4 m-2 rounded-box place-items-center cursor-pointer ${
                    activeService === "self-service"? "bg-primary text-white" : "bg-neutral  text-black"
                  }`}
                  onClick={() => setActiveService("self-service")}
      >
        As Self Service
        <p className="text-2xl">
          Ideal for social media managers.
        </p>
      </div>

      <div  id="managed-service-btn"
        className={`flex-grow card font-bold   text-primary  w-full p-4 m-2 rounded-box place-items-center cursor-pointer ${
          activeService === "managed-service" ? "bg-primary text-white" : "bg-neutral text-black"
        }`}
        onClick={() => setActiveService("managed-service")}
      >
        As Managed Service
        <p className="text-bold text-2xl ">
          Ideal for business Owners.
        </p>
      </div>
      </div>
      {/* Content Sections */}
      <div className="w-full ">
        {activeService === "self-service" && (
          <div id="self-service">
            <PageSubcription />
          </div>
        )}

        {activeService === "managed-service" && (
          <div id="managed-service">
            <ManagedSubcription />
          </div>
        )}
      </div>
 
          </section>
                  </div>

<div className='flex-item '>
<div className="flex sm:p-10 mt-20 justify-center">
         <PlatformContainer platforms={Platforms} title="Suported platforms" desc="Talk to all your customers on these platforms"  />  
    </div>
</div>



        {/*  */}
    


       


          {/*  */}
               
 {/* <div className='p-10'>
                <Heading desc="100% money back guarantee for the 6months and 1 year subscription" className='' isCenter >
                  <Slider values={values} />
                </Heading>
                </div> */}
       {/* <BgGlassmorphism /> */}

        {/* <div className='flex-item m-16'>
             <div className="hero sm:p-10  relative p-5 ">     
            <ListContainer2 data={BenefitData} heading='Benefit' subHeading='With hollo, you are perfectly positioned to out perform your competitors' /> 
            </div>
         </div> */}



<div className='flex-item' >
          <Heading desc="Posts made from Hollo" isCenter > Latest posts  </Heading> 
              <div className="hero relative  sm:p-10 ">

                <PostContainer posts={posts} />

                  {loading?
                    <div className='flex justify-center'>
                            <button className="btn btn-ghost loading loading-infinity  btn-lg "></button>
                    </div>:""
                  }
              </div>
         </div>
{/*  */}



<a style={{ textAlign: 'center' }} className='btn btn-primary btn-outline  btn-block pt-2 pb-2' href='#register'>Try hollo now</a>

         

    {/*  */}

         {/* <div className='flex-item m-16'> */}
                 {/* <div className='flex justify-center p-10'>
                <div >
                    <Heading desc="Hurry register today to be among the first 10,000 people. don't get lockedout" isCenter >
                      Users acquisition roadmap 
                    </Heading>
                      <ul className="steps steps-vertical lg:steps-horizontal">
                          <li className="step step-primary">+1</li>
                          <li className="step step-primary">1,000 users</li>
                          <li className="step">2,000 users</li>
                          <li className="step">5,000 users</li>
                          <li className="step">10,000 users</li>
                          <li className="step font-bold text-primary">Pause subscription</li>
                        </ul>
                      </div>
                </div> */}
         {/* </div> */}
    {/*  */}


<div className='flex-item'>
<div className=" flex  justify-center">
        
         <PlatformContainer platforms={ComePlatforms} title="Coming soon" desc="" /> 
    </div>
</div>
{/* <a style={{ textAlign: 'center' }} className='btn btn-primary btn-outline  btn-block pt-2 pb-2' href='#register'>Use hollo</a> */}

{/*  */}

          
    
         
      
        {/* </div> */}
      

        {/* 
     
        //learn hollo
        // learn container is for teaching the person on this page something new using 
        // displays post with hashtag learn. the query should be post with hashtag #learn #learnonhollo that has the highest view on hollo.
        <LearnContainer />
        <PostsContainer />
                {/* <UsersContainers users={Users} />  */}
       
        {/* <FeedbackContainer />  */}

   
       </Layout>
    
    


    
  );
}
export default HomePage
