import React,{ useState,useEffect } from 'react'
import {callback_url, getToken, UpdateDeviceChannels} from "../lib/util"
import { useSearchParams } from 'react-router-dom';
import {baseUrl,holloHeader,} from '../lib/util'
const axios = require('axios').default;


export default  function CallBack(){
 
  const token = getToken()
  const [searchParams ] = useSearchParams();


  let [connectingPages,setConnectingPages] = useState([])
  let isInstgrameConectionReady = false



   let userId = token.user_id 
 
   const code = searchParams.get("code")
   const state = searchParams.get("state")
   const error = searchParams.get("error")

  const outh_v1 = searchParams.get("oauth_token")
  const oauth_verifier = searchParams.get("oauth_verifier")


   let is_page_connection = localStorage.getItem("is_page_connection");
   const connecting_payload = JSON.parse(localStorage.getItem('connecting_payload'))       
  
   let has_verified_code=localStorage.getItem("has_verified_code")
   const clear_linking_data=()=>{
    isInstgrameConectionReady=false
      localStorage.removeItem("connecting_payload");
      localStorage.removeItem("connecting_channel");
      localStorage.removeItem("is_page_connection");
      localStorage.removeItem("connecting_platform");
      localStorage.removeItem("has_verified_code")
      localStorage.removeItem("activated_void_x")
   }
  
   if(code){
    if (has_verified_code == null){
    
     const platform_tag = localStorage.getItem('connecting_platform')
    
  //uses this to create the channel for the platform of choice 
    if(connecting_payload){
      const payload =
      {
       "platform_tag": platform_tag,
       "code": code,
       "state": state,
       "code_verify": connecting_payload.code_verify,
       "channel_for": connecting_payload.channel_for,
       "user_id": connecting_payload.user_id,
       "client_url":callback_url
     }
    
    try {
   
      axios.post(baseUrl + "/callback",payload, holloHeader())
          .then(response => {
           
            if(response.data){
             let  responseData = response.data
           
             if(responseData.success){
                          localStorage.setItem("has_verified_code",true)

                          if (is_page_connection === "false") {
                            finilize_connection(userId);

                          } else {
                                //the id of the channel created is what will be returned 
                              
                                displayChannelPageModal(responseData.data);
                              }
                }else{


            

                  clear_linking_data()
                  window.location.href="/error?error="+responseData.error.message
                }
          
        
          }
          
        })
    } catch (error) {
      console.error(error);
      clear_linking_data()
      window.location.href="/error?error="+error
    }

    }
  
  
  }
     
   }
   if(error){
    //handle error on rejection
    clear_linking_data()
    window.location.href="/error?error="+error
   }
  
 if(outh_v1){

   complete_x_v1()
 
 }

   const resetConnection= ()=>{
    clear_linking_data()
    
    window.location.href="/error?error=You have to properly configure the account you want to connect with Hollo. Please contact support for assistance."
   }

   /// functions 
    // connect the selected page to the channel last leg
    const handleConnectPage=(page)=>{
   
      let   page_token="/null"
      if(page.token){
        page_token='/'+page.token
     
      }

      let url=baseUrl+'/channels/connect/page/'+page.channel_id +'/'+page.name+'/'+page.page_id+page_token

      try {
        // Call API
    
        axios.get(url, holloHeader())
            .then(response => {
            
              if(response.data){
               let  responseData = response.data
  
               if (responseData.success) {
                finilize_connection(userId)
              } else {
                console.log(responseData.error);
           
                clear_linking_data()
                window.location.href="/error?error="+responseData.error.message
                
               }
              return responseData
            }else{
              console.log("Could not connect channels "+response);
             
              clear_linking_data()
              window.location.href="/error?error=Could not connect channels "+response
            }
            
          })
        
      } catch (error) {
        console.error(error);
     
        clear_linking_data()
        window.location.href="/error?error="+error
      }
    
 } 


 const handleInstagramConnetPage=(page)=>{

  let url=baseUrl+'/channels/fetch/page/instagram/'+page.page_id +'/'+page.channel_id 

  try {
    // Call API
    axios.get(url, holloHeader())
        .then(response => {
   
          if(response.data){
           let  responseData = response.data

           if (responseData.success) {
            //
            
            setConnectingPages([]);
            setConnectingPages(responseData.data)
            isInstgrameConectionReady=true
            const label = document.querySelector("label[for='finalize-insta-account']");
          



            const pageLabelTitle = document.getElementById("page_label_title");
            pageLabelTitle.textContent ="Choose the instagram account you want to connect to your hollo account "
    
           
            label.click();
          } else {
            console.log(responseData.error);
            
            clear_linking_data()
            window.location.href="/error?error="+responseData.error.message
            
           }
          return responseData
        }else{
          console.log("Could not connect channels "+response);
          alert("Could not connect channels")
          clear_linking_data()
          window.location.href="/error?error=Could not connect channels "+JSON.stringify(response)
        }
        
      })
    
  } catch (error) {
    console.error(error);
 
    clear_linking_data()
    window.location.href="/error?error="+error
  }

} 



 const displayChannelPageModal=(channel_id)=>{
  try {
    
    axios.get(baseUrl+'/channels/fetch/pages/'+channel_id, holloHeader())
        .then(response => {
        
          if(response.data){
           let  responseData = response.data
           if(responseData.success){
          
            setConnectingPages(responseData.data)
            const label = document.querySelector("label[for='connect-account']");
         
            label.click();
           }else{
        
             console.log(responseData.error)
           
        
                    //display error and delete the channel or handle error case properly
                   //check if error has status 400 first
                   if(responseData.error.status_code==400){
                      const toggler = document.getElementById("choose_service_type_btn");
                      toggler.click();
                   }
                   
 
           }
          
        }
      })
  } catch (error) {
    console.error(error);
    alert(error)
  }
}
  async function complete_x_v1() {

           const request_token_secrete = localStorage.getItem("void_ot_x")
           try {                                            
            
            const url =`${baseUrl}/authenticate/twitter/v11?user_id=${userId}&request_token=${outh_v1}&request_token_secrete=${request_token_secrete}&oauth_verifier=${oauth_verifier}`
             const response = await axios.get(url,holloHeader());
           
             if (response.data) {
               let responseData = response.data;
               
               if (responseData.success) {

                  await UpdateDeviceChannels(userId)
                  clear_linking_data()
                  window.location.href="/success"

               } else {
                 console.log(responseData.error.message);
                 alert(responseData.error.message);
                  clear_linking_data()
             
               }
             }
           } catch (error) {
             console.log(error);
             alert(error);
             clear_linking_data()
           }

 

 
 }


 const finilize_connection=async (userId)=>{
   // here check if its a twitter connection start the second round of authorization to get the v1 token

  const connecting_platform = localStorage.getItem("connecting_platform")
  const twitter_x = localStorage.getItem("activated_void_x")

   if (connecting_platform =="twitter")
   {
           
            try {
              const response = await axios.get(baseUrl+'/authenticate/twitter/v1?clientUrl='+callback_url,holloHeader());
            
              if (response.data) {
                let responseData = response.data;
                
                if (responseData.success) {
                
                  localStorage.setItem('void_ot_x',responseData.data.ot_secrete)
                  // alert(responseData.data)
                  window.location.replace(responseData.data.url)
                } else {
                  console.log(responseData.error.message);
                  alert(responseData.error.message);
                }
              }
            } catch (error) {
              console.log(error);
              alert(error);
            }
   }
   else
   {
     await UpdateDeviceChannels(userId)
     clear_linking_data()
     window.location.href="/success"
   }

  
  }

  


      return(
          <>
          <div class="flex flex-col h-full space-y-4">
            <div className="avatar flex justify-center mt-10">
              <div className="w-full max-w-xs rounded-full ring ring-white ring-offset-base-100 ring-offset-2 mt-2">
                <img src="\processing.jpg" className='w-md' />
              </div>
            </div>

            <h2 className="text-xl flex justify-center">
              <span className="badge badge-lg"> </span>
            </h2>

            <div className="flex bg-neutral text-neutral-contentl">
              <div className="card-body flex items-center justify-center text-center">
                <h2 className="card-title">
                  <button className="btn loading">Linking</button>
                </h2>
                <span>
                  Linking your account. Don't leave this page or refresh!!! You will be redirected once complete.
                </span>
              </div>
            </div>
          </div>

      

          <div className='card w-42'>
       

     


          <label htmlFor="connect-account" className="hidden"></label>
          <label htmlFor="finalize-insta-account" className="hidden"></label>
         </div>

         <input type="checkbox" id="connect-account" className="modal-toggle" />
          <div className="modal card bg-neutral text-primary-conten">
            <div className="modal-box relative">
              <label htmlFor="connect-account" className="btn btn-sm btn-circle absolute right-2 top-2"> ✕ </label>
            
            
          {connecting_payload.channel_for === "instagram_user_feed" ? (
            <>
              <h3  className="text-lg font-bold m-4 text-primary">Choose a Facebook page that is linked to your Instagram business.</h3>

              <div  class="m-1">
                {connectingPages.map(page => (
                   <div key={page.id}    id="pageConnectHandleBtn" class="btn-group m-2"   onClick={() => handleInstagramConnetPage(page)}>
                   <button class="btn btn-circle avatar btn-sm btn-circle btn-outline">
                  <img  className = "w-5 rounded-full"  src="instagram.png" /> 
                  </button>
                <button class="btn btn-sm">
                {page.name}
               </button>
                  
                 
                  </div>
                 
                ))}
              </div>

            </>
          ) : (
            <>
              <h3 className="text-lg font-bold m-4 text-primary">Choose a page/group  to connect with hollo ✨.</h3>
              <div  class="m-1">
                {connectingPages.map(page => (
                   <div key={page.id}     class="btn-group m-2"   onClick={() => handleConnectPage(page)}>
                   <button class="btn btn-circle avatar btn-sm btn-circle btn-outline">
                  <img  className = "w-5 rounded-full"  src="instagram.png" /> 
                  </button>
                <button class="btn btn-sm">
                {page.name}
               </button>
                  
                 
                  </div>

                 
                ))}
              </div>
            </>
          )}

            </div>
          </div>
         

          <input type="checkbox" id="finalize-insta-account" className="modal-toggle" />
          <div className="modal m-0 p-0 card bg-neutral text-primary-conten">
            <div className="modal-box relative mt-0">

            <div className="navbar bg-primary text-primary-content ">
            
            <button  className="pl-4 btn btn-ghost text-xl">Finalize channel Connection </button>
            <button className="btn btn-ghost text-xl"></button>
            <label id="connect-account" htmlFor="connect-account" className="btn btn-sm btn-circle absolute right-2 top-2">
              ✕
            </label>
          </div>


              <div className="card-body w-96">
               <div>
                <p  id="page_label_title" >Choose a Facebook page that is linked to your Instagram business</p>
               </div>
              <div  class="m-1">
                    {connectingPages.map(page => (
                     <div key={page.id}    id="pageConnectHandleBtn" class="btn-group m-2"   onClick={() => handleConnectPage(page)}>
                       <button class="btn btn-circle avatar btn-sm btn-circle btn-outline">
                      <img  className = "w-5 rounded-full"  src="instagram.png" /> 
                      </button>
                      <button class="btn btn-sm">
                      {page.name}
                    </button>
                      
                      </div>

                    ))}
                  </div>
            </div>

            </div>
          </div>


          <label className="" id="choose_service_type_btn" htmlFor = "authorizationerror"></label>


{/* modal  */}


<input type="checkbox" id="authorizationerror" className="modal-toggle" />
    <div className=" modal w-full ">
    <div className="w-1/2 ">
     
       <div className="card   bg-neutral text-primary-content">
         <div className="navbar bg-primary text-primary-content p-0">
         <button className="btn btn-ghost text-xl">Facebook account not properly configured</button>
     
       </div>
       <div className="card-body">

     
          
       <div className="form-control">
         <label className="label cursor-pointer">
    

             <span className="label-text">
                    <strong>YOU HAVE NOT PROPERLY CONFIGURED YOUR FACEBOOK ACCOUNT TO CONNECT TO HOLLO</strong>
                    <br/>
                    <article>
                        For Hollo to be able to connect to your Facebook page, you must confirm you have done the following:
                    </article>
                    <br/>
                    <h3><strong>Given the Facebook user you want to use with Hollo access to the page:</strong></h3>
                    <ul>
                        <li><strong>Switch to the Page</strong></li>
                        <li><strong>Go to the page settings</strong></li>
                        <li><strong>Click the Page Setup</strong></li>
                        <li><strong>Click View on the Page Access</strong></li>
                        <li><strong>Click Add New, search for the username or email of the Facebook user you want to add, and follow the instructions</strong></li>
                    </ul>
                    <p>
                        Note: There is a Facebook bug where the username or email of the user does not show up, especially if you had given that user access in the past and revoked it. In that case, kindly contact our support staff to help you with the workaround.
                    </p>
                    <ul>
                        <li><strong>Confirm that you have added the desired user by checking the "People with page access" tab</strong></li>
                    </ul>
                    <br/>
                    <h3><strong>Give Hollo the requested permissions to the user account:</strong></h3>
                    <ul>
                        <li><strong>Restart the channel connection again: Click on connect channel on Hollo.</strong></li>
                    </ul>
                  
              </span>



         </label>
       </div>
     
       <hr />
      
       <button onClick={resetConnection} className='btn btn-primary btn-block'>
                      Continue
                    </button>
       </div>
   
     </div>
   </div>
    </div>

          </>
      );
  }
 