import React from 'react';
import LoginForm from '../forms/LoginForm';
import Layout from './LayoutPage';
import {checkPublicAuthorization} from '../lib/check-auth'
import {useDispatch}from 'react-redux'
import {logPageVisit} from '../lib/util'
function LoginPage(){
  checkPublicAuthorization(useDispatch)
  logPageVisit()
    return(
        <Layout>
          <div className="hero min-h-screen bg-base-200">
          <LoginForm />
          </div>
        </Layout>    
    );
}
export default LoginPage;