

export const CLIENT_SET = 'CLIENT_SET'  
export const CLIENT_UNSET = 'CLIENT_UNSET'  

//signups
export const SIGNUP_REQUESTING = 'SIGNUP_REQUESTING'  
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'  
export const SIGNUP_ERROR = 'SIGNUP_ERROR'


//login ,
  
export const LOGIN_REQUESTING = 'LOGIN_REQUESTING'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const LOGIN_EXISTING = 'LOGIN_EXISTING'
export const LOGOUT = 'LOGOUT'


//LOGOUT
export const  LOGOUT_REQUESTING="LOGOUT_REQUESTING"

//CHANNEL CONNECTION
export const  CHANNEL_CONNECT_REQUESTING = "CHANNEL_CONNECT_REQUESTING"
export const CHANNEL_CONECT_ERROR = 'CHANNEL_CONECT_ERROR'  
export const CHANNEL_CONECT_SUCCESS = 'CHANNEL_CONECT_SUCCESS'

export const  CREATE_CHANNEL_REQUESTING = "CREATE_CHANNEL_REQUESTING"
export const  CREATE_CHANNEL_ERROR = "CREATE_CHANNEL_ERROR"
export const  CREATE_CHANNEL_SUCCESS = "CREATE_CHANNEL_SUCCESS"

export const FETCH_CHANNELS ="FETCH_CHANNELS"
export const FETCH_CHANNELS_ERROR ="FETCH_CHANNELS_ERROR"
export const FETCH_CHANNELS_SUCCESS ="FETCH_CHANNELS_SUCCESS"

//POST CONNECTION
export const  POST_REQUESTING = "POST_REQUESTING"
export const POST_ERROR = 'POST_ERROR'  
export const POST_SUCCESS = 'POST_SUCCESS'

const avatarColors = [
    "#ffdd00",
    "#fbb034",
    "#ff4c4c",
    "#c1d82f",
    "#f48924",
    "#7ac143",
    "#30c39e",
    "#06BCAE",
    "#0695BC",
    "#037ef3",
    "#146eb4",
    "#8e43e7",
    "#ea1d5d",
    "#fc636b",
    "#ff6319",
    "#e01f3d",
    "#a0ac48",
    "#00d1b2",
    "#472f92",
    "#388ed1",
    "#a6192e",
    "#4a8594",
    "#7B9FAB",
    "#1393BD",
    "#5E13BD",
    "#E208A7",
  ];
  
  export { avatarColors };