import React from 'react'

import {convertNumbThousand} from '../lib/util'



export default function PostImageCard({ images }) {
  const getColumnClass = (length) => {
    switch (length) {
      case 1:
        return 'columns-1';
      case 2:
        return 'columns-2';
      case 3:
        return 'columns-3';
      case 4:
        return 'columns-4';
      default:
        return 'columns-4'; // Default to 4 columns for more than 4 images
    }
  };
  return (
    <>
      {/* {images.length > 0 && (
        <div className="columns-3xs">
          {images.map((image, index) => (
            <img
              key={index}
              className="w-auto"
              src={image.public_path}
              alt={`Post Image ${index + 1}`}
              loading="lazy"  // Add the loading="lazy" attribute for lazy loading
            />
          ))}
        </div>
      )} */}

{images.length > 0 && (
        <div className={getColumnClass(images.length)}>
          {images.map((image, index) => (
            <img
              key={index}
              className="w-full"
              src={image.public_path}
              alt={`Post Image ${index + 1}`}
              loading="lazy" 
            />
          ))}
        </div>
      )}
    </>
  );
}