import React,{useState} from 'react';

import Layout from '../LayoutPage';
import { baseUrl} from '../../lib/util'
import * as Yup from 'yup';
import { Formik, Form, useField,Field } from 'formik';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Socials from '../../components/Socials';



const Schema = Yup.object().shape({
  fullname: Yup.string().required('Required'), 
  phone: Yup.string().required('Give us a phone number we can call you with.'), 
  email: Yup.string().required('Give us an email we can contact you on'), 
  expirience: Yup.string().required('Tell us how long you have been working'), 
  why: Yup.string().required('Tell us why you should be the one we choose out of everyone that applied for this role'), 
  location: Yup.string().required('Location is required'), 
});

const axios = require('axios').default;
const MyTextArea = ({label, ...props}) => {
  const [field, meta] = useField(props);
  return (
      <>
          <label htmlFor={props.id || props.name}>{label}</label>
          <textarea className="textarea m-2  w-full max-w-sm" {...field} {...props} />
          {meta.touched && meta.error ? (  <div className="error">{meta.error}</div> ) : null}
      </>
  );
};

const Job = ({ job_id }) => {
 

  const location = useLocation();
  const { job } = location.state || {}; 

  if (!job) {
    return <div>Job not found</div>;
  }

  const [attachment, setAttachment] = useState([]);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);

  

  return ( <Layout>
    <div className="flex  bg-neutral text-neutral-content mb-4 mt-4">
          <div className="card-body items-center text-center">
          <h1 className="text-3xl font-bold mb-4 mt-4">{job.title}</h1>

          

                <Link
                  to={`/careers`}
                  state={{job}}
                  className='btn btn-primary btn-outline btn-block pt-2 pb-2'
                  style={{ textAlign: 'center' }}
                >
                  Explore more 
                </Link>
      
                <div className="stats  text-primary-content stats-vertical lg:stats-horizontal ">
  
                      <div className="stat">
                        <div className="stat-figure text-secondary">
                        </div>
                        <div className="stat-title">Opened slots </div>
                        <div className="stat-value text-primary">35 </div>

                      </div>

                                  
                      <div className="stat">
                        <div className="stat-figure text-secondary">
                        </div>
                        <div className="stat-title">Expiring</div>
                        <div className="text-primary font-bold">{job.deadline} </div>

                      </div>

                                    
                                      
                 </div>



           </div>
        </div> 
    <div className="max-w-3xl mx-auto my-8 p-4">
    
      <h1 className="text-3xl font-bold mb-4 mt-4">{job.title}</h1>
      <strong> Location:  {job.location.map((i) => (<span className='ml-1'>{i} </span>))} </strong>
      <br />
      <p className="mb-4 mt-4">   <strong>Job Type: {job.type}</strong></p>
      <p className="mb-4 mt-4">   <strong>Created: {job.created}</strong></p>
      <br />
 
      <p className="mb-4 mt-4">
        <strong>About Hollo</strong>
      </p>
      <p>{job.about}</p>

      <p className="mb-4 mt-4">
        <strong>Job Description</strong>
      </p>
      <p>
        {job.description}
      </p>
    
      <p className="mb-4 mt-4">
        <strong>Responsibilities:</strong>
      </p>
      <ol>
      {job.responsibilities.map((i) => (<li>{i}</li>  ))}
</ol>



       <p className="mb-4 mt-4">
        <strong>Requirements</strong>
      </p>
      <ul>
      {job.requirements.map((i) => (<li>{i}</li>  ))}
      </ul>
     
      <p className="mb-4 mt-4">
        <strong>Salary</strong>
      </p>
      <p> {job.salary}</p>
      
      <p className="mb-4 mt-4">
        <strong>Benefits</strong>
      </p>

      <ol>
         {job.benefits.map((job) => (<li>{job}</li>  ))}
      </ol>

      <p className="mb-4 mt-4">
        <strong>Ideal Candidate</strong>
      </p>
      <ol>
      {job.ideal.map((job) => (<li>{job}</li>  ))}
      </ol>

<p>

<label className="" id="apply-feedback-btn" htmlFor = "apply-feedback"></label>


</p>

      <Formik
          initialValues={{
            fullname: "",
            phone: "",
            email: "",
            expirience: "",
            location:"",
            why:"",
            cv_path:"",
            job_id:job._id,
            role:job.title
          }}
         
         
          validationSchema={Schema}
          onSubmit={ async (values, { setSubmitting }) => {
   
            const applyFeedbackBtn = document.getElementById('apply-feedback-btn')

           let data =values;
             
                if (attachment) {
                       
                  setLoading(true);
                  const formData = new FormData();
                  formData.append('attachments', attachment);

                  try {
                    const response = await axios.post(`${baseUrl}/media/upload/file`, formData, {headers: { 'Content-Type': 'multipart/form-data' },
                      onUploadProgress: (progressEvent) => {
                        const uploadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setProgress(uploadPercentage);
                      },
                    });

                    if (response.data) {
                      const responseData = response.data;
                      if (responseData.success) {
                        data.cv_path = responseData.data[0].public_path;

                      
                      //implement with job id
                        const reg_response = await axios.post(baseUrl + "/careers/job/apply", data, { headers: { 'Content-Type': 'application/json' }});
             
                        if (reg_response.data) {
                            let reg_responsedata = reg_response.data;
                    
                            if (reg_responsedata.success) {
                           
                                applyFeedbackBtn.click();
                               //success 
                              
                              
                            } else {
                                console.log(reg_responsedata.error);
                                alert(reg_responsedata.error);
                               // setProccesing(false);
                            }
                        }
                      } else {
                        alert(responseData.error);
                      }
                    }
                  } catch (error) {
                    console.error('File upload error:', error);
                    alert('An error occurred during file upload.');
                  } finally {
                    setLoading(false);
                  }
                }

           
            setSubmitting(false);
           
          }}
        >
         
          {({ errors, touched }) => (
             <Form>
               <div className='card mt-0 '>
                <br />
                <hr />
               <label className="label"> <span className="label-text text-primary text-2x text-base-500 font-bold">If you're a motivated and results-driven professional with a passion for sales and customer relationships apply below. </span> </label>
                </div>  
              
                <div className="form-control">
                <label className="label"><span className="label-text">Fullname </span></label>
                <Field name="fullname" type="text"  className="input input-bordered" />
                <label className="label font-bold text-primary">  {errors.fullname && touched.fullname ? (<div>{errors.fullname}</div>) : null}</label>
                </div> 

               
                <div className="form-control">
                <label className="label"> <span className="label-text">Phone</span> </label>
                <Field name="phone" type="text"  className="input input-bordered" />
                <label className="label font-bold text-primary"> {errors.phone && touched.phone ? ( <div>{errors.phone}</div> ) : null}
                </label></div> 


               <div className="form-control">
                <label className="label"> <span className="label-text">Email</span></label>
                <Field name="email" type="email"  className="input input-bordered" /> 
                <label className="label font-bold text-primary"> {errors.email && touched.email ? ( <div>{errors.email}</div> ) : null}
                </label></div> 
                <div className="form-control">
                <label className="label"> <span className="label-text">Years of expirience</span></label>
                <Field name="expirience" type="text"  className="input input-bordered" /> 
                <label className="label font-bold text-primary"> {errors.expirience && touched.expirience ? ( <div>{errors.expirience}</div> ) : null}
                </label></div> 

                <label>Select a job location </label>
                <Field name="location" as="select" className= "w-full m-4">        
                {({ field }) => (
                  <select {...field} className="select select-primary w-full max-w-xs mb-4 mt-4">
                    <option  value="" defaultValue>Make a choice</option>
                   
                    {job.location.map((i) => (
                       <option value={i}> <span >{i} </span></option>
                     ))}
                  </select>
                )}
              
              </Field>
              <label className="label font-bold text-primary">
                        {errors.location && touched.location ? (<div>{errors.location}</div>) : null}
                      </label>
                <div className="form-control">
                      <MyTextArea
                        label="Sell yourself"
                        className="textarea w-full textarea-grey w-full max-w-x"
                        name="why"
                        rows="5"
                        placeholder="Why should we hire you ?"
                      />
                      <label className="label font-bold text-primary">
                        {errors.why && touched.why ? (<div>{errors.why}</div>) : null}
                      </label>
                </div>
              
                <label htmlFor="attachments" className=" focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              <span className='mb-2'>Upload your CV</span>
              <br /> 
           
                     <input
                      type="file"
                      style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                      accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                     
                      onChange={(event) => {
                        const file = event.currentTarget.files[0];
                        if (file.size <= 1097152) { // 2MB in bytes
                          setAttachment(file);
                        } else {
                          alert('File size should not exceed 2MB.');
                          // Optionally, you can clear the input if the file is too large
                          event.currentTarget.value = null;
                        }
                      }}
                    />
                    {loading && <div>Uploading... {progress}%</div>}
                                  
                              
         
                  </label>
               
                <div>

            </div>
          

             

                <div className="form-control mt-6">

                <button type="submit" className="btn btn-outline btn-primary mb-4">Apply</button>
              </div>
      
             </Form>
           )}
        </Formik>
     
    </div>


    
    <input type="checkbox" id="apply-feedback" className="modal-toggle" />
       <div className=" modal ">
       <div className="w-1/4 ">
          
          <div className="card   bg-neutral text-primary-content">
            <div className="navbar bg-primary text-primary-content p-0">
            <button className="btn btn-ghost text-xl">Application Received</button>
        
          </div>
          <div className="card-body">
{/*           
            <figure className='item bg-primary w-20 h-20 rounded-full mt-2 ml-10'>      <h1 className='text-white text-5xl font-bold'>Ok</h1></figure> */}
            <span className='font-bold'>
           Congratulations, your job application has been received. A member of our team will review it and revert accordingly. Please note that only candidates who actively follow and engage with our social media platforms will be considered for this position. This engagement helps us understand your interest and alignment with our brand's values.</span>
<br />
              <Socials />
           
            <a style={{ textAlign: 'center' }} className='btn btn-primary btn-outline btn-block pt-2 pb-2' href='/'>browse hollo </a>
         
          </div>

        </div>
      </div>
       </div>
     </Layout>
  );
};

export default Job;

