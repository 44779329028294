import React, { FC } from "react";
//import { ArrowRightIcon } from "@heroicons/react/solid";
import { NavLink } from "react-router-dom";
import Avatar from "../components/avaters/Avater";
import { PlatformType, UserType } from "../data/jsons/types";

export interface CardAuthorBoxProps {
  className?: string;
  platform: PlatformType;
}

const CardAuthorBox: FC<CardAuthorBoxProps> = ({ className = "", platform }) => {
  const { tag, icon , isactive, } = platform;
  return (
    <NavLink
      to="/"
      className={`flex flex-col items-center justify-center text-center px-3 py-5 sm:px-6 sm:py-7  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardAuthorBox"
    >
      <Avatar
        sizeClass="w-20 h-20 text-2xl"
        radius="rounded-full"
        imgUrl={icon}
        userName={tag}
      />
      <div className="mt-3">
        <h2 className={`text-base font-bold`}>
          <span className="line-clamp-1">{tag}</span>
        </h2>
        {/* <span className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`} >
         {username}
        </span> */}
      </div>
      
    </NavLink>
  );
};

export default CardAuthorBox;
