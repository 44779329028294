import {  all } from 'redux-saga/effects'
import signupSaga from './RegisterSaga'
import logoutSaga from './LogoutSaga'
import PostSaga from './PostSaga'


  export default function* IndexSaga() {
    yield all([
      signupSaga(),
      logoutSaga(),
      PostSaga(),
    ])
  }