import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useParams  } from 'react-router-dom';
import HomePage from './pages/HomePage';
import RegisterPage from './pages/RegisterPage';
import LoginPage from './pages/LoginPage';
import ProfilePage from './pages/ProfilePage';
import FeedPage from './pages/FeedPage';
import SettingPage from './pages/SettingPage';
import { ThemeProvider } from './ThemeContext';
import Callback  from './pages/CallBack';
import PostPage from './pages/ScheduledPage';
import ChannelConnectSuccessPage from './pages/landing/ChannelConectSuccessPage'
import ChannelConnectErrorPage from './pages/landing/ChannelConectErrorPage'
import PostSccessTrigger from './pages/landing/PostSuccessTrigger';
import WelcomePage from './pages/landing/WelcomePage';
import TermsAndConditions from './pages/landing/legal/terms';
import PrivacyPolicy from './pages/landing/legal/privacy';
import DataPolicy from './pages/landing/legal/data';
import CodeOfConduct from './pages/landing/legal/code_of_conduct';
import TutorialPage from './pages/TutorialPage';
import FeedbackPage from './pages/FeedbackPage';
import WaitingPage from './pages/WaitingPage';
import ResetPage from './pages/ResetPage';
import PaymentSuccess from './pages/landing/PaymentSuccess';
import RewardPage from './pages/RewardPage';
import AdminDashboard from './pages/admin/AdminDash';
import AdminJobs from './pages/admin/AdminJobs';
import AdminJobApplications from './pages/admin/AdminJobApplications';
import Job from './pages/career/job';
import Jobs from './pages/career/jobs';
import AdminBase from './pages/admin/AdminBase';
import NotificationPage from './pages/NotificationPage';


// Wrapper components
function ProfilePageWrapper() {
  return <ProfilePage />;
}

function UserProfilePageWrapper() {
  const { username } = useParams();
  return <ProfilePage username={username} />;
}
function JobApplicationPageWrapper() {
  const { job_id } = useParams();
  return <Job job_id = {job_id} />;
}
function ExplorePageWrapper() {
  const { postt_id } = useParams();
  return <Job job_id = {postt_id} />;
}

const App = () => {
  useEffect(() => {
    let deferredPrompt;
  
    window.addEventListener('beforeinstallprompt', (event) => {
      console.log('beforeinstallprompt event fired'); // Log to check if the event fires
      event.preventDefault();
      deferredPrompt = event;
  
      // Check if the app is already installed
      if (!window.matchMedia('(display-mode: standalone)').matches) {
        const installButton = document.createElement('button');
        installButton.textContent = 'Install';
        installButton.className = 'btn btn-primary fixed top-4 left-1/2 transform -translate-x-1/2 z-50';
  
        installButton.onclick = () => {
          deferredPrompt.prompt();
          deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
              console.log('User accepted the A2HS prompt');
            }
            deferredPrompt = null;
          });
        };
  
        document.body.appendChild(installButton);
      }
    });
  
    return () => {
      window.removeEventListener('beforeinstallprompt', () => {});
    };
  }, []);


  return (
    <ThemeProvider>
      <BrowserRouter>
          <Routes>
  
   
            <Route path="/me" element={<ProfilePageWrapper />} />
            <Route path="/user/:username" element={<UserProfilePageWrapper />} />
            <Route path="/" element={<HomePage />} /> 
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/home" element={<FeedPage />} />
            <Route path="/account" element={<ProfilePage />} />
            <Route path="/rewards" element={<RewardPage />} />
            <Route path="/tutorial" element={<TutorialPage />} />
            <Route path="/notification" element={<NotificationPage />} />
            <Route path="/feedback" element={<FeedbackPage />} />
            <Route path="/scheduled" element={<PostPage />} />
            <Route path="/settings" element={<SettingPage />} />
            <Route path="/callback" element={<Callback />} />
            <Route path="/profile" element={<ProfilePage />} />


            {/* carrers */}
            <Route path="/careers" element={<Jobs />} />
            <Route path="/careers/job/:job_id" element={<JobApplicationPageWrapper />} />
            {/* post */}
            <Route path="/explore" element={<ChannelConnectSuccessPage />} />
            <Route path="/post/" element={<WelcomePage />} />




            <Route path="/success" element={<ChannelConnectSuccessPage />} />
            <Route path="/welcome" element={<WelcomePage />} />
            <Route path="/post/success" element={<PostSccessTrigger />} />
            <Route path="/reset-pass" element={<ResetPage />} />
            <Route path="/paid" element={<PaymentSuccess />} />
            <Route path="/error" element={<ChannelConnectErrorPage />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/data-policy" element={<DataPolicy />} />
            <Route path="/code-of-conduct" element={<CodeOfConduct />} />
            {/* Admin */}
            <Route path="/admin" element={<AdminBase />} />
            <Route path="/admin/staffs" element={<AdminDashboard />} />
            <Route path="/admin/reward" element={<AdminDashboard />} />
            <Route path="/admin/reward/request" element={<AdminDashboard />} />
            <Route path="/admin/reward/fulfiled" element={<AdminDashboard />} />
            <Route path="/admin/posts" element={<AdminDashboard />} />
            <Route path="/admin/jobs/applications" element={<AdminJobApplications />} />
          </Routes>    
      </BrowserRouter>
     
    </ThemeProvider>
  );
};
export default App;